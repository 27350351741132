<template>
    <div>
        <v-form ref="form">
            <h1 class="text-h5 my-4">
                Cadastro de tipos de ato de concessão
            </h1>
            <v-sheet class="pa-4  mb-5" rounded outlined>
                <h2 class="subtitle-1 mb-3">Dados do tipo de ato de concessão</h2>
                <v-row>
                    <v-col cols="12" md="12">
                        <v-text-field
                            label="Nome tipo de ato de concessão *"
                            outlined
                            dense
                            v-model="form.nm_tipo_ato_concessao"
                            :rules="validate('required', 'Nome tipo de ato de concessão')"
                            :disabled="visualizar"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-sheet>
            <div class="d-flex">
                <v-spacer></v-spacer>
                <v-btn
                    class="mx-2"
                    color="error"
                    @click="voltar()"
                >
                    Voltar
                </v-btn>
                
                <v-btn v-if="!visualizar"
                    color="primary"
                    @click="salvar()"
                >
                    Salvar
                </v-btn>
            </div>
        </v-form>
    </div>
</template>

<script>

import { getTipoAto, createTipoAto, updateTipoAto } from "../../api/tipo_ato_concessao";
import { validationMixin } from "../../mixins/validationMixin";

export default {
    name: "TipoAto",
    components: {},
    props: {
        tipoAto: {
            type: Object,
            default() {
                return {id_tipo_ato_concessao: null}
            }
        },
    },
    mixins: [validationMixin],
    data() {
        return {
            form: {
                nm_tipo_ato_concessao: null
            },
            error: {},
            permissoes: [],
        }
    },
    created() {
        if (this.$route.params.tipoAto) { 
            getTipoAto(this.$route.params.tipoAto).then((response) => {
                this.form.nm_tipo_ato_concessao = response.data.data.nm_tipo_ato_concessao;
            })
        }
    },
    methods: {
        salvar() {
            if (this.$refs.form.validate()) {
                let promise;
                if (this.$route.params.tipoAto) {
                    promise = updateTipoAto(this.$route.params.tipoAto, this.form)
                } else {
                    promise = createTipoAto(this.form)
                }

                promise.then(response => {
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: response.data.message,
                    })
                    this.$vuetify.goTo(0)
                    this.$router.push({name: 'tipoAto'})
                })
            }            
        },
        voltar() {           
            this.$router.push({name: 'tipoAto'});
        }
    },
}
</script>