require('./bootstrap');

import Vue from 'vue';

import { router } from './router';
import store from './store';

import vuetify from './plugins/vuetify';
import VueTheMask from 'vue-the-mask'
import VueNumeric from 'vue-numeric';
import ToggleButton from 'vue-js-toggle-button'

import VCurrencyField from 'v-currency-field'
import money from 'v-money'
import VModal from 'vue-js-modal'

import './filters/filters';

import MainApp from './components/app/MainApp.vue';

Vue.use(VModal)
Vue.use(money, {precision: 4})
Vue.use(VueTheMask);
Vue.use(VueNumeric);
Vue.use(require('vue-moment'));
Vue.use(ToggleButton)

Vue.use(VCurrencyField, { 
	locale: 'pt-BR',
	decimalLength: 2,
	autoDecimalMode: false,
	min: null,
	max: null,
	defaultValue: 0,
    valueAsInteger: false,
    allowNegative: false
})


const app = new Vue({
    el: '#app',
    router,
    store,
    vuetify,
    components: {
        MainApp
    }
});
