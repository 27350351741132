<template>
    <div>
        <v-form ref="form">
            <h1 class="text-h5 my-4">
                Questionário Avulso
            </h1>
            <v-sheet class="pa-4  mb-5" rounded outlined>
                <input type="hidden" name="id_questionario_avulso" class="form-control" :value="form.id_questionario_avulso">
                <input type="hidden" name="id_resposta_questionario_avulso" class="form-control" :value="form.id_resposta_questionario_avulso">
                
                <v-row>
                    <v-col cols="12" md="6">
                        <v-select
                            label="Conselho"
                            outlined
                            v-model="form.id_conselho"
                            dense                            
                            clearable                                                     
                            :items="conselhosSelect"
                            item-value="id_conselho"
                            item-text="nm_conselho"
                            hide-details="false"
                            @change="(event) => conselhoChange(event)"
                            :disabled="visualizar || responder"
                        />
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-select
                            label="Orgão *"
                            outlined                
                            v-model="form.id_orgao"
                            dense                            
                            clearable                                                     
                            :items="orgaosSelect"
                            item-value="id_orgao"
                            item-text="nm_orgao"
                            :rules="validate('required', 'Orgão')" 
                            :disabled="visualizar || responder"
                        />
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-text-field                            
                            label="Data de referência *"
                            outlined
                            type="date"
                            dense
                            v-model="form.dt_referencia"    
                            :rules="validate('required', 'Data de referência')"  
                            :disabled="visualizar || responder"                      
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row v-if="form.id_questionario_avulso">
                    <v-col cols="12" md="12">
                        <v-textarea
                            label="Descrição do questionário *"
                            outlined
                            dense
                            v-model="form.ds_questionario_avulso"
                            :rules="validate('required', 'Descrição do questionário')"
                            :disabled="visualizar || responder"
                        ></v-textarea>
                    </v-col>
                </v-row>
                <v-row v-if="form.id_questionario_avulso">
                    <v-col>
                        <QuestionarioAvulsoAnexo
                            @questionarioAvulsoAnexoEmit="questionarioAvulsoAnexoEmit"
                            :anexoItens="this.anexos"                                        
                        ></QuestionarioAvulsoAnexo>
                    </v-col>
                </v-row>
            </v-sheet>

            <h1 class="text-h5 my-4" v-if="(form.id_resposta_questionario_avulso && visualizar) || responder">
                Resposta Questionário Avulso
            </h1>
            <v-sheet class="pa-4  mb-5" rounded outlined>
                <v-row v-if="(form.id_resposta_questionario_avulso && visualizar) || responder">
                    <v-col cols="12" md="12">
                        <v-textarea
                            label="Resposta do questionário *"
                            outlined
                            dense
                            v-model="form.ds_resposta_questionario_avulso"
                            :rules="validate('required', 'Resposta do questionário')"
                            :disabled="visualizar"
                        ></v-textarea>
                    </v-col>
                </v-row>
                <v-row v-if="(form.id_resposta_questionario_avulso && visualizar) || responder">
                    <v-col cols="12" md="6">
                        <v-text-field
                            label="Número do SEI"
                            outlined
                            dense
                            v-model="form.nu_sei"
                            hide-details="false"
                            :disabled="visualizar"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row v-if="form.id_questionario_avulso && ((form.id_resposta_questionario_avulso && visualizar) || responder)">
                    <v-col>
                        <RespostaQuestionarioAvulsoAnexo
                            @respostaQuestionarioAvulsoAnexoEmit="respostaQuestionarioAvulsoAnexoEmit"
                            :anexoItensRespostas="this.anexosResposta"                                        
                        ></RespostaQuestionarioAvulsoAnexo>
                    </v-col>
                </v-row>
            </v-sheet>
            <div class="d-flex">
                <v-spacer></v-spacer>
                <v-btn
                    class="mx-2"
                    color="success"
                    v-if="questionarioAvulso.resposta"
                    @click="callPDF()"
                >
                    Exportar como PDF
                </v-btn>

                <QuestionarioAvulsoPDF v-if="callPF" :questionario="questionarioAvulso"/>

                <v-btn
                    class="mx-2"
                    color="error"
                    @click="voltar()"
                >
                    Voltar
                </v-btn>
                
                <v-btn v-if="!editar && !visualizar && !responder"
                    color="primary"
                    @click="salvar()"
                >
                    Iniciar questionário
                </v-btn>

                <v-btn v-if="editar"
                    class="ml-2"
                    color="primary"
                    @click="validSubmeterQuestionario()"
                >
                    Enviar Questionário
                </v-btn>

                <v-btn v-if="responder"
                    class="ml-2"
                    color="primary"
                    @click="responderQuestionario()"
                >
                    Responder Questionário
                </v-btn>

                <v-btn v-if="showEncerrar"
                    color="primary"
                    @click="encerrar()"
                >
                    Encerrar
                </v-btn>

                <v-dialog v-model="submit_dialog" persistent class="v-dialog">
                    <v-card>
                        <v-card-title class="headline">
                            Deseja realmente enviar o Questionário Avulso?
                        </v-card-title>
                        <v-card-text>
                            <h3>
                                Ao enviar, este Questionário enviado para os responsáveis e você não poderá
                                mais editar as informações nele contidas.
                            </h3>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="error"
                                @click="submit_dialog = false">
                                Cancelar
                            </v-btn>
                            <v-btn
                                color="primary"
                                @click="submeterQuestionario()"
                            >
                                Enviar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>
        </v-form>
    </div>
</template>

<script>

import { createQuestionarioAvulso, getQuestionarioAvulso, submeterQuestionarioAvulso, responderQuestionarioAvulso, encerrarQuestionarioAvulso } from "../../api/questionario_avulso";
import { getConselhos } from "../../api/conselho";
import { getOrgaosByConselho } from "../../api/orgao";
import {validationMixin} from "../../mixins/validationMixin";
import DeleteAction from "../../components/inputs/DeleteAction.vue";
import QuestionarioAvulsoAnexo from "../../components/components/QuestionarioAvulsoAnexo.vue";
import RespostaQuestionarioAvulsoAnexo from "../../components/components/RespostaQuestionarioAvulsoAnexo.vue";

import QuestionarioAvulsoPDF from '../../views/pdf/QuestionarioAvulsoPDF.vue'

import moment from 'moment';

moment.locale('pt-br');

export default {
    name: "QuestionarioAvulso",
    components: {DeleteAction, QuestionarioAvulsoAnexo, RespostaQuestionarioAvulsoAnexo, QuestionarioAvulsoPDF},
    mixins: [validationMixin],
    data() {
        return {
            questionarioAvulso: [],
            conselhosSelect: [],
            orgaosSelect: [],
            form: {
                id_questionario_avulso: null,
                id_conselho: null,
                id_orgao: null,
                ds_questionario_avulso: null,
                id_resposta_questionario_avulso: null,
                ds_resposta_questionario_avulso: null,
                nu_sei: null
            },
            visualizar: false,
            editar: false,
            novo: false,
            anexos: [],
            submit_dialog: false,
            responder: false,
            anexosResposta: [],
            showEncerrar: false,
            callPF: false
        }
    },
    created() {
        this.editar = this.$route.name == 'questionario-avulso-editar' ? true : false;
        this.visualizar = this.$route.name == 'questionario-avulso-visualizar' ? true : false;
        this.responder = this.$route.name == 'questionario-avulso-responder' ? true : false;

        getConselhos().then((conselho_response) => {

            this.conselhosSelect = conselho_response.data.data;

            if (!this.$store.getters['auth/usuarioHasRole'](['Administrador'])) { // se não for administrador

                getMe().then(user_response => {
                    this.user = user_response.data;

                    if (this.$store.getters['auth/usuarioHasRole'](['Analista'])) {

                        if (this.user.conselho.id_conselho_pai != null) { // Analista Estadual
                            this.disableConselho = true;
                            this.filtro.id_conselho = this.user.id_conselho;

                            getOrgaosByConselho(this.user.id_conselho).then((orgao_response) => {
                                this.orgaosSelect = orgao_response.data.data;        
                            });
                        } else { // Analista Federal
                        }
                    } else if (this.$store.getters['auth/usuarioHasRole'](['Cadastrador'])) {
                        
                        getOrgaosByConselho(this.user.id_conselho).then((orgao_response) => {

                            this.disableOrgao = true;
                            this.disableConselho = true;
                            this.orgaosSelect = orgao_response.data.data;
                            this.filtro.id_conselho = this.user.id_conselho;

                            orgao_response.data.data.forEach(orgao => {
                                if (orgao.id_orgao == user_response.data.id_orgao) {
                                    this.filtro.id_orgao = orgao.id_orgao;
                                }
                            });
                        });
                    }
                });
            }
                
        }); 

        if (this.$route.params.questionario) {
            getQuestionarioAvulso(this.$route.params.questionario).then((response) => {
                this.questionarioAvulso = response.data.data;

                getOrgaosByConselho(this.questionarioAvulso.orgao.conselho.id_conselho).then(response => this.orgaosSelect = response.data.data);

                this.form.id_questionario_avulso = this.questionarioAvulso.id_questionario_avulso;
                this.form.id_orgao = this.questionarioAvulso.id_orgao;
                this.form.nm_orgao = this.questionarioAvulso.orgao.nm_orgao;
                this.form.id_conselho = this.questionarioAvulso.orgao.conselho.id_conselho;
                this.form.dt_referencia = moment(this.questionarioAvulso.dt_referencia).format("YYYY-MM-DD");
                this.form.ds_questionario_avulso = this.questionarioAvulso.ds_questionario_avulso;
                this.anexos = response.data.data.anexos;

                if ( this.questionarioAvulso.resposta ) {
                    this.form.id_resposta_questionario_avulso = this.questionarioAvulso.resposta.id_resposta_questionario_avulso;
                    this.form.ds_resposta_questionario_avulso = this.questionarioAvulso.resposta.ds_resposta_questionario_avulso;
                    this.form.nu_sei = this.questionarioAvulso.resposta.nu_sei;
                    this.anexosResposta = response.data.data.resposta.anexos;
                }

                this.showEncerrar = this.$store.getters['auth/usuarioHasRole'](['Analista']) && this.$store.getters['auth/usuarioHasPermissao'](['QuestionárioAvulsoVisualizar']) && this.questionarioAvulso.status.nm_questionario_avulso_status == 'Respondido';
            })
        }
    },
    methods: {
        conselhoChange(event) {
            if (this.form.id_conselho != null) {
                getOrgaosByConselho(event).then(response => this.orgaosSelect = response.data.data);
                this.form.id_orgao = null;
            } else {
                this.orgaosSelect = [];
            }
        }, 

        questionarioAvulsoAnexoEmit(anexos) {
            if (anexos.length == 0) {
                // this.anexosEmpty = true; // postergado
            } else {
                this.anexosEmpty = false;
                this.anexos = anexos;
            }
        },

        respostaQuestionarioAvulsoAnexoEmit(anexosResposta) {
            if (anexosResposta.length == 0) {
                // this.anexosEmpty = true; // postergado
            } else {
                this.anexosEmpty = false;
                this.anexosResposta = anexosResposta;
            }
        },

        salvar() {
            if (this.$refs.form.validate()) {
                let promise;

                promise = createQuestionarioAvulso(this.form)

                promise.then(response => {
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: response.data.message,
                    })
                    this.$vuetify.goTo(0)
                    this.$router.push({
                        name: 'questionario-avulso-editar',
                        params: {questionario: response.data.data.id_questionario_avulso}
                    });
                }).catch(error => this.handleError(error))
            }
        },

        voltar() {
            this.$router.push({name: 'questionarios-avulsos'});
        },

        validSubmeterQuestionario() {
            if (this.$refs.form.validate()) {
                this.submit_dialog = true;
            }
        },
        submeterQuestionario() {
            let promise;

            promise = submeterQuestionarioAvulso(this.$route.params.questionario, this.form)

            promise.then(response => {
                this.$store.dispatch('alertas/show', {
                    tipo: 'Sucesso',
                    titulo: response.data.message,
                })
                this.$router.push({name: 'questionarios-avulsos'});
            }).catch(error => this.handleError(error))
        },

        responderQuestionario() {
            if (this.$refs.form.validate()) {
                let promise;

                promise = responderQuestionarioAvulso(this.$route.params.questionario, this.form)

                promise.then(response => {
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: response.data.message,
                    })
                    this.$router.push({name: 'questionarios-avulsos'});
                }).catch(error => this.handleError(error))
            }
        },

        encerrar() {
            let promise;

            promise = encerrarQuestionarioAvulso(this.$route.params.questionario)

            promise.then(response => {
                this.$store.dispatch('alertas/show', {
                    tipo: 'Sucesso',
                    titulo: response.data.message,
                })
                this.$router.push({name: 'questionarios-avulsos'});
            }).catch(error => this.handleError(error))
        },

        validarQuestionario() {

            let promise;

            promise = validarQuestionario(this.$route.params.questionario)

            promise.then(response => {
                this.$store.dispatch('alertas/show', {
                    tipo: 'Sucesso',
                    titulo: response.data.message,
                })
                this.$router.push({name: 'questionarios'});
            }).catch(error => this.handleError(error))
        },
        handleError(error) {
            this.$store.dispatch('alertas/show', {
                tipo: 'Erro',
                titulo: error.response.data.error.message.title,
                mensagem: error.response.data.error.message.msg,
            });

            this.$vuetify.goTo(0);

            if (error.response.data.error.message.type == 403) {
                this.$router.push({name: 'home'});
            }
        },
        
        dateFormat(date) {
            if (!!date) {
                return moment(date).format("DD/MM/YYYY [às] HH:mm:ss")
            }
            return null;
        },

        callPDF() {
            this.callPF = true;

            setTimeout(function () {window.location.reload()} , 2000);
        }, 
    },
}
</script>