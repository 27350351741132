<template>
    <div>
        <v-card class="mb-9">
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-select
                            label="Conselho"
                            outlined
                            :disabled="disableConselho"
                            v-model="filtro.id_conselho"
                            dense
                            clearable
                            :items="conselhosSelect"
                            item-value="id_conselho"
                            item-text="nm_conselho"
                            hide-details="false"
                            @change="(event) => conselhoChange(event)"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-select
                            label="Orgão"
                            outlined
                            :disabled="disableOrgao"
                            v-model="filtro.id_orgao"
                            dense
                            clearable
                            :items="orgaosSelect"
                            item-value="id_orgao"
                            item-text="nm_orgao"
                            hide-details="false"
                        />
                    </v-col>
                </v-row>
                <v-row v-if="!this.$store.getters['auth/usuarioHasRole'](['Cadastrador', 'Consultor'])">
                    <v-col cols="12" md="6">
                        <v-text-field
                            label="Busca Aproximada"
                            outlined
                            dense
                            @keyup.enter="filtrar()"
                            v-model="filtro.nm_orgao"
                            hide-details="false"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-select
                            label="Status"
                            outlined
                            dense
                            clearable
                            v-model="filtro.id_questionario_status"
                            :items="[
                                {value: 1, text: 'Em Preenchimento'},
                                {value: 2, text: 'Em Análise'},
                                {value: 3, text: 'Em Análise & Ajustes'},
                                {value: 4, text: 'Encerrado'},
                                {value: 5, text: 'Devolvido para Ajustes'},
                            ]"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="3">
                        <MonthPickerInput
                            ref="monthPicker"
                            v-model="month_date"
                            :rules= []
                        />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    outlined
                    @click="limparFiltro()"
                >
                    Limpar
                </v-btn>
                <v-btn
                    color="primary"
                    @click="filtrar()"
                >
                    <v-icon left>
                        mdi-filter
                    </v-icon>
                    Filtrar
                </v-btn>
            </v-card-actions>
        </v-card>

        <div class="d-flex my-3">
            <v-spacer></v-spacer>
            <v-btn v-if="$store.getters['auth/usuarioHasPermissao'](['QuestionárioCriar'])"
                color="primary"
                :to="{name: 'questionario-novo'}"
            >
                Responder Questionário
            </v-btn>
        </div>

        <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page="pagination.itemsPerPage"
            :footer-props="footerProps"
            :options.sync="pagination"
            :server-items-length="pagination.total"
            class="elevation-1"
            @update:options="filtrar()"
        >

            <template v-slot:item.dt_mes_ano="{ item }">
                <span>{{ dateFormat(item.dt_mes_ano) }}</span>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-tooltip v-if="$store.getters['auth/usuarioHasPermissao'](['QuestionárioVisualizar'])" top>
                    <template v-slot:activator="{ on: tooltip}">
                        <v-btn
                            fab
                            color="cyan"
                            x-small
                            elevation="0"
                            :to="{name: 'questionario-visualizar', params: {questionario: item.id_questionario}}"
                            v-on="{ ...tooltip}"
                        >
                            <v-icon>
                                mdi-magnify
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Visualizar</span>
                </v-tooltip>
                <v-tooltip v-if="showEditPreencher(item)" top>
                    <template v-slot:activator="{ on: tooltip}">
                        <v-btn
                            fab
                            color="success"
                            x-small
                            elevation="0"
                            :to="{name: 'questionario-editar', params: {questionario: item.id_questionario}}"
                            v-on="{ ...tooltip}"
                        >
                            <v-icon>
                                mdi-pencil
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Preencher Questionário</span>
                </v-tooltip>
                <v-tooltip v-if="showEditAnalisar(item)" top>
                    <template v-slot:activator="{ on: tooltip}">
                        <v-btn
                            fab
                            color="success"
                            x-small
                            elevation="0"
                            :to="{name: 'questionario-editar', params: {questionario: item.id_questionario}}"
                            v-on="{ ...tooltip}"
                        >
                            <v-icon>
                                mdi-pencil
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Analisar Respostas</span>
                </v-tooltip>
                <v-tooltip v-if="showEditAjuste(item)" top>
                    <template v-slot:activator="{ on: tooltip}">
                        <v-btn
                            fab
                            color="success"
                            x-small
                            elevation="0"
                            :to="{name: 'questionario-editar', params: {questionario: item.id_questionario}}"
                            v-on="{ ...tooltip}"
                        >
                            <v-icon>
                                mdi-pencil
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Ajustar Respostas</span>
                </v-tooltip>
                <v-tooltip v-if="showEditSEI(item)" top>
                    <template v-slot:activator="{ on: tooltip}">
                        <v-btn
                            fab
                            color="success"
                            x-small
                            elevation="0"
                            :to="{name: 'questionario-editar', params: {questionario: item.id_questionario}}"
                            v-on="{ ...tooltip}"
                        >
                            <v-icon>
                                mdi-alpha-s-box
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Adicionar informações do SEI</span>
                </v-tooltip>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { getQuestionariosPaginate, deleteQuestionario, getQuestionario } from "../../api/questionario";
import { getConselhos } from "../../api/conselho";
import { getOrgaosByConselho } from "../../api/orgao";
import { dataTableMixin } from "../../mixins/dataTableMixin";
import _ from "lodash";
import { filterNormalizeMixin } from "../../mixins/filterNormalizeMixin";
import DeleteAction from "../../components/inputs/DeleteAction.vue";
import { getMe } from "../../api/usuario";
import MonthPickerInput from "../../components/inputs/MonthPickerInput.vue";
import FilterService from "../../filters/filterService";

import moment from 'moment';
moment.locale('pt-br');

export default {
    name: "QuestionarioListar",
    components: { DeleteAction, MonthPickerInput },
    mixins: [dataTableMixin, filterNormalizeMixin],
    data() {
        return {
            filtro: {
                id_orgao: null,
                id_conselho: null,
                id_questionario_status: null,
                nm_orgao: null,
                mes: null,
                ano: null,
            },
            month_date: null,
            editIcon: null,
            editTooltip: null,
            filtro_id_conselho: null,
            conselhosSelect: [],
            orgaosSelect: [],
            loading: true,
            user: null,
            disableConselho: false,
            disableOrgao: false,
            column_id: 'id_questionario',
            headers: [
                // { text: 'ID', value: 'id_questionario', sortable: false },
                { text: 'Orgão', value: 'orgao.nm_orgao', sortable: false },
                { text: 'Mês/Ano', value: 'dt_mes_ano', sortable: false },
                { text: 'Status', value: 'status.nm_questionario_status', sortable: false, width: '150px' },
                { text: 'Ações', value: 'actions', sortable: false, width: '100px' },
            ]
        }
    },
    created() {

        getConselhos().then((conselho_response) => {

            this.conselhosSelect = conselho_response.data.data;

            if (!this.$store.getters['auth/usuarioHasRole'](['Administrador'])) { // se não for administrador

                getMe().then(user_response => {
                    this.user = user_response.data;

                    if (this.$store.getters['auth/usuarioHasRole'](['Analista'])) {

                        if (this.user.conselho.id_conselho_pai != null) { // Analista Estadual
                            this.disableConselho = true;
                            this.filtro.id_conselho = this.user.id_conselho;

                            getOrgaosByConselho(this.user.id_conselho).then((orgao_response) => {
                                this.orgaosSelect = orgao_response.data.data;
                            });

                            this.filtrar();
                        } else { // Analista Federal
                            this.filtrar();
                        }
                    } else if (this.$store.getters['auth/usuarioHasRole'](['Cadastrador', 'Consultor'])) {

                        getOrgaosByConselho(this.user.id_conselho).then((orgao_response) => {

                            this.disableConselho = true;
                            this.filtro.id_conselho = this.user.id_conselho;
                            this.orgaosSelect = orgao_response.data.data;

                            if (this.$store.getters['auth/usuarioHasRole'](['Cadastrador'])) {
                                orgao_response.data.data.forEach(orgao => {
                                    if (orgao.id_orgao == user_response.data.id_orgao) {
                                        this.filtro.id_orgao = orgao.id_orgao;
                                    }
                                });
                                this.disableOrgao = true;
                            }

                            this.filtrar();
                        });
                    }
                });
            } else {
                this.filtrar();
            }

        });
    },
    mounted() {
        const savedFilters = FilterService.loadFilters(this.$route.name);
        if (savedFilters) {
            this.filtro = savedFilters;
        }
    },
    beforeRouteLeave(to, from, next) {
        FilterService.saveFilters(from.name, this.filtro);
        next();
    },
    methods: {
        conselhoChange(event) {
            if (this.filtro.id_conselho != null) {
                getOrgaosByConselho(event).then(response => this.orgaosSelect = response.data.data);
                this.filtro.id_orgao = null;
            } else {
                this.orgaosSelect = [];
            }
        },
        limparFiltro() {
            this.filtro = {
                id_orgao: null,
                id_conselho: null,
                id_questionario_status: null,
                nm_orgao: null,
                mes: null,
                ano: null,
            };

            this.month_date = null;

            if (this.$refs.monthPicker) {
                this.$refs.monthPicker.clearDate(); // Clear date in MonthPickerInput
            }

            FilterService.clearFilters(this.$route.name);
            this.filtrar();
        },
        filtrar() {
            getQuestionariosPaginate(this.pagination.page, this.pagination.itemsPerPage, this.filtro)
                .then(response => {
                    this.items = response.data.data.data;
                    this.pagination.total = response.data.data.total;
                    if (this.pagination.total < this.pagination.itemsPerPage) {
                        this.pagination.page = 1;
                    }
                });
        },
        deletar(id) {

            getQuestionario(id).then((response) => {
                deleteQuestionario(id).then((response) => {
                    this.items = this.items.filter(item => item[this.column_id] !== id)
                    //this.$store.dispatch('alertas/hide');
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: response.data.success.message,
                    });
                    this.$vuetify.goTo(0);
                })
                .catch(error => this.handleError(error))
            })
        },
        handleError(error) {

            this.$store.dispatch('alertas/show', {
                tipo: 'Erro',
                titulo: error.response.data.error.message.title,
                mensagem: error.response.data.error.message.msg,
            });
            this.$vuetify.goTo(0);

            if (error.response.data.error.message.type == 403) {
                this.$router.push({name: 'home'});
            }
        },
        dateFormat(date) {
            if (!!date) {
                return moment(date).format("MM/YYYY")
            }
            return null;
        },
        showEditSEI(item) {
            // se for analista e o questionário estiver Encerrado e tiver permissão de atualização
            return this.$store.getters['auth/usuarioHasRole'](['Analista']) && this.$store.getters['auth/usuarioHasPermissao'](['QuestionárioAtualizar']) && item.status.nm_questionario_status == 'Encerrado';
        },
        showEditAjuste(item) {
            // se for cadastrador e o questionário estiver em análise & ajustes ou Devolvido para Ajustes e tiver permissão de atualização
            return this.$store.getters['auth/usuarioHasRole'](['Cadastrador']) && this.$store.getters['auth/usuarioHasPermissao'](['QuestionárioAtualizar']) && (item.status.nm_questionario_status == 'Em Análise & Ajustes' || item.status.nm_questionario_status == 'Devolvido para Ajustes');
        },
        showEditAnalisar(item) {
            // se for analista e o questionário estiver em análise ou em análise & ajustes e tiver permissão de atualização
            return this.$store.getters['auth/usuarioHasRole'](['Analista']) && this.$store.getters['auth/usuarioHasPermissao'](['QuestionárioAtualizar']) && (item.status.nm_questionario_status == 'Em Análise' || item.status.nm_questionario_status == 'Em Análise & Ajustes');
        },
        showEditPreencher(item) {
            // se for cadastrador e o questionário estiver em preenchimento e tiver permissão de atualização
            return this.$store.getters['auth/usuarioHasRole'](['Cadastrador']) && this.$store.getters['auth/usuarioHasPermissao'](['QuestionárioAtualizar']) && item.status.nm_questionario_status == 'Em Preenchimento';
        }
    },
    watch: {
        'month_date': function (newVal) {
            if (newVal) {
                const [mes, ano] = newVal.split('/');
                this.filtro.mes = mes;
                this.filtro.ano = ano;
            } else {
                this.filtro.mes = null;
                this.filtro.ano = null;
            }
        },
    },
}
</script>
