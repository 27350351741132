import api from './index';

const createQuestionarioAvulso = (dados) => api.post('questionario-avulso', dados);
const submeterQuestionarioAvulso = (id_questionario_avulso, dados) => api.put(`questionario-avulso/submit/${id_questionario_avulso}`, dados);
const getQuestionarioAvulso = (id_questionario_avulso) => api.get(`questionario-avulso/${id_questionario_avulso}`);
const responderQuestionarioAvulso = (id_questionario_avulso, dados) => api.put(`questionario-avulso/responder/${id_questionario_avulso}`, dados);
const getRespostaQuestionarioAvulso = (id_questionario_avulso) => api.get(`resposta-questionario-avulso/${id_questionario_avulso}`);
const encerrarQuestionarioAvulso = (id_questionario_avulso) => api.put(`questionario-avulso/encerrar/${id_questionario_avulso}`);

const getQuestionariosAvulsoPaginate = async (page, itemsPerPage, filtrosAplicados = []) => await api.get('questionario-avulso/questionarios-avulso-paginate', {
    params: {
        page: page,
        limit: itemsPerPage,
        filtros: filtrosAplicados,
    },
    showLoader: true
});

export {    
    createQuestionarioAvulso,
    submeterQuestionarioAvulso,
    getQuestionarioAvulso,
    getQuestionariosAvulsoPaginate,
    responderQuestionarioAvulso,
    getRespostaQuestionarioAvulso,
    encerrarQuestionarioAvulso
}
