<template>
    <div>
        <v-form ref="form">
            <v-row>
                <v-col cols="12" md="12">
                    <h4>Anexos Questionário</h4>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="6" v-if="!visualizar && !responder">
                    <v-text-field
                        label="Descrição do Anexo *"
                        outlined
                        dense
                        v-model="form.ds_questionario_avulso_anexo"
                        :rules="validate('required', 'Descrição do Anexo')"
                    ></v-text-field>
                </v-col>
                <v-col cols="6" md="4" v-if="!visualizar && !responder">
                    <v-file-input
                        accept="application/pdf"
                        label="Anexo"
                        outlined
                        dense
                        :rules="validate('required', 'Anexo')"
                        v-model="form.file"
                    ></v-file-input>
                </v-col>
                <v-col cols="6" md="2" v-if="!visualizar && !responder">
                    <v-btn
                        color="primary"
                        @click="addAnexo()"
                    >
                        Anexar
                    </v-btn>
                </v-col>
            </v-row>
            <v-data-table
                :headers="anexoHeaders"
                :items="this.anexos"                
                class="elevation-1 mb-0 mt-4"
            >
                <template v-slot:item.actions="{ item }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on: tooltip}">
                            <v-btn
                                fab
                                color="primary"
                                elevation="0"
                                x-small
                                @click="download(item.id_questionario_avulso_anexo)"
                                v-on="{ ...tooltip}"
                            >
                                <v-icon>
                                    mdi-cloud-download
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Download</span>
                    </v-tooltip>
                    <DeleteAction v-if="!visualizar && !responder"                  
                        @delete="deletar(item.id_questionario_avulso_anexo)"
                    />
                </template>
            </v-data-table>
        </v-form>
    </div>
</template>

<script>

import { getQuestionarioAvulso } from "../../api/questionario_avulso";
import { uploadQuestionarioAvulsoAnexo, getQuestionarioAvulsAnexoDownload, deleteQuestionarioAvulsoAnexo  } from "../../api/questionario_avulso_anexo";
import { validationMixin } from "../../mixins/validationMixin";
import DeleteAction from "../inputs/DeleteAction.vue";

export default {
    name: "QuestionarioAvulsoAnexo",
    mixins: [validationMixin],
    components: { DeleteAction },
    props: {       
        disabled: {
            type: Boolean,
        },
        anexoItens: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {
            anexos: [],
            form: {
                ds_questionario_avulso_anexo: null,
                file: null,
            },
            anexoHeaders: [
                { text: 'Descrição', value: 'ds_questionario_avulso_anexo', sortable: false },
                { text: 'Ações', value: 'actions', sortable: false },
            ],
            visualizar: false,
            responder: false
        }
    },
    created() {
        this.visualizar = this.$route.name == 'questionario-avulso-visualizar' ? true : false;
        this.responder = this.$route.name == 'questionario-avulso-responder' ? true : false;

        this.anexos = this.anexoItens;
    },
    methods: {
        addAnexo() {
            if (this.$refs.form.validate()) {

                let promise;
                let formData = new FormData();

                formData.append('id_questionario_avulso', this.$route.params.questionario);
                formData.append('ds_questionario_avulso_anexo', this.form.ds_questionario_avulso_anexo);
                formData.append('file', this.form.file);

                if (this.$route.params.questionario) {                    
                    promise = uploadQuestionarioAvulsoAnexo(this.$route.params.questionario, formData);
                }

                promise.then(response => {
                    this.updateAnexos();                    
                    this.form.ds_questionario_avulso_anexo = null;
                    this.form.file = null;
                })
                .catch(error => this.handleError(error))
            }                
        },
        updateAnexos() {
            getQuestionarioAvulso(this.$route.params.questionario).then((response) => {
                this.anexos = response.data.data.anexos;
                this.$emit("questionarioAvulsoAnexoEmit", this.anexos);
            })
        },

        deletar(id_questionario_avulso_anexo) {
            deleteQuestionarioAvulsoAnexo(id_questionario_avulso_anexo)
                .then(response => {
                    this.updateAnexos();
                });
        },
        
        download(id_questionario_avulso_anexo) {
            getQuestionarioAvulsAnexoDownload(id_questionario_avulso_anexo)
                .then(response => {
                    this.forceFileDownload(response);
                });
        },
        forceFileDownload(response, file = 'questionarioAvulsoAnexo.pdf') {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', file) //or any other extension
            document.body.appendChild(link)
            link.click()
        },
    },
}
</script>
