<template>
    <v-main>
        <v-container>
            <div class="container">
                <div class="center">
                    <v-sheet
                        color="grey lighten-5"
                        elevation="24"
                        rounded                        
                        class="mx-auto"
                    >
                        <v-row>
                            <v-col cols="12">
                                <Alerta class="alert" />
                            </v-col>
                        </v-row>
                        <v-row>                            
                            <v-col cols="12" md="12">
                                <v-sheet
                                    class="desc-sisrrf"
                                    rounded                                    
                                >
                                    <v-row>
                                        <v-col cols="12" md="12">
                                            <div >
                                                <h1 class="sisrrf-title">
                                                    <strong>
                                                        SisRRF
                                                    </strong>
                                                </h1>
                                                <h3 class="sisrrf-subtitle">
                                                    Sistema de Monitoramento do Regime de Recuperação Fiscal
                                                </h3>
                                            </div>
                                            <v-spacer></v-spacer>
                                            <br/>
                                            <br/>
                                            <p>
                                                Com o objetivo de orientar os estados que tiveram seu pedido de adesão ao RRF deferido, o CSRRF, órgão do Ministério da Economia, com apoio do TCU, disponibiliza o Sistema de Monitoramento do RRF (SisRRF) para utilização pelos estados.
                                            </p>
                                            <p>
                                                O SisRRF <strong>visa registrar os status e as atualizações referentes ao art.7º-D da LCF nº159/2017, a fim de subsidiar relatório bimestral de monitoramento.</strong>
                                            </p>                                            
                                        </v-col>

                                    </v-row>
                                    <v-row>                                        
                                        <v-col cols="12" md="12">
                                            <div class="acesso-sisrrf">                                    
                                                <v-btn
                                                    rounded
                                                    color="primary"
                                                    x-large
                                                    @click="loginUnico"
                                                    class="font-weight-light"
                                                >
                                                    <v-icon large left>mdi-account</v-icon>
                                                    Entrar com <span class="ml-1 font-weight-bold">gov.br</span>
                                                </v-btn>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-sheet>
                            </v-col>
                        </v-row>

                    </v-sheet>                    
                </div>
            </div>
        </v-container>
    </v-main>
</template>

<script>

import { getUsuarioByCpf } from "../../api/usuario";
import { updateConfiguracaoOut } from "../../api/configuracao";
import Alerta from "../app/Alerta";
import { validationMixin } from "../../mixins/validationMixin";
export default {
    name: "Login",
    components: {Alerta},
    mixins: [validationMixin],
    data() {
        return {       
            acesso: '1',     
            form: {
                ds_valor: null,
                nm_configuracao: 'usr_logon',
                st_interna: true,
            },
        }
    },
    methods: {
        generateNonce() {
            var nonce = "";
            var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            for (var i = 0; i < 50; i++) {
                nonce += possible.charAt(Math.floor(Math.random() * possible.length));
            }
            return nonce;
        },
        loginLegado() {

            this.form.ds_valor = this.form.ds_valor.replace(/[-.]/g, '');

            let promise;
            promise = updateConfiguracaoOut(1, this.form);

            getUsuarioByCpf(this.form.ds_valor).then((response) => {
                if (!!response.data.data) {
                    promise.then(response => {
                        this.$router.push({name: 'valida', params: { code: this.form.ds_valor }});
                    })                            
                } else {
                    this.$store.dispatch('alertas/show', {
                    tipo: 'Erro',
                titulo: 'Erro de login!',
                        mensagem: 'Usuário não possui cadastro na base de dados.',
            });
            this.$vuetify.goTo(0);
                }
            }).catch(error => this.handleError(error)) 
        },
        loginUnico() {
            const url = process.env.MIX_GOVBR_URL + process.env.MIX_GOVBR_AUTHORIZE_ENDPOINT;
            const params = `response_type=${process.env.MIX_GOVBR_RESPONSE_TYPE}&client_id=${process.env.MIX_GOVBR_CLIENT_ID}&scope=${process.env.MIX_GOVBR_SCOPE}&redirect_uri=${process.env.MIX_GOVBR_REDIRECT_URI}&nonce=${this.generateNonce()}`;
            window.open(`${url}?${params}`, '_self');
        },
        handleError(error) {

            this.$store.dispatch('alertas/show', {
                tipo: 'Erro',
                titulo: error.response.data.error.message.title,
                mensagem: error.response.data.error.message.msg,
            });
            this.$vuetify.goTo(0);
        }
    },
}
</script>

<style scoped>

.container {
    position: relative;
    top: 100px;
    padding: 2%;
}

.center {
    margin: auto;
    width: 86%;    
    padding: 10px;
}

.desc-sisrrf {
    margin: auto;
    width: 98%;
    background-color: #002051;
    color: white;
    text-align: justify;
    padding: 4%;    
}

.acesso-sisrrf {
    text-align: center;
    margin-top: 2%;
}

.alert {
    margin: auto;
    width: 98%;    
}

.sisrrf-title {
    text-align: left;
    font-size: 2.5em;
    font-weight: 300;
    margin-bottom: 0 !important;
}

.sisrrf-subtitle {
    margin-top: -5px !important;
    padding-top: -5px !important;
}

</style>