<template>
    <div>
        <v-form ref="form">
            <v-row>
                <v-col cols="12">
                    <v-sheet class="pa-4  mb-5" rounded outlined>
                        <v-row>
                            <v-col cols="12" md="10">
                                <v-text-field
                                    label="Nº SEI *"
                                    outlined
                                    dense
                                    v-model="form.nr_sei"
                                    :rules="validate('required|max 250', 'Nº SEI')"
                                    :disabled="visualizar"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-text-field                            
                                    label="Data do alerta *"
                                    outlined
                                    type="date"
                                    dense
                                    v-model="form.dt_alerta"    
                                    :rules="validate('required', 'Data do alerta')"  
                                    :disabled="visualizar"                      
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-textarea
                                    label="Texto do alerta *"
                                    outlined
                                    dense
                                    v-model="form.tx_alerta"
                                    :rules="validate('required', 'Texto do alerta')"
                                    :disabled="visualizar"
                                ></v-textarea>
                            </v-col>
                        </v-row>

                    </v-sheet>
                </v-col>
            </v-row>
            <div class="d-flex">
                <v-spacer></v-spacer>
                <v-btn
                    class="mx-2"
                    color="error"
                    @click="voltar()"
                >
                    Voltar
                </v-btn>

                <v-btn v-if="!visualizar"
                       color="primary"
                       @click="enviar()"
                >
                    Salvar
                </v-btn>
            </div>
        </v-form>
    </div>
</template>

<script>

    import ConselhoSelect from "../../components/inputs/ConselhoSelect.vue";
    import {validationMixin} from "../../mixins/validationMixin";
    import {createAlerta, getAlerta} from "../../api/alerta";

    import moment from 'moment';
    moment.locale('pt-br');

    export default {
        components: {ConselhoSelect},
        mixins: [validationMixin],
        data() {
            return {
                search: '',
                visualizar: null,
                form: {
                    nr_sei: null,
                    dt_alerta: null,
                    tx_alerta: null
                },
                usuarios: [],
                headers: [
                    {key: 'usuario', text: 'Nome', value: 'nm_usuario'},
                    {key: 'ciente', text: 'Ciente em:', value: 'read_at'},
                ],
                fields: {
                    id_conselho: [],
                },
                error: {},
            }
        },
        methods: {
            voltar() {
                this.$router.push({name: 'alerta'});
            },
            enviar() {
                if (this.$refs.form.validate()) {

                    createAlerta(this.form).then(response => {
                        this.$store.dispatch('alertas/show', {
                            tipo: 'Sucesso',
                            titulo: response.data.message,
                        })
                        this.$vuetify.goTo(0)
                        this.$router.push({name: 'alerta'})
                    });

                }
            }
        },
        created() {
            this.visualizar = this.$route.name === 'alerta-visualizar';

            if (this.$route.params.alerta) {
                getAlerta(this.$route.params.alerta).then((response) => {
                    this.form.id_alerta = response.data.data.id_alerta;
                    this.form.dt_alerta = moment(response.data.data.dt_alerta).format("YYYY-MM-DD");
                    this.form.nr_sei = response.data.data.nr_sei;
                    this.form.tx_alerta = response.data.data.tx_alerta;
                })
            }
        }
    }
</script>
