import api from './index';


const createAlerta = (dados) => api.post('alerta', dados);

const getAlerta = (id_alerta) => api.get(`alerta/${id_alerta}`);

const getAlertaPendentes = () => api.get('alerta/alerta_pendentes');

const marcarAlertaLido = (id_alerta) => api.put(`alerta/${id_alerta}`);

const deletarAlerta = (id_alerta) => api.delete(`alerta/${id_alerta}`);

const getAlertaPaginate = async (page, itemsPerPage, filtrosAplicados = []) => await api.get('alerta/alerta-paginate', {
    params: {
        page: page,
        limit: itemsPerPage,
        filtros: filtrosAplicados,
    },
    showLoader: true
});

export {
    getAlertaPaginate,
    createAlerta,
    deletarAlerta,
    getAlerta,
    getAlertaPendentes,
    marcarAlertaLido
}
