<template>
    <div>
        <v-card class="mb-9">
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-select
                            label="Conselho"
                            outlined
                            :disabled="disableConselho"
                            v-model="filtro.id_conselho"
                            dense
                            clearable
                            :items="conselhosSelect"
                            item-value="id_conselho"
                            item-text="nm_conselho"
                            hide-details="false"
                            @change="(event) => conselhoChange(event)"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-select
                            label="Orgão"
                            outlined
                            :disabled="disableOrgao"
                            v-model="filtro.id_orgao"
                            dense
                            clearable
                            :items="orgaosSelect"
                            item-value="id_orgao"
                            item-text="nm_orgao"
                            hide-details="false"
                            @change="() => orgaoChange()"
                        />
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-select
                            label="Status Resposta"
                            outlined
                            :disabled="disableRespostaStatus"
                            v-model="filtro.id_resposta_status"
                            dense
                            :items="respostaStatusSelect"
                            item-value="id_resposta_status"
                            item-text="nm_resposta_status"
                            hide-details="false"
                        />
                    </v-col>
                    <v-col cols="12" md="8">
                        <v-select
                            label="Pergunta"
                            outlined
                            :disabled="disablePergunta"
                            v-model="filtro.id_pergunta"
                            dense
                            clearable
                            :items="perguntasSelect"
                            item-value="id_pergunta"
                            item-text="ds_pergunta"
                            hide-details="false"
                        />
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-select
                            label="Abriu Processo?"
                            outlined
                            dense
                            :disabled="disableNrSei"
                            v-model="filtro.nr_sei"
                            :items="[
                                {value: 1, text: 'Sim'},
                                {value: null, text: 'Não'},
                            ]"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-select
                            label="Inciso ocorreu?"
                            outlined
                            dense
                            :disabled="disableStResposta"
                            v-model="filtro.st_resposta"
                            :items="[
                                {value: null, text: 'Todos'},
                                {value: true, text: 'Sim'},
                                {value: false, text: 'Não'},
                            ]"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-select
                            label="Situação do Regime"
                            outlined
                            :disabled="disableSituacaoRegime"
                            v-model="filtro.id_situacao_regime"
                            dense
                            clearable
                            :items="situacoesRegimeSelect"
                            item-value="id_situacao_regime"
                            item-text="nm_situacao_regime"
                            hide-details="false"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-select
                            label="Mês"
                            outlined
                            dense
                            :disabled="disableMes"
                            v-model="filtro.mes"
                            :items="[
                            {value: null, text: 'Todos'},
                            {value: '01', text: 'Janeiro'},
                            {value: '02', text: 'Feveiro'},
                            {value: '03', text: 'Março'},
                            {value: '04', text: 'Abril'},
                            {value: '05', text: 'Maio'},
                            {value: '06', text: 'Junho'},
                            {value: '07', text: 'Julho'},
                            {value: '08', text: 'Agosto'},
                            {value: '09', text: 'Setembro'},
                            {value: '10', text: 'Outubro'},
                            {value: '11', text: 'Novembro'},
                            {value: '12', text: 'Dezembro'},
                            ]"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-select
                            label="Ano"
                            outlined
                            dense
                            :disabled="disableAno"
                            v-model="filtro.ano"
                            :items="anosSelect"
                            item-value="ano_value"
                            item-text="ano_text"
                            hide-details="false"
                        ></v-select>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="success"
                    @click="toXlsAll()"
                >
                    <v-icon left>
                        mdi-file-excel
                    </v-icon>
                    Excel
                </v-btn>
                <v-btn
                    outlined
                    @click="limparFiltro()"
                >
                    Limpar
                </v-btn>
                <v-btn
                    color="primary"
                    @click="filtrar()"
                >
                    <v-icon left>
                        mdi-filter
                    </v-icon>
                    Filtrar
                </v-btn>
            </v-card-actions>
        </v-card>

        <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page="pagination.itemsPerPage"
            :footer-props="footerProps"
            :options.sync="pagination"
            :server-items-length="pagination.total"
            class="elevation-1"
            @update:options="filtrar()"
        >
            <template v-slot:item.dt_mes_ano="{ item }">
                <span>{{ dateFormat(item.dt_mes_ano) }}</span>
            </template>
            <template v-slot:item.resposta="{ item }">
                <v-tooltip top>
                    <template v-slot:activator="{ on: tooltip}">
                        <v-btn
                            fab
                            color="gray"
                            x-small
                            elevation="0"
                            v-on="{ ...tooltip}"
                        >
                            <router-link :to="visualizarResposta(item)" v-on="{ ...tooltip }">
                                <v-icon>
                                    mdi-magnify
                                </v-icon>>
                            </router-link>
                        </v-btn>
                    </template>
                    <span>Visualizar Resposta</span>
                </v-tooltip>
            </template>
            <template v-slot:item.excel="{ item }">
                <v-tooltip top>
                    <template v-slot:activator="{ on: tooltip}">
                        <v-btn
                            fab
                            color="success"
                            x-small
                            elevation="0"
                            @click="toXlsIndividual(item)"
                            v-on="{ ...tooltip}"
                        >
                            <v-icon>
                                mdi-file-excel
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Gerar Excel</span>
                </v-tooltip>
            </template>
        </v-data-table>
    </div>
</template>

<script>

import { getQuestionariosRespondidosPaginate, getAllPerguntas, getAnosQuestionarios } from "../../api/questionario_respondido";
import { getConselhos } from "../../api/conselho";
import { getOrgaosByConselho } from "../../api/orgao";
import { getSituacoesRegime } from "../../api/situacao_regime";
import {getRespostaStatus} from "../../api/resposta_status";
import { dataTableMixin } from "../../mixins/dataTableMixin";
import { filterNormalizeMixin } from "../../mixins/filterNormalizeMixin";
import { getMe } from "../../api/usuario";
import ExcelJS from 'exceljs';

import moment from 'moment';
import FilterService from "../../filters/filterService";

// Mapeamento das chaves originais para os novos nomes desejados
const headerMapping = {
    'id_questionario': 'Questionário',
    'dt_mes_ano': 'Mês/Ano',
    'orgao': 'Órgão',
    'conselho': 'Conselho',
    'pergunta': 'Inciso',
    'ds_descricao': 'Descrição',
    'despacho_estadual': 'Despacho Estadual',
    'despacho_federal': 'Despacho Federal',
    'situacao_regime': 'Situação do Regime',
};

moment.locale('pt-br');
export default {
    name: "relatorio-questionario-respondido",
    components: {},
    mixins: [dataTableMixin, filterNormalizeMixin],
    data() {
        return {
            filtro: {
                id_orgao: null,
                id_conselho: null,
                id_pergunta: null,
                id_resposta_status: null,
                ano: null,
                mes: null,
                nr_sei: null,
                id_situacao_regime: null,
                st_resposta: null
            },
            conselhosSelect: [],
            orgaosSelect: [],
            perguntasSelect: [],
            situacoesRegimeSelect: [],
            respostaStatusSelect: [],
            anosSelect: [],
            loading: true,
            user: null,
            disableConselho: false,
            disableOrgao: false,
            disablePergunta: false,
            disableRespostaStatus: false,
            disableSituacaoRegime: false,
            disableMes: false,
            disableAno: false,
            disableStResposta: false,
            disableNrSei: false,
            headers: [
                // { text: 'ID', value: 'id_questionario', sortable: false },
                {
                    text: 'Resposta',
                    align: 'start',
                    sortable: false,
                    value: 'resposta'
                },
                { text: 'Mês/Ano', value: 'dt_mes_ano', sortable: false },
                { text: 'Orgão', value: 'orgao.nm_orgao', sortable: false, width: '250px' },
                { text: 'Inciso', value: 'pergunta.full', sortable: false, width: '250px' },
                { text: 'Descrição', value: 'ds_descricao', sortable: false, width: '250px'},
                { text: 'Nº SEI', value: 'resposta.nr_sei', sortable: false},
                { text: 'Situação do Regimme', value: 'situacao_regime.nm_situacao_regime', sortable: false, width: '150px' },
                { text: 'Despacho Estadual', value: 'despacho_estadual.ds_despacho', sortable: false, width: '250px' },
                { text: 'Despacho Federal', value: 'despacho_federal.ds_despacho', sortable: false, width: '250px' },
                { text: 'Status Resposta', value: 'resposta.status_resposta.nm_resposta_status', sortable: false, width: '100px' },
                { text: 'Ação', value: 'excel', sortable: false },
            ]
        }
    },
    methods: {
        conselhoChange(event) {
            if (this.filtro.id_conselho != null) {
                getOrgaosByConselho(event).then(
                    response => this.orgaosSelect = response.data.data
                );
                this.filtro.id_orgao = null;
            } else {
                this.orgaosSelect = [];
            }
            this.orgaoChange();
        },

        orgaoChange() {
            getAnosQuestionarios(this.filtro).then((anos) => {
                const todosOption = { ano_value: null, ano_text: "Todos" };
                this.anosSelect = [todosOption, ...anos.data.data];
            });
        },

        filtrar() {
            getQuestionariosRespondidosPaginate(this.pagination.page, this.pagination.itemsPerPage, this.filtro)
                .then(response => {
                    this.items = response.data.data;
                    this.pagination.total = response.data.data.total;

                    if (this.pagination.total < this.pagination.itemsPerPage) {
                        this.pagination.page = 1;
                    }
                });
        },
        limparFiltro() {
            this.filtro = {
                id_orgao: null,
                id_conselho: null,
                id_pergunta: null,
                id_resposta_status: null,
                ano: null,
                mes: null,
                nr_sei: null,
                id_situacao_regime: null,
                st_resposta: null
            };

            FilterService.clearFilters(this.$route.name);
            this.filtrar();
        },
        dateFormat(date) {
            if (!!date) {
                return moment(date).format("MM/YYYY")
            }
            return null;
        },
        visualizarResposta(item) {
          ///questionarios/visualizar/:questionario/pergunta/:pergunta/resposta/visualizar/:resposta
            return {
                name: 'qr-resposta-visualizar',
                params: {
                    questionario: item.id_questionario,
                    pergunta: item.pergunta.id,
                    resposta: item.resposta.id_resposta
                }
            };
        },

        async toXlsIndividual(item) {
            try {
                // Criar uma nova instância do ExcelJS
                const workbook = new ExcelJS.Workbook();
                const worksheet = workbook.addWorksheet('Dados');

                // Adicionar cabeçalhos
                let headers = Object.keys(item);
                headers = this.removeUnwantedKeys(headers, ['resposta', 'questionario_status']);
                headers = this.renameHeaders(headers, headerMapping);
                headers = this.addExtraColumns(headers, ['Status Resposta', 'Nº SEI']);

                worksheet.addRow(headers);
                worksheet.addRow(this.createDataRow(item));

                await this.generateAndDownloadExcel(workbook, 'dados_individual.xlsx');

            } catch (error) {
                this.$store.dispatch('alertas/show', {
                    tipo: 'Erro',
                    titulo: 'Erro ao gerar o arquivo Excel'
                })
                this.$vuetify.goTo(0);
            }
        },

        async toXlsAll() {
            if (!this.items[0]?.id_questionario) {
                this.$store.dispatch('alertas/show', {
                    tipo: 'Erro',
                    titulo: 'Nenhum dado disponível.'
                });
                return this.$vuetify.goTo(0);
            }

            try {
                const workbook = new ExcelJS.Workbook();
                const worksheet = workbook.addWorksheet('Dados');

                let headers = Object.keys(this.items[0]);
                headers = this.removeUnwantedKeys(headers, ['resposta', 'questionario_status']);
                headers = this.renameHeaders(headers, headerMapping);
                headers = this.addExtraColumns(headers, ['Status Resposta', 'Nº SEI']);

                worksheet.addRow(headers);

                this.items.forEach(item => {
                    worksheet.addRow(this.createDataRow(item));
                });

                await this.generateAndDownloadExcel(workbook, 'dados.xlsx');
            } catch (error) {
                this.$store.dispatch('alertas/show', {
                    tipo: 'Erro',
                    titulo: 'Erro ao gerar o arquivo Excel'
                });
                this.$vuetify.goTo(0);
            }
        },
        removeUnwantedKeys(headers, keysToRemove) {
            return headers.filter(header => !keysToRemove.includes(header));
        },
        renameHeaders(headers, mapping) {
            return headers.map(header => mapping[header] || header);
        },

        addExtraColumns(headers, extraColumns) {
            extraColumns.forEach(column => {
                if (!headers.includes(column)) {
                    headers.push(column);
                }
            });
            return headers;
        },
        createDataRow(item) {
            return [
                `${item.id_questionario}`,
                moment(item.dt_mes_ano).format("MM/YYYY"),
                `${item.orgao.id} - ${item.orgao.nm_orgao}`,
                `${item.conselho.id} - ${item.conselho.nm_conselho}`,
                `${item.pergunta?.id ?? ''} - ${item.pergunta?.ds_pergunta ?? ''}`,
                item.ds_descricao ?? '',
                `${item.situacao_regime?.id ?? ''} - ${item.situacao_regime?.nm_situacao_regime ?? ''}`,
                `${item.despacho_estadual?.id_despacho ?? ''} - ${item.despacho_estadual?.ds_despacho ?? ''}`,
                `${item.despacho_federal?.id_despacho ?? ''} - ${item.despacho_federal?.ds_despacho ?? ''}`,
                `${item.resposta?.status_resposta?.id_resposta_status ?? ''} - ${item.resposta?.status_resposta?.nm_resposta_status ?? ''}`,
                item.resposta?.nr_sei ?? ''
            ];
        },
        async generateAndDownloadExcel(workbook, filename) {
            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
        },
    },
    created() {
        getConselhos().then((conselho_response) => {
            this.conselhosSelect = conselho_response.data.data;

            if (!this.$store.getters['auth/usuarioHasRole'](['Administrador'])) { // se não for administrador
                getMe().then(user_response => {
                    this.user = user_response.data;

                    if (this.$store.getters['auth/usuarioHasRole'](['Analista'])) {

                        if (this.user.conselho.id_conselho_pai != null) { // Analista Estadual
                            this.disableConselho = true;
                            this.filtro.id_conselho = this.user.id_conselho;

                            getOrgaosByConselho(this.user.id_conselho).then((orgao_response) => {
                                this.orgaosSelect = orgao_response.data.data;
                            });

                            this.filtrar();
                        } else { // Analista Federal
                            this.filtrar();
                        }
                    } else if (this.$store.getters['auth/usuarioHasRole'](['Cadastrador', 'Consultor'])) {
                        getOrgaosByConselho(this.user.id_conselho).then((orgao_response) => {

                            this.disableOrgao = true;
                            this.disableConselho = true;
                            this.orgaosSelect = orgao_response.data.data;
                            this.filtro.id_conselho = this.user.id_conselho;

                            orgao_response.data.data.forEach(orgao => {
                                if (orgao.id_orgao == user_response.data.id_orgao) {
                                    this.filtro.id_orgao = orgao.id_orgao;
                                }
                            });

                            this.filtrar();
                        });
                    }
                });

            } else {
                this.filtrar();
            }

        });

        getAllPerguntas().then((perguntas_response) => {
            this.perguntasSelect = perguntas_response.data.data;
        });

        getSituacoesRegime().then((situacoes_regime_response) => {
            this.situacoesRegimeSelect = situacoes_regime_response.data.data;
        });

        getRespostaStatus().then((resposta_status) => {
           this.respostaStatusSelect = [{ id_resposta_status: null, nm_resposta_status: "Todos" }, ...resposta_status.data.data];
        });

        getAnosQuestionarios({'id_orgao': null, 'id_conselho': null}).then((anos) => {
            const todosOption = { ano_value: null, ano_text: "Todos" };
            this.anosSelect = [todosOption, ...anos.data.data];
        });
    },
    mounted() {
        const savedFilters = FilterService.loadFilters(this.$route.name);
        if (savedFilters) {
            this.filtro = savedFilters;
        }
    },
    beforeRouteLeave(to, from, next) {
        FilterService.saveFilters(from.name, this.filtro);
        next();
    },
}
</script>

<style>

.vue-js-switch.changed-font {
    font-size: 16px !important;
    margin: 10px;;
}

.v-dialog {
    max-width: 50%;
}

.div-historico {
    max-height: 800px;
    overflow-y: auto;
}

</style>


