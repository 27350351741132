import api from './index';

const getConselhos = () => api.get('conselho/conselhos');
const getConselho = (id_conselho) => api.get(`conselho/${id_conselho}`);
const createConselho = (dados) => api.post('conselho', dados);
const updateConselho = (id_conselho, dados) => api.put(`conselho/${id_conselho}`, dados);
const deleteConselho = (id_conselho) => api.delete(`conselho/${id_conselho}`);

const getConselhosPaginate = async (page, itemsPerPage, filtrosAplicados = []) => await api.get('conselho/conselhos-paginate', {
    params: {
        page: page,
        limit: itemsPerPage,
        filtros: filtrosAplicados,
    },
    showLoader: true
});

const ativarInativarConselho = (id_conselho, st_Status) => api.put(`conselho/ativar-inativar/${id_conselho}`, st_Status);

export {
    getConselhos,
    getConselhosPaginate,
    getConselho,
    createConselho,
    updateConselho,
    deleteConselho,
    ativarInativarConselho
}
