<template>
    <div>
        <v-card class="mb-9">
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="8">
                        <v-text-field
                            label="Nome"
                            outlined
                            dense
                            @keyup.enter="filtrar()"
                            v-model="filtro.nm_orgao"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-select
                            label="Status"
                            outlined
                            dense
                            clearable
                            v-model="filtro.st_status"
                            :items="[
                                {value: null, text: 'Todos'},
                                {value: true, text: 'Ativo'},
                                {value: false, text: 'Inativo'},
                            ]"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="12">
                        <ConselhoSelect
                            label="Conselho"
                            v-model="filtro.id_conselho"
                            :disabled="disableConselho"
                        />
                    </v-col>
                </v-row>
                <v-row>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="success"
                    @click="toXlsAll()"
                >
                    <v-icon left>
                        mdi-file-excel
                    </v-icon>
                    Excel
                </v-btn>
                <v-btn
                    outlined
                    @click="limparFiltro()"
                >
                    Limpar
                </v-btn>
                <v-btn
                    color="primary"
                    @click="filtrar()"
                >
                    <v-icon left>
                        mdi-filter
                    </v-icon>
                    Filtrar
                </v-btn>
            </v-card-actions>
        </v-card>

        <div class="d-flex my-3">
            <v-spacer></v-spacer>
            <v-btn v-if="$store.getters['auth/usuarioHasPermissao'](['OrgãoCriar'])"
                color="primary"
                :to="{name: 'orgao-novo'}"
            >
                Novo Registro
            </v-btn>
        </div>

        <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page="pagination.itemsPerPage"
            :footer-props="footerProps"
            :options.sync="pagination"
            @update:options="filtrar()"
            :server-items-length="pagination.total"
            class="elevation-1"
        >

            <template v-slot:item.st_status="{ item }">
                <v-chip
                    v-if="item.st_status"
                    small
                    outlined
                    color="primary"
                >
                    Ativo
                </v-chip>
                <v-chip
                    v-else
                    small
                    outlined
                    color="error"
                >
                    Inativo
                </v-chip>
            </template>
            <template v-if="$store.getters['auth/usuarioHasPermissao'](['OrgãoVisualizar'])" v-slot:item.actions="{ item }">
                <v-tooltip top>
                    <template v-slot:activator="{ on: tooltip}">
                        <v-btn
                            fab
                            color="cyan"
                            x-small
                            elevation="0"
                            :to="{name: 'orgao-visualizar', params: {orgao: item.id_orgao}}"
                            v-on="{ ...tooltip}"
                        >
                            <v-icon>
                                mdi-magnify
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Visualizar</span>
                </v-tooltip>
                <v-tooltip v-if="$store.getters['auth/usuarioHasPermissao'](['OrgãoAtualizar'])" top>
                    <template v-slot:activator="{ on: tooltip}">
                        <v-btn
                            fab
                            color="success"
                            x-small
                            elevation="0"
                            :to="{name: 'orgao-editar', params: {orgao: item.id_orgao}}"
                            v-on="{ ...tooltip}"
                        >
                            <v-icon>
                                mdi-pencil
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Editar</span>
                </v-tooltip>
<!--                <DeleteAction v-if="$store.getters['auth/usuarioHasPermissao'](['OrgãoDeletar'])" @delete="deletar(item[column_id])"/>-->
                <ConditionalDeleteAction
                    v-if="$store.getters['auth/usuarioHasPermissao'](['OrgãoDeletar'])"
                    :showCheckButton="item.st_status ? '1' : null"
                    @delete="deletar(item[column_id],item.st_status)"
                />
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { getOrgaosPaginate, deleteOrgao, getOrgao, getOrgaosWithoutPaginate, ativarInativarOrgao } from "../../api/orgao";
import { getConselhos } from "../../api/conselho";
import { dataTableMixin } from "../../mixins/dataTableMixin";
import ConselhosChip from "../../components/inputs/ConselhosChip.vue";
import _ from "lodash";
import { filterNormalizeMixin } from "../../mixins/filterNormalizeMixin";
import DeleteAction from "../../components/inputs/DeleteAction.vue";
import ConselhoSelect from "../../components/inputs/ConselhoSelect.vue";
import { getMe } from "../../api/usuario";
import ExcelJS from 'exceljs';
import ConditionalDeleteAction from "../../components/inputs/ConditionalDeleteAction.vue";
import FilterService from "../../filters/filterService";

export default {
    name: "OrgaosListar",
    components: {ConditionalDeleteAction, DeleteAction, ConselhosChip, ConselhoSelect },
    mixins: [dataTableMixin, filterNormalizeMixin],
    data() {
        return {
            filtro: {
                nm_orgao: null,
                id_conselho: null,
                st_status: null,
            },
            loading: true,
            column_id: 'id_orgao',
            headers: [
                // { text: 'ID', value: 'id_orgao', sortable: false },
                { text: 'Nome', value: 'nm_orgao', sortable: false },
                { text: 'Sigla', value: 'sg_sigla', sortable: false },
                { text: 'E-mail', value: 'ds_email', sortable: false },
                { text: 'Telefone', value: 'nr_telefone', sortable: false },
                { text: 'Conselho', value: 'conselho.sg_sigla', sortable: false},
                { text: 'Status', value: 'st_status', sortable: false, width: '70px' },
                { text: 'Ações', value: 'actions', sortable: false, width: '135px' },
            ],
            disableConselho: false
        }
    },
    created() {

        getConselhos().then((conselho_response) => {

            this.conselhosSelect = conselho_response.data.data;

            if (!this.$store.getters['auth/usuarioHasRole'](['Administrador'])) { // se não for administrador

                getMe().then(user_response => {
                    this.user = user_response.data;

                    if (this.$store.getters['auth/usuarioHasRole'](['Analista']) || this.$store.getters['auth/usuarioHasRole'](['Consultor'])) {
                        if (this.user.conselho.id_conselho_pai != null || this.$store.getters['auth/usuarioHasRole'](['Consultor'])) { // Analista Estadual e Consultor
                            this.disableConselho = true;
                            this.filtro.id_conselho = this.user.id_conselho;
                            this.filtrar();
                        } else { // Analista Federal
                            this.filtrar();
                        }
                    }
                });
            } else {
                this.filtrar();
            }

        });
    },
    mounted() {
        const savedFilters = FilterService.loadFilters(this.$route.name);
        if (savedFilters) {
            this.filtro = savedFilters;
        }
    },
    beforeRouteLeave(to, from, next) {
        FilterService.saveFilters(from.name, this.filtro);
        next();
    },
    methods: {
        limparFiltro() {
            this.filtro = {
                nm_orgao: null,
                id_conselho: null,
                st_status: null,
            };

            FilterService.clearFilters(this.$route.name);
            this.filtrar()
        },
        filtrar() {
            getOrgaosPaginate(this.pagination.page, this.pagination.itemsPerPage, this.filtro)
                .then(response => {
                    this.items = response.data.data.data;
                    this.pagination.total = response.data.data.total;
                    if (this.pagination.total < this.pagination.itemsPerPage) {
                        this.pagination.page = 1;
                    }
                });
        },
        deletar(id, st_status) {
            let promise;
            let msg = 'Órgão ' + (!st_status ? 'Ativado' : 'Inativado') + ' com sucesso.';

            promise = ativarInativarOrgao(id, {'st_status':!st_status});

            promise.then(response => {
                this.$store.dispatch('alertas/show', {
                    tipo: 'Sucesso',
                    titulo: msg,
                })
                this.$vuetify.goTo(0);
                this.filtrar();
            })
        },
        // deletar(id) {
        //
        //     getOrgao(id).then((response) => {
        //         deleteOrgao(id).then((response) => {
        //             this.items = this.items.filter(item => item[this.column_id] !== id)
        //             this.$store.dispatch('alertas/show', {
        //                 tipo: 'Sucesso',
        //                 titulo: response.data.success.message,
        //             });
        //             this.$vuetify.goTo(0);
        //         })
        //         .catch(error => this.handleError(error))
        //     })
        // },
        handleError(error) {

            this.$store.dispatch('alertas/show', {
                tipo: 'Erro',
                titulo: error.response.data.error.message.title,
                mensagem: error.response.data.error.message.msg,
            });
            this.$vuetify.goTo(0);

            if (error.response.data.error.message.type == 403) {
                this.$router.push({name: 'home'});
            }
        },

        async toXlsAll() {
            let allOrgaos = [];

            await getOrgaosWithoutPaginate(this.filtro)
                .then(response => {
                    allOrgaos = response.data.data
            });

            if (!allOrgaos[0]?.id_orgao) {
                this.$store.dispatch('alertas/show', {
                    tipo: 'Erro',
                    titulo: 'Nenhum dado disponível.'
                });
                return this.$vuetify.goTo(0);
            }

            try {
                const workbook = new ExcelJS.Workbook();
                const worksheet = workbook.addWorksheet('Dados');

                let headers = ['Nome','Sigla','Conselho','Status'];

                worksheet.addRow(headers);

                allOrgaos.forEach(item => {
                    worksheet.addRow(this.createDataRow(item));
                });

                await this.generateAndDownloadExcel(workbook, 'orgaos.xlsx');
            } catch (error) {
                this.$store.dispatch('alertas/show', {
                    tipo: 'Erro',
                    titulo: 'Erro ao gerar o arquivo Excel'
                });
                this.$vuetify.goTo(0);
            }
        },
        createDataRow(item) {
            return [
                `${item.nm_orgao}`,
                `${item.sg_sigla ?? ''}`,
                `${item.conselho?.sg_sigla ?? ''}`,
                `${item.st_status ? 'Ativo' : 'Inativo'}`
            ];
        },
        async generateAndDownloadExcel(workbook, filename) {
            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
        },
    }
}
</script>
