<template>
    <div class="pt-8">
        <v-row>
            <v-col cols="12" md="12">
                <v-sheet v-if="$store.getters['auth/usuarioHasRole'](['Administrador', 'Analista', 'Consultor'])" class="pa-5"
                         outlined rounded>
                    <h2 class="mb-3">Gerenciar</h2>
                    <v-row class="mt-1 text-center">
                        <v-col v-for="(opcao, key) in opcoesGerenciarVisible" :key="key" cols="6" lg="3" xl="2">
                            <v-hover v-slot="{ hover }">
                                <v-card
                                    :to="opcao.to"
                                    :elevation="hover ? 5 : 2"
                                    outlined
                                    height="130"
                                    class="pa-2"
                                    style="position: relative"
                                >
                                    <div class="pa-3">
                                        <v-icon color="primary" class="text-h3">{{ opcao.icon }}</v-icon>
                                    </div>
                                    <h3 class="text-subtitle-2">{{ opcao.title }}</h3>
                                </v-card>
                            </v-hover>
                        </v-col>
                    </v-row>
                </v-sheet>
                <v-sheet class="pa-5" outlined rounded>
                    <h2 class="mb-3">Funcionalidades</h2>
                    <v-row class="mt-1 text-center">
                        <v-col v-for="(opcao, key) in opcoesFuncionalidadesVisible" :key="key" cols="6" lg="3" xl="2">
                            <v-hover v-slot="{ hover }">
                                <v-card
                                    :to="opcao.to"
                                    :elevation="hover ? 5 : 2"
                                    outlined
                                    height="130"
                                    class="pa-2"
                                    style="position: relative"
                                >
                                    <div class="pa-3">
                                        <v-icon color="primary" class="text-h3">{{ opcao.icon }}</v-icon>
                                    </div>
                                    <h3 class="text-subtitle-2">{{ opcao.title }}</h3>
                                </v-card>
                            </v-hover>
                        </v-col>
                    </v-row>
                </v-sheet>
                <v-sheet class="pa-5" outlined rounded v-if="opcoesRelatoriosVisible.length > 0">
                    <h2 class="mb-3">Relatórios</h2>
                    <v-row class="mt-1 text-center">
                        <v-col v-for="(opcao, key) in opcoesRelatoriosVisible" :key="key" cols="6" lg="3" xl="2">
                            <v-hover v-slot="{ hover }">
                                <v-card
                                    :to="opcao.to"
                                    :elevation="hover ? 5 : 2"
                                    outlined
                                    height="130"
                                    class="pa-2"
                                    style="position: relative"
                                >
                                    <div class="pa-3">
                                        <v-icon color="primary" class="text-h3">{{ opcao.icon }}</v-icon>
                                    </div>
                                    <h3 class="text-subtitle-2">{{ opcao.title }}</h3>
                                </v-card>
                            </v-hover>
                        </v-col>
                    </v-row>
                </v-sheet>
            </v-col>
        </v-row>
        <CaixaAviso/>
    </div>
</template>

<script>

import {dataTableMixin} from "../../mixins/dataTableMixin";
import { getMe } from "../../api/usuario";
import CaixaAviso from "../components/Aviso.vue";

export default {
    name: 'home-app',
    components: {CaixaAviso},
    mixins: [dataTableMixin],
    data() {
        return {
            userPermissoes: [],
            opcoesGerenciarVisible: [],
            opcoesFuncionalidadesVisible: [],
            opcoesRelatoriosVisible: [],
            opcoesFuncionalidades: [
                {
                    to: {name: 'questionarios'},
                    icon: 'mdi-archive-outline',
                    title: 'Questionários',
                    permissoes: ['QuestionárioListar'],
                    new: true,
                },
                {
                    to: {name: 'questionario-novo'},
                    icon: 'mdi-file-outline',
                    title: 'Responder Questionário',
                    permissoes: ['QuestionárioCriar'],
                    new: true,
                },
                {
                    to: {name: 'questionarios-avulsos'},
                    icon: 'mdi-archive-plus-outline',
                    title: 'Questionário Avulso',
                    permissoes: ['QuestionárioAvulsoListar'],
                    new: true,
                },
                {
                    to: {name: 'resposta-qualitativa'},
                    icon: 'mdi-book-outline',
                    title: 'Avaliação Qualitativa',
                    permissoes: ['AvaliacaoQualitativaCriar'],
                    new: true,
                },
            ],
            opcoesGerenciar: [
                {
                    to: {name: 'conselhos'},
                    icon: 'mdi-office-building',
                    title: 'Conselhos',
                    permissoes: ['ConselhoListar'],
                    new: true,
                },
                {
                    to: {name: 'orgaos'},
                    icon: 'mdi-domain',
                    title: 'Orgãos',
                    permissoes: ['OrgãoListar'],
                    new: true,
                },
                {
                    to: {name: 'perfis'},
                    icon: 'mdi-account-cog-outline',
                    title: 'Perfis',
                    permissoes: ['PerfilListar'],
                    new: true,
                },
                {
                    to: {name: 'usuarios'},
                    icon: 'mdi-account',
                    title: 'Usuários',
                    permissoes: ['UsuárioListar'],
                    new: true,
                },
                {
                    to: {name: 'configuracoes'},
                    icon: 'mdi-tools',
                    title: 'Configurações',
                    permissoes: ['ConfiguraçãoListar'],
                    new: true,
                },
                {
                    to: {name: 'comunicacoes'},
                    icon: 'mdi-email-outline',
                    title: 'Comunicações',
                    permissoes: ['ComunicaçãoListar'],
                    new: true,
                },
                {
                    to: {name: 'tipoAto'},
                    icon: 'mdi-cog-outline',
                    title: 'Tipos de Ato de Concessão',
                    permissoes: ['TipoAtoListar'],
                    new: true,
                },
                {
                    to: {name: 'situacaoRegime'},
                    icon: 'mdi-cog-outline',
                    title: 'Situação do Regime',
                    permissoes: ['SituaçãoRegimeListar'],
                    new: true,
                },
                {
                    to: {name: 'avisos'},
                    icon: 'mdi-alert-rhombus-outline',
                    title: 'Avisos',
                    permissoes: ['AvisosListar'],
                    new: true,
                },
                {
                    to: {name: 'pergunta-qualitativa'},
                    icon: 'mdi-book-plus-outline',
                    title: 'Pergunta Qualitativa',
                    permissoes: ['PerguntaQualitativaListar'],
                    new: true,
                },
                {
                    to: {name: 'alerta'},
                    icon: 'mdi-alert-plus-outline',
                    title: 'Alerta',
                    permissoes: ['AlertaListar'],
                    new: true,
                },
                {
                    to: {name: 'pergunta-questionario'},
                    icon: 'mdi-clipboard-edit-outline',
                    title: 'Pergunta Questionário',
                    permissoes: ['PerguntaQuestionarioEditar'],
                    new: true,
                },
            ],
            opcoesRelatorios: [
                {
                    to: {name: 'relatorio_questionario'},
                    icon: 'mdi-chart-bar',
                    title: 'Relatório de Questionários',
                    permissoes: ['Relatório Questionário Enviado X PendentesVisualizar'],
                    new: true,
                },
                {
                    to: {name: 'relatorio-questionario-respondido'},
                    icon: 'mdi mdi-tooltip-edit',
                    title: 'Questionários Respondidos',
                    permissoes: ['RelatorioQuestionarioRespondidoVisualizar'],
                    new: true,
                },
                {
                    to: {name: 'relatorio-qualitativo'},
                    icon: 'mdi-table-large',
                    title: 'Relatório Qualitativo',
                    permissoes: ['RelatorioQualitativoVisualizar'],
                    new: true,
                }
            ]
        }
    },
    created() {

        //atualiza opções visíveis do menu por permissão
        this.opcoesGerenciar.forEach(element => {
            if (this.$store.getters['auth/usuarioHasPermissao'](element.permissoes)) {
                if ( element.title == 'Conselhos' ) {
                    if ( this.$store.getters['auth/usuarioHasRole'](['Administrador']) ) {
                        this.opcoesGerenciarVisible.push(element);
                    } else {
                        getMe().then(user_response => {
                            if ( this.$store.getters['auth/usuarioHasRole'](['Analista']) && user_response.data.conselho.id_conselho_pai == null ) {
                                this.opcoesGerenciarVisible.push(element);
                            }
                        });
                    }
                } else {
                    this.opcoesGerenciarVisible.push(element);
                }
            }
        });

        //atualiza opções visíveis do menu por permissão
        this.opcoesFuncionalidades.forEach(element => {
            if (this.$store.getters['auth/usuarioHasPermissao'](element.permissoes)) {
                if ( element.title == 'Avaliação Qualitativa' ) {
                    if ( this.$store.getters['auth/usuarioHasRole'](['Administrador']) ) {
                        this.opcoesFuncionalidadesVisible.push(element);
                    } else {
                        getMe().then(user_response => {
                            if ( this.$store.getters['auth/usuarioHasRole'](['Analista']) && user_response.data.conselho.id_conselho_pai == null ) {
                                this.opcoesFuncionalidadesVisible.push(element);
                            }
                        });
                    }
                } else {
                    this.opcoesFuncionalidadesVisible.push(element);
                }
            }
        });

        this.opcoesRelatorios.forEach(element => {
            if (this.$store.getters['auth/usuarioHasPermissao'](element.permissoes)) {
                if ( element.title == 'Relatório Qualitativo' ) {
                    if ( this.$store.getters['auth/usuarioHasRole'](['Administrador']) ) {
                        this.opcoesRelatoriosVisible.push(element);
                    } else {
                        getMe().then(user_response => {
                            if ( this.$store.getters['auth/usuarioHasRole'](['Analista']) && user_response.data.conselho.id_conselho_pai == null ) {
                                this.opcoesRelatoriosVisible.push(element);
                            }
                        });
                    }
                } else {
                    this.opcoesRelatoriosVisible.push(element);
                }
            }
        });

    },
}
</script>
