<script>

import DeleteAction from "../../components/inputs/DeleteAction.vue";
import {deletarAviso, getAvisosPaginate} from "../../api/aviso";
import {dataTableMixin} from "../../mixins/dataTableMixin";
import {filterNormalizeMixin} from "../../mixins/filterNormalizeMixin";
import ConselhoSelect from "../../components/inputs/ConselhoSelect.vue";
import FilterService from "../../filters/filterService";

export default {
    name: 'CaixaAvisosListar',
    components: {ConselhoSelect, DeleteAction},
    mixins: [dataTableMixin, filterNormalizeMixin],
    data() {
        return {
            headers: [
                // {text: 'ID', key: 'id', value: 'id_aviso', sortable: false, width: '20px'},
                {text: 'Destino', key: 'destino', value: 'conselho.sg_sigla', width: '100px'},
                {text: 'Assunto', key: 'assunto', value: 'nm_assunto', width: '300px'},
                {text: 'Mensagem', key: 'mensagem', value: 'nm_mensagem'},
                {text: 'Data', key: 'created_at', value: 'created_at', width: '150px'},
                {text: 'Ações', value: 'actions', sortable: false, width: '200px', align: 'center'},
            ],
            items: [],
            column_id: 'id_aviso',
            filtro: {
                nm_assunto: null,
                id_conselho: null
            },
        }
    },
    created() {
        getAvisosPaginate(this.pagination.page, this.pagination.itemsPerPage, this.filtro)
            .then(response => {
                this.items = response.data.data.data;
                this.pagination.total = response.data.data.total;
                if (this.pagination.total < this.pagination.itemsPerPage) {
                    this.pagination.page = 1;
                }
            });
    },
    mounted() {
        const savedFilters = FilterService.loadFilters(this.$route.name);
        if (savedFilters) {
            this.filtro = savedFilters;
        }
    },
    beforeRouteLeave(to, from, next) {
        FilterService.saveFilters(from.name, this.filtro);
        next();
    },
    methods: {
        formatDate(dateString) {
            const date = new Date(dateString);
            return date.toLocaleString('pt-BR', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
            });
        },
        filtrar() {
            getAvisosPaginate(this.pagination.page, this.pagination.itemsPerPage, this.filtro)
                .then(response => {
                    this.items = response.data.data.data;
                    this.pagination.total = response.data.data.total;
                    if (this.pagination.total < this.pagination.itemsPerPage) {
                        this.pagination.page = 1;
                    }
                });
        },
        limparFiltro() {
            this.filtro = {
                nm_assunto: null,
                id_conselho: null,
            };

            FilterService.clearFilters(this.$route.name);
            this.filtrar()
        },
        deletar(id) {
            deletarAviso(id).then(() => {
                this.items = this.items.filter(item => item[this.column_id] !== id)
                this.$store.dispatch('alertas/show', {
                    tipo: 'Sucesso',
                    titulo: response.data.success.message,
                });
                this.$vuetify.goTo(0);
            });
        }
    }
}
</script>

<template>
    <div>
        <v-card class="mb-9">
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="6">
                        <ConselhoSelect
                            label="Conselho"
                            v-model="filtro.id_conselho"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            label="Assunto"
                            outlined
                            dense
                            @keyup.enter="filtrar()"
                            v-model="filtro.nm_assunto"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    outlined
                    @click="limparFiltro()"
                >
                    Limpar
                </v-btn>
                <v-btn
                    color="primary"
                    @click="filtrar()"
                >
                    <v-icon left>
                        mdi-filter
                    </v-icon>
                    Filtrar
                </v-btn>
            </v-card-actions>
        </v-card>

        <div class="d-flex my-3">
            <v-spacer></v-spacer>
            <v-btn v-if="$store.getters['auth/usuarioHasPermissao'](['AvisosCriar'])"
                   color="primary"
                   :to="{name: 'avisos-novo'}"
            >
                Novo Aviso
            </v-btn>
        </div>

        <v-data-table
            :headers="headers"
            :items="items"
            class="elevation-1"
            @update:options="filtrar()"
            :items-per-page="pagination.itemsPerPage"
            :footer-props="footerProps"
            :options.sync="pagination"
            :server-items-length="pagination.total"
        >
            <template v-if="$store.getters['auth/usuarioHasPermissao'](['AvisosListar'])"
                      v-slot:item.actions="{ item }">

                <v-btn
                    fab
                    color="cyan"
                    x-small
                    elevation="0"
                    :to="{name: 'avisos-visualizar', params: {aviso: item.id_aviso}}"
                >
                    <v-icon>
                        mdi-magnify
                    </v-icon>
                </v-btn>

                <DeleteAction v-if="$store.getters['auth/usuarioHasPermissao'](['AvisosDeletar'])"
                              @delete="deletar(item[column_id])"/>
            </template>

            <template v-slot:item.created_at="{ item }">
                <span>{{ formatDate(item.created_at) }}</span>
            </template>
        </v-data-table>
    </div>
</template>

