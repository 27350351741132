import api from './index';

const getPergunta = (id_pergunta) => api.get(`pergunta/${id_pergunta}`);

const salvarPergunta = (dados) => api.post('pergunta', dados);

const getPerguntaPaginate = async (page, itemsPerPage, filtrosAplicados = []) => await api.get('pergunta/pergunta-paginate', {
    params: {
        page: page,
        limit: itemsPerPage,
        filtros: filtrosAplicados,
    },
    showLoader: true
});

const retornarPerguntaAjuste = (dados) => api.post('pergunta/retornar-pergunta-ajuste', dados);

export {
    getPergunta,
    salvarPergunta,
    getPerguntaPaginate,
    retornarPerguntaAjuste
}
