<template>
    <v-menu>
        <template v-slot:activator="{ on: dialog, attrs }">
            <v-tooltip top>
                <template v-slot:activator="{ on: tooltip}">
                    <v-btn
                        fab
                        :color="customActionColor"
                        elevation="0"
                        x-small
                        v-bind="attrs"
                        v-on="{ ...tooltip, ...dialog }"
                    >
                        <v-icon>
                            {{ icon }}
                        </v-icon>
                    </v-btn>
                </template>
                <span>{{ messageSpan }}</span>
            </v-tooltip>
        </template>
        <v-card class="v-dialog-card">
            <v-card-subtitle class="text-center font-weight-medium">
                <span>{{ topMessage }}</span>
            </v-card-subtitle>
            <v-card-title class="headline">
                {{ cardTitle }}
            </v-card-title>
            <v-card-text>
                {{ cardTitleAux }}
                <v-divider></v-divider>
                <v-form ref="devForm">
                    <v-row>
                        <v-col cols="12" md="12">
                            <v-textarea
                                v-if="showTextarea"
                                v-model="localTextareaValue"
                                :rules="validate('required', 'Campo obrigatório')"
                                rows="4"
                                outlined
                                dense
                                @click.stop
                            ></v-textarea>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn color="default" @click="$emit('cancel')">
                    Cancelar
                </v-btn>
                <v-btn
                    :color="colorBtn"
                    @click="confirm"
                >
                    Confirmar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-menu>
</template>

<script>
import { validationMixin } from "../../mixins/validationMixin";

export default {
    name: "CustomAction",
    props: {
        topMessage: String,
        cardTitle: String,
        cardTitleAux: String,
        icon: String,
        messageSpan: String,
        colorBtn: String,
        customActionColor: String,
        showTextarea: Boolean,
        textareaValue: String,
        data: {
            type: [String, Number, Object, Array],
            default: null
        }
    },
    mixins: [validationMixin],
    data() {
        return {
            localTextareaValue: this.textareaValue
        };
    },
    methods: {
        confirm() {
            this.$emit('click', this.data, this.localTextareaValue);
        }
    },
    watch: {
        textareaValue(newValue) {
            this.localTextareaValue = newValue;
        }
    }
}
</script>
