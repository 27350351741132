<template>
    <div>
        <v-form ref="form">
            <h1 class="text-h5 my-4">
                Cadastro de Configuração
            </h1>
            <v-sheet class="pa-4  mb-5" rounded outlined>
                <h2 class="subtitle-1 mb-3">Dados do Configuração</h2>
                <v-row>
                    <v-col cols="12" md="8">
                        <v-text-field
                            label="Nome do do Configuração *"
                            outlined
                            dense
                            v-model="form.nm_configuracao"
                            :rules="validate('required', 'nome do configuração')"
                            disabled
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field
                            label="Valor da Configuração *"
                            outlined
                            dense
                            v-model="form.ds_valor"
                            :rules="validate('required', 'valor da configuração')"
                            :disabled="visualizar || form.st_interna"
                        ></v-text-field>
                    </v-col>
                </v-row>

            </v-sheet>
            <div class="d-flex">
                <v-spacer></v-spacer>
                <v-btn
                    class="mx-2"
                    color="error"
                    @click="voltar()"
                >
                    Voltar
                </v-btn>
                
                <v-btn v-if="!visualizar"
                    color="primary"
                    @click="salvar()"
                >
                    Salvar
                </v-btn>
            </div>
        </v-form>
    </div>
</template>

<script>

import { getConfiguracao, updateConfiguracao } from "../../api/configuracao";
import { validationMixin } from "../../mixins/validationMixin";

export default {
    name: "Configuracao",
    components: {  },
    props: {
        configuracao: {
            type: Object,
            default() {
                return {id_configuracao: null}
            }
        },
    },
    mixins: [validationMixin],
    data() {
        return {
            form: {
                nm_configuracao: null,
                ds_valor: null,
                st_interna: true,
            },
            visualizar: null,
            error: {}
        }
    },
    created() {

        this.visualizar = this.$route.name == 'configuracao-visualizar' ? true : false;

        // update
        if (this.$route.params.configuracao) { 
            getConfiguracao(this.$route.params.configuracao).then((response) => {
                this.form.nm_configuracao = response.data.data.nm_configuracao;
                this.form.ds_valor = response.data.data.ds_valor;
                this.form.st_interna = response.data.data.st_interna;
            })
        }
    },
    methods: {
        salvar() {
            if (this.$refs.form.validate()) {
                let promise;
                if (this.$route.params.configuracao) {
                    promise = updateConfiguracao(this.$route.params.configuracao, this.form)
                }

                promise.then(response => {
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: response.data.message,
                    })
                    this.$vuetify.goTo(0)
                    this.$router.push({name: 'configuracoes'})
                })
            }            
        },
        voltar() {           
            this.$router.push({name: 'configuracoes'});
        },
    },
}
</script>

<style>

.vue-js-switch.changed-font {
    font-size: 16px !important;
    margin: 10px;;
}

</style>


