import api from './index';

const getPerfis = () => api.get('perfil/perfis');
const getPerfil = (id_perfil) => api.get(`perfil/${id_perfil}`);
const createPerfil = (dados) => api.post('perfil', dados);
const updatePerfil = (id_perfil, dados) => api.put(`perfil/${id_perfil}`, dados);
const deletePerfil = (id_perfil) => api.delete(`perfil/${id_perfil}`);

const getPerfisPaginate = async (page, itemsPerPage, filtrosAplicados = []) => await api.get('perfil/perfis-paginate', {
    params: {
        page: page,
        limit: itemsPerPage,
        filtros: filtrosAplicados,
    },
    showLoader: true
});

export {    
    getPerfis,
    getPerfisPaginate,
    getPerfil,
    createPerfil,
    updatePerfil,
    deletePerfil
}
