<template>
    <div>
        <v-form ref="form">
            <v-row>
                <v-col cols="12">
                    <v-sheet class="pa-4  mb-5" rounded outlined>
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-textarea
                                    label="Descrição da pergunta *"
                                    outlined
                                    dense
                                    v-model="form.ds_pergunta"
                                    :rules="validate('required|max 250', 'Descrição da pergunta')"
                                ></v-textarea>
                            </v-col>
                        </v-row>

                    </v-sheet>
                </v-col>
            </v-row>
            <div class="d-flex">
                <v-spacer></v-spacer>
                <v-btn
                    class="mx-2"
                    color="error"
                    @click="voltar()"
                >
                    Voltar
                </v-btn>

                <v-btn
                       color="primary"
                       @click="salvar()"
                >
                    Salvar
                </v-btn>
            </div>
        </v-form>
    </div>
</template>

<script>

    import {validationMixin} from "../../mixins/validationMixin";
    import {getPergunta,salvarPergunta} from "../../api/pergunta";

    import moment from 'moment';
    moment.locale('pt-br');

    export default {
        components: {},
        mixins: [validationMixin],
        data() {
            return {
                form: {
                    id_pergunta: null,
                    ds_pergunta: null
                },
                fields: {
                    id_conselho: [],
                },
                error: {},
            }
        },
        methods: {
            voltar() {
                this.$router.push({name: 'pergunta-questionario'});
            },
            salvar() {
                if (this.$refs.form.validate()) {
                    salvarPergunta(this.form).then(response => {
                        this.$store.dispatch('alertas/show', {
                            tipo: 'Sucesso',
                            titulo: response.data.message,
                        })
                        this.$vuetify.goTo(0)
                        this.$router.push({name: 'pergunta-questionario'})
                    });

                }
            }
        },
        created() {
            if (this.$route.params.pergunta) {
                getPergunta(this.$route.params.pergunta).then((response) => {
                    this.form.id_pergunta = response.data.data.id_pergunta;
                    this.form.ds_pergunta = response.data.data.ds_pergunta;
                })
            }
        }
    }
</script>
