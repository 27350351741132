<template>
    <div>
        <v-card class="mb-9">
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="4">
                        <v-select
                            label="Conselho"
                            outlined
                            v-model="filtro.id_conselho"
                            dense
                            clearable
                            :items="conselhosSelect"
                            item-value="id_conselho"
                            item-text="nm_conselho"
                            hide-details="false"
                        />
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field
                            type="number"
                            label="Número do processo SEI"
                            outlined
                            dense
                            v-model="filtro.nr_sei"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field                            
                            label="Data do Julgamento"
                            outlined
                            type="date"
                            dense
                            v-model="filtro.dt_julgamento"    
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <OpcaoRelatorioQualitativo
                            @opcaoRelatorioQualitativaEmit="opcaoRelatorioQualitativaEmit"
                            :opcaoItens="this.opcoes"                                        
                        ></OpcaoRelatorioQualitativo>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="success"
                    @click="exportarXls()"
                >
                    <v-icon left>
                        mdi-file-excel
                    </v-icon>
                    Excel
                </v-btn>
                <v-btn
                    outlined
                    @click="limparFiltro()"
                >
                    Limpar
                </v-btn>
                <v-btn
                    color="primary"
                    @click="filtrar()"
                >
                    <v-icon left>
                        mdi-filter
                    </v-icon>
                    Filtrar
                </v-btn>
            </v-card-actions>
        </v-card>

        <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page="pagination.itemsPerPage"
            :footer-props="footerProps"
            :options.sync="pagination"
            @update:options="filtrar()"
            :server-items-length="pagination.total"
            class="elevation-1"
        >

            <template v-slot:item.dt_mes_ano="{ item }">
                <span>{{ dateFormatMesAno(item.dt_mes_ano) }}</span>
            </template>
            <template v-slot:item.vl_acrescimo="{ item }">
                <v-currency-field v-if="item.vl_acrescimo"
                    :disabled="true"
                    flat
                    solo
                    locale="pt-BR"
                    prefix="R$ "
                    v-model="item.vl_acrescimo"
                    hide-details="false"
                />
            </template>
        </v-data-table>
        <br />
        <v-row>
            <v-col cols="12" md="6"></v-col>
            <v-col cols="12" md="6"><h3>Soma dos Valores dos Impactos Globais: {{ this.valorTotal }}</h3></v-col>
        </v-row>
    </div>
</template>

<script>
import { getConselhos } from "../../api/conselho";
import { getRelatorioQualitativoPaginate, getValorTotalRelatorioQualitativo, extracaoRelatorioQualitativo } from "../../api/relatorio_qualitativo";
import OpcaoRelatorioQualitativo from "../../components/components/OpcaoRelatorioQualitativo.vue";
import _ from "lodash";
import { validationMixin } from "../../mixins/validationMixin";
import { dataTableMixin } from "../../mixins/dataTableMixin";
import moment from 'moment';

export default {
    name: "RelatorioQualitativo",
    components: {OpcaoRelatorioQualitativo},
    mixins: [validationMixin,dataTableMixin],
    data() {
        return {
            conselhosSelect: [],
            opcoes: [],
            filtro: {
                id_conselho: null,
                dt_julgamento: null,
                nr_sei: null,
                opcoes: []
            },
            valorTotal: null,
            loading: true,
            column_id: 'id_pergunta',
            headers: [
                { text: 'Nº SEI', value: 'nr_sei', sortable: false, width: '180px' },
                { text: 'Conselho', value: 'nm_conselho', sortable: false },
                { text: 'Órgão', value: 'nm_orgao', sortable: false },
                { text: 'Mês e Ano', value: 'dt_mes_ano', sortable: false },
                { text: 'Inciso', value: 'ds_pergunta', sortable: false },
                { text: 'Impacto Global', value: 'vl_acrescimo', sortable: false, width: '180px' },
            ]
        }
    },
    created() {
        getConselhos().then((conselho_response) => {
            this.conselhosSelect = conselho_response.data.data;
        });
    },
    methods: {
        opcaoRelatorioQualitativaEmit(opcoes) {
            if (opcoes.length == 0) {
                // this.anexosEmpty = true; // postergado
            } else {
                this.opcoesEmpty = false;
                this.opcoes = opcoes;
            }
        },

        limparFiltro() {
            this.filtro = {
                id_conselho: null,
                dt_julgamento: null,
                id_pergunta_qualitativa: null,
                id_opcao_resposta_pergunta_qualitativa: null,
            };
            this.opcaoRespostaSelect = [];
            this.filtrar()
        },
        filtrar() {
            this.filtro.opcoes = this.opcoes;

            getRelatorioQualitativoPaginate(this.pagination.page, this.pagination.itemsPerPage, this.filtro)
                .then(response => {
                    this.items = response.data.data.data;
                    this.pagination.total = response.data.data.total;
                    if (this.pagination.total < this.pagination.itemsPerPage) {
                        this.pagination.page = 1;
                    }
                }).catch(error => this.handleError(error));

            getValorTotalRelatorioQualitativo(this.filtro).then(response => {
                this.valorTotal = Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(response.data.data);
            });
        },

        dateFormatMesAno(date) {
            if (!!date) {
                return moment(date.toString()).format('MMMM/YYYY');
            }
            return null;
        },

        exportarXls() {
            extracaoRelatorioQualitativo(this.filtro).then(response => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'Relatório qualitativo.xlsx');
                document.body.appendChild(fileLink);
                fileLink.click();
            });
        }
    }
}
</script>
