import api from './index';

const downloadAnexo = (id_anexo) => api.get('anexo/' + id_anexo + '/download', {responseType: 'blob'})
const getListAnexo = (dados) => api.post(`anexo/listByEntity`, dados)
const uploadAnexo = (dados) => api.post(`anexo/upload`, dados, {
    headers: {
        "Content-Type": "multipart/form-data",
    },
    maxContentLength: 11000,
    maxBodyLength: 11000,
});

const deleteAnexo = (id_anexo) => api.delete(`anexo/${id_anexo}`);

export {
    downloadAnexo,
    uploadAnexo,
    deleteAnexo,
    getListAnexo
}
