<template>
    <div>
        <v-form ref="form">
            <h1 class="text-h5 my-4">
                Cadastro de pergunta qualitativa
            </h1>
            <v-sheet class="pa-4  mb-5" rounded outlined>
                <h2 class="subtitle-1 mb-3">Dados da pergunta qualitativa</h2>
                <v-row>
                    <v-col cols="12" md="12">
                        <v-textarea
                            label="Descrição pergunta qualitativa *"
                            outlined
                            dense
                            v-model="form.ds_pergunta_qualitativa"
                            :rules="validate('required', 'Descrição pergunta qualitativa')"
                            :disabled="visualizar"
                        ></v-textarea>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <OpcaoRespostaQualitativa
                            @opcaoRespostaQualitativaEmit="opcaoRespostaQualitativaEmit"
                            :opcaoItens="this.opcoes"                                        
                        ></OpcaoRespostaQualitativa>
                    </v-col>
                </v-row>
            </v-sheet>
            <div class="d-flex">
                <v-spacer></v-spacer>
                <v-btn
                    class="mx-2"
                    color="error"
                    @click="voltar()"
                >
                    Voltar
                </v-btn>
                
                <v-btn v-if="!visualizar"
                    color="primary"
                    @click="salvar()"
                >
                    Salvar
                </v-btn>
            </div>

            <v-dialog v-model="resposta_dialog" persistent class="v-dialog">
                <v-card>
                    <v-card-title class="headline">
                        Aviso!
                    </v-card-title>
                    <v-card-text>
                        <h3>
                            Ao menos duas opções de resposta devem ser preenchidas.
                        </h3>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="error"
                            @click="resposta_dialog = false">
                            Fechar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-form>
    </div>
</template>

<script>

import { getPerguntaQualitativa, createPerguntaQualitativa, updatePerguntaQualitativa } from "../../api/pergunta_qualitativa";
import { validationMixin } from "../../mixins/validationMixin";
import OpcaoRespostaQualitativa from "../../components/components/OpcaoRespostaQualitativa.vue";

export default {
    name: "PerguntaQualitativa",
    components: {OpcaoRespostaQualitativa},
    props: {
        perguntaQualitativa: {
            type: Object,
            default() {
                return {id_pergunta_qualitativa: null}
            }
        },
    },
    mixins: [validationMixin],
    data() {
        return {
            form: {
                ds_pergunta_qualitativa: null
            },
            error: {},
            permissoes: [],
            opcoes: [],
            visualizar: false,
            resposta_dialog: false
        }
    },
    created() {
        if (this.$route.params.perguntaQualitativa) { 
            getPerguntaQualitativa(this.$route.params.perguntaQualitativa).then((response) => {
                this.form.ds_pergunta_qualitativa = response.data.data.ds_pergunta_qualitativa;
            })
        }
    },
    methods: {
        salvar() {
            if (this.$refs.form.validate()) {
                if ( this.opcoes.length <= 1 ) {
                    this.resposta_dialog = true;
                } else {
                    let promise;
                    this.form.opcoes = this.opcoes;

                    if (this.$route.params.perguntaQualitativa) {
                        promise = updatePerguntaQualitativa(this.$route.params.perguntaQualitativa, this.form)
                    } else {
                        promise = createPerguntaQualitativa(this.form)
                    }

                    promise.then(response => {
                        this.$store.dispatch('alertas/show', {
                            tipo: 'Sucesso',
                            titulo: response.data.message,
                        })
                        this.$vuetify.goTo(0)
                        this.$router.push({name: 'pergunta-qualitativa'})
                    })
                }
            }            
        },
        voltar() {           
            this.$router.push({name: 'pergunta-qualitativa'});
        },

        opcaoRespostaQualitativaEmit(opcoes) {
            if (opcoes.length == 0) {
                // this.anexosEmpty = true; // postergado
            } else {
                this.opcoesEmpty = false;
                this.opcoes = opcoes;
            }
        },
    },
}
</script>