import api from './index';

const getQuestionarioAvulsAnexoDownload = (id_questionario_avulso_anexo) => api.get('questionario-avulso-anexo/' + id_questionario_avulso_anexo + '/download', {responseType: 'blob'})

const uploadQuestionarioAvulsoAnexo = (id_questionario_avulso, dados) => api.post(`questionario-avulso-anexo/upload/${id_questionario_avulso}`, dados, {
    headers: {
        "Content-Type": "multipart/form-data",
    },
    maxContentLength: 11000,
    maxBodyLength: 11000,
});

const deleteQuestionarioAvulsoAnexo = (id_questionario_avulso_anexo) => api.delete(`questionario-avulso-anexo/${id_questionario_avulso_anexo}`);

export {    
    getQuestionarioAvulsAnexoDownload,
    uploadQuestionarioAvulsoAnexo,
    deleteQuestionarioAvulsoAnexo,
}
