<template>
    <div>
        <v-form ref="form">
            <h1 class="text-h5 my-4">
                Avaliação qualitativa
            </h1>
            <v-row>
                <v-col cols="12" md="6">
                    <v-text-field
                        type="number"
                        label="Número do processo SEI *"
                        outlined
                        dense
                        v-model="form.nr_sei"
                        :rules="validate('required', 'Número do processo SEI')"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-btn
                        color="primary"
                        @click="buscarResposta()"
                    >
                        Buscar
                    </v-btn>
                </v-col>
            </v-row>

            <v-alert
                v-if="semResposta"
                border="top"
                colored-border
                type="warning"
                elevation="2"
            >
                <h3>Nenhuma resposta encontrada para o número do SEI informado.</h3>
            </v-alert>
            
            <div v-if="dadosReposta">
                <h1 class="subtitle-1 mb-3">Perguntas com o SEI cadastrado</h1>
                <v-sheet class="pa-4  mb-5" rounded outlined v-for="(resposta, keyR) in respostas" :key="'R'+keyR">
                    <table>
                        <tr>
                            <td><b>Conselho:</b></td>
                        </tr>
                        <tr>
                            <td>{{ resposta.questionario.orgao.conselho.nm_conselho }}</td>
                        </tr>
                    </table>
                    <table>
                        <tr>
                            <td><b>Órgão:</b></td>
                            <td width="20%"><b>Mês e Ano:</b></td>
                        </tr>
                        <tr>
                            <td>{{ resposta.questionario.orgao.nm_orgao }}</td>
                            <td width="20%">{{ dateFormatMesAno(resposta.questionario.dt_mes_ano) }}</td>
                        </tr>
                    </table>
                    <table>
                        <tr>
                            <td><b>Pergunta:</b></td>
                        </tr>
                        <tr>
                            <td>{{ resposta.pergunta.ds_pergunta }}</td>
                        </tr>
                    </table>
                    <br >
                    <div class="d-flex" v-if="resposta.acrescimos_orcamentarios.length <= 1">
                        <v-spacer></v-spacer>
                        <v-btn
                            color="success"
                            @click="openValorOrcamento(resposta.id_resposta)"
                        >
                            Valor orçamento 
                        </v-btn>
                    </div>
                </v-sheet>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-text-field                            
                            label="Data do Julgamento *"
                            outlined
                            type="date"
                            dense
                            v-model="form.dt_julgamento"    
                            :rules="validate('required', 'Data do Julgamento')"  
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-container fluid 
                    v-for="(pergunta, keyP) in perguntas" :key="'P' + keyP">
                    <div>{{ pergunta.ds_pergunta_qualitativa }}</div>
                    <v-radio-group 
                        v-model="form.opcao_resposta[pergunta.id_pergunta_qualitativa]"
                        :rules="validate('required', pergunta.ds_pergunta_qualitativa)">

                        <v-radio
                            v-for="(op, keyO) in pergunta.opcao" :key="'O'+keyO"
                            :label="op.ds_opcao_resposta"
                            :value="op.id_opcao_resposta_pergunta_qualitativa"
                        ></v-radio>
                    </v-radio-group>
                </v-container>
                <div class="d-flex" v-if="dadosReposta">
                    <v-spacer></v-spacer>
                    <v-btn
                        class="mx-2"
                        color="error"
                        @click="limpar()"
                    >
                        Cancelar
                    </v-btn>
                    
                    <v-btn
                        color="primary"
                        @click="salvar()"
                    >
                        Salvar
                    </v-btn>
                </div>
            </div>
        </v-form>
        <v-dialog v-model="orcamento_dialog" persistent class="v-dialog">
            <v-form ref="formOrc">
                <v-sheet class="pa-4  mb-5" rounded outlined>
                    <input type="hidden" name="id_acrescimo_orcamentario" class="form-control" :value="formOrc.id_acrescimo_orcamentario">
                    <input type="hidden" name="id_resposta" class="form-control" :value="formOrc.id_resposta">
                    <v-card class="v-dialog-card">
                        <v-card-title class="headline">
                            Valor do orçamento
                        </v-card-title>
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-currency-field
                                    outlined
                                    dense
                                    locale="pt-BR"
                                    prefix="R$ "
                                    v-model="formOrc.vl_acrescimo"
                                    :rules="validate('required', 'R$ ')" 
                                />
                            </v-col>
                        </v-row>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="error"
                                @click="closeValorOrcamento()">
                                Cancelar
                            </v-btn>
                            <v-btn
                                color="success"
                                @click="salvarValorOrcamento()"
                            >
                                Salvar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-sheet>
            </v-form>
        </v-dialog>
    </div>
</template>

<script>

import { getRespostaByNrSei, salvarAvaliacaoQualitativa, getAvaliacaoRespondidaByNrSei, salvarValorOrcamento, getValorOrcamento } from "../../api/resposta_qualitativa";
import { getAllPerguntaQualitativa } from "../../api/pergunta_qualitativa";
import { validationMixin } from "../../mixins/validationMixin";

import moment from 'moment';
moment.locale('pt-br');

export default {
    name: "AvaliacaoQualitativa",
    components: {},
    props: { },
    mixins: [validationMixin],
    data() {
        return {
            form: {
                nr_sei: null,
                dt_julgamento: null,
                opcao_resposta: []
            },
            formOrc: {
                id_resposta: null,
                vl_acrescimo: null,
            },
            error: {},
            permissoes: [],
            respostas: [],
            dadosReposta: false,
            semResposta: false,
            perguntas: [],
            orcamento_dialog: false
        }
    },
    created() {
        if (this.$route.params.perguntaQualitativa) { 
            getPerguntaQualitativa(this.$route.params.perguntaQualitativa).then((response) => {
                this.form.ds_pergunta_qualitativa = response.data.data.ds_pergunta_qualitativa;
            })
        }
    },
    methods: {
        buscarResposta() {
            this.limparNotNrSei();

            if (this.$refs.form.validate()) {
                getRespostaByNrSei(this.form.nr_sei).then((response) => {
                    if ( response.data.data.length > 0 ) {
                        this.respostas = response.data.data;

                        this.dadosReposta = true;
                        this.semResposta = false;

                        getAllPerguntaQualitativa().then((response) => {
                            this.perguntas = response.data.data;

                            getAvaliacaoRespondidaByNrSei(this.form.nr_sei).then((response) => {
                                this.form.dt_julgamento = response.data.data[0].dt_julgamento;

                                this.perguntas.forEach(perg => {
                                    perg.opcao.forEach(op => {
                                        response.data.data.forEach(check => {
                                            if ( check.id_opcao_resposta_pergunta_qualitativa == op.id_opcao_resposta_pergunta_qualitativa ) {
                                                this.form.opcao_resposta[perg.id_pergunta_qualitativa] = check.id_opcao_resposta_pergunta_qualitativa;
                                            }
                                        });
                                    });
                                });
                            })
                        })
                    } else {
                        this.limparNotNrSei();
                        this.semResposta = true;
                    }
                })
            }
        },

        dateFormatMesAno(date) {
            if (!!date) {
                return moment(date.toString()).format('MMMM/YYYY');
            }
            return null;
        },

        salvar() {
            if (this.$refs.form.validate()) {
                let promise;
                this.opcoes = this.form.opcao_resposta;

                if (this.$route.params.perguntaQualitativa) {
                    promise = updatePerguntaQualitativa(this.$route.params.perguntaQualitativa, this.form)
                } else {
                    promise = salvarAvaliacaoQualitativa(this.form)
                }

                promise.then(response => {
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: response.data.message,
                    })
                    this.limpar();
                    this.$vuetify.goTo(0);
                    this.$router.push({name: 'resposta-qualitativa'})
                })
            }            
        },
        
        limpar() {  
            this.dadosReposta = false;
            this.form.nr_sei = null;
            this.form.dt_julgamento = null;
            this.form.opcao_resposta = [];
        },
        limparNotNrSei() {
            this.dadosReposta = false;
            this.form.dt_julgamento = null;
            this.form.opcao_resposta = [];
        },

        openValorOrcamento(idResposta) {
            this.formOrc.vl_acrescimo = null;
            this.formOrc.id_acrescimo_orcamentario = null;
            this.formOrc.id_resposta = idResposta;

            getValorOrcamento(idResposta).then((response) => {
                this.formOrc.id_acrescimo_orcamentario = response.data.data.id_acrescimo_orcamentario;
                this.formOrc.vl_acrescimo = response.data.data.vl_acrescimo;
            })

            this.orcamento_dialog = true;
        },
        salvarValorOrcamento() {
            if (this.$refs.formOrc.validate()) {
                salvarValorOrcamento(this.formOrc).then(response => {
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: response.data.message,
                    })
                    this.orcamento_dialog = false;
                    this.$vuetify.goTo(0);
                    this.buscarResposta();
                })
            }
        },
        closeValorOrcamento() {
            this.formOrc.id_resposta = null;
            this.formOrc.vl_acrescimo = null;
            this.orcamento_dialog = false;
        }

    },
}
</script>