<template>
    <div>
        <v-form ref="form">
            <h1 class="text-h5 my-4">
                Cadastro de Perfil
            </h1>
            <v-sheet class="pa-4  mb-5" rounded outlined>
                <h2 class="subtitle-1 mb-3">Dados do Perfil</h2>
                <v-row>
                    <v-col cols="12" md="10">
                        <v-text-field
                            label="Nome do Perfil *"
                            outlined
                            dense
                            v-model="form.nm_perfil"
                            :rules="validate('required', 'Nome do Perfil')"
                            :disabled="visualizar"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-select
                            label="Status *"
                            outlined
                            dense                            
                            v-model="form.st_status"
                            :items="[
                                {value: true, text: 'Ativo'},
                                {value: false, text: 'Inativo'},
                            ]"                            
                            :rules="validate('required', 'status')"
                            :disabled="visualizar"
                        ></v-select>
                    </v-col>
                </v-row>
            </v-sheet>
            <v-sheet class="pa-4  mb-5" rounded outlined>
                <h2 class="subtitle-1 mb-3">Permissões</h2>
                <v-data-table
                    disable-pagination
                    :hide-default-footer="true"
                    :headers="headers"
                    :items="permissoes"
                    class="elevation-1"
                >
                
                <template v-slot:item.tem_permissao="{ item }">
                    <v-checkbox
                        v-model="item.tem_permissao"   
                        :disabled="visualizar"                     
                    />
                </template>
                   
                </v-data-table>
            </v-sheet>
            <div class="d-flex">
                <v-spacer></v-spacer>
                <v-btn
                    class="mx-2"
                    color="error"
                    @click="voltar()"
                >
                    Voltar
                </v-btn>
                
                <v-btn v-if="!visualizar"
                    color="primary"
                    @click="salvar()"
                >
                    Salvar
                </v-btn>
            </div>
        </v-form>
    </div>
</template>

<script>

import { getPerfil, createPerfil, updatePerfil } from "../../api/perfil";
import { getPermissoes } from "../../api/permissao";
import { validationMixin } from "../../mixins/validationMixin";

export default {
    name: "Perfil",
    components: {},
    props: {
        perfil: {
            type: Object,
            default() {
                return {id_perfil: null}
            }
        },
    },
    mixins: [validationMixin],
    data() {
        return {
            form: {
                nm_perfil: null,
                st_status: true,
                permissoes: []
            },
            visualizar: null,
            error: {},
            permissoes: [],
            headers: [
                { text: 'Funcionalidade', value: 'nm_funcionalidade', sortable: false },
                { text: 'Permissão', value: 'nm_permissao', sortable: false },
                { text: 'Status', value: 'tem_permissao', sortable: false },
            ]
        }
    },
    created() {

        this.visualizar = this.$route.name == 'perfil-visualizar' ? true : false;

        // update
        if (this.$route.params.perfil) { 

            getPerfil(this.$route.params.perfil).then((response) => {
                let userPermissions = response.data.data.permissoes;

                getPermissoes().then((permissoesResponse) => {
                    this.permissoes = permissoesResponse.data.data;
                    this.permissoes.forEach(element => {

                        if (userPermissions.some(e => e.id_permissao === element.id_permissao)) {
                            element.tem_permissao = true;
                        } else {
                            element.tem_permissao = false;
                        }
                    });
                });
                
                this.form.nm_perfil = response.data.data.nm_perfil;
                this.form.st_status = response.data.data.st_status;
            })
        }
    },
    methods: {
        salvar() {
            if (this.$refs.form.validate()) {

                this.permissoes.forEach(element => {
                    if (element.tem_permissao) {
                        this.form.permissoes.push(element.id_permissao);
                    }
                });

                let promise;
                if (this.$route.params.perfil) {
                    promise = updatePerfil(this.$route.params.perfil, this.form)
                } else {
                    promise = createPerfil(this.form)
                }

                promise.then(response => {
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: response.data.message,
                    })
                    this.$vuetify.goTo(0)
                    this.$router.push({name: 'perfis'})
                })
            }            
        },
        voltar() {           
            this.$router.push({name: 'perfis'});
        }
    },
}
</script>