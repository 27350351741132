import api from './index';

const getRespostaByNrSei = (nrSei) => api.put(`resposta-qualitativa/respostaByNrSei/${nrSei}`);
const salvarAvaliacaoQualitativa = (dados) => api.post('resposta-qualitativa', dados);
const getAvaliacaoRespondidaByNrSei = (nrSei) => api.put(`resposta-qualitativa/avaliacaoByNrSei/${nrSei}`);
const salvarValorOrcamento = (dados) => api.post('resposta-qualitativa/salvarValorOrcamento', dados);
const getValorOrcamento = (idResposta) => api.put(`resposta-qualitativa/getValorOrcamento/${idResposta}`);
const getOpcaoRespostaByPergunta = (idPergunta) => api.put(`resposta-qualitativa/getOpcaoRespostaByPergunta/${idPergunta}`);

export {
    getRespostaByNrSei,
    salvarAvaliacaoQualitativa,
    getAvaliacaoRespondidaByNrSei,
    salvarValorOrcamento,
    getValorOrcamento,
    getOpcaoRespostaByPergunta
}
