<template>
    <v-autocomplete
        :label="label"
        outlined
        :value="value"
        @input="$emit('input', $event)"
        :rules="rules"
        :error-messages="error"
        :items="orgaos"
        :filter="filterNormalize"
        item-value="id_orgao"
        item-text="nm_orgao"
        multiple
        chips
        :disabled="disabled"        
        @change="(event) => changeOrgao(event)"
    >
        <template v-slot:selection="{ attrs, item, parent, selected }">
            <v-chip
                :disabled="disabled"
                v-if="item === Object(item)"
                v-bind="attrs"
                :input-value="selected"
            >
                <v-icon
                    small
                    @click="parent.selectItem(item)"
                >
                    $delete
                </v-icon>
                <span class="pl-2">{{ item.nm_orgao }}</span>
            </v-chip>
        </template>
    </v-autocomplete>
</template>

<script>
import { filterNormalizeMixin } from "../../mixins/filterNormalizeMixin";
import { getOrgaosByConselho } from "../../api/orgao";

export default {
    name: "OrgaoAutocomplete",
    mixins: [ filterNormalizeMixin ],
    props: {
        idConselho: {
            type: Number,
            default: 0
        },
        label: {
            type: String,
            default: 'Orgaos'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        value: {
            type: Array,
        },
        rules: {
            type: Array,
            default: () => []
        },
        error: {
            type: String|Array,
            default: () => []
        }
    },
    data() {
        return {
            orgaos: []
        }
    },
    created() {
        getOrgaosByConselho(this.idConselho).then((response) => {

            this.orgaos = response.data.data;

            // se for Analista e não for Administrador desabilita a opção de Administrador
            if (this.$store.getters['auth/usuarioHasRole'](['Analista']) && !this.$store.getters['auth/usuarioHasRole'](['Administrador'])) {
                this.orgaos.forEach(element => {
                    if (element.nm_orgao === 'Administrador') {
                        element.disabled = true;
                    }
                });                
            }

        })
    },
    methods: {
        changeOrgao(event) {
            this.$emit("changeOrgaosEvent", event);
        }
    }

}
</script>
