<template>
    <div>

        <v-form ref="form">           
            <modal
                width="656"
                height="200"
                :clickToClose="false"
                adaptive
                transition="pop-out"
                name="orgaoSelectModel"
                :shiftY="0.3"
                >
                <div class="box">
                    <div class="partition" id="partition-register">
                        <div class="partition-title">SELEÇÃO DE ORGÃO</div>
                        <div class="partition-subtitle">Escolha o Orgão que deseja utilizar para esta sessão de trabalho</div>
                        <v-row>
                            <v-col cols="12" md="10">
                                <v-select
                                    label="Orgão"
                                    outlined                            
                                    v-model="form.id_orgao"
                                    dense                            
                                    clearable                                                     
                                    :items="orgaosSelect"
                                    item-value="id_orgao"
                                    item-text="nm_orgao"                                    
                                    :rules="validate('required', 'orgão')"
                                />
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-btn
                                    color="primary"
                                    @click="salvar()"
                                >
                                    Salvar
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </modal>
        </v-form>
        <v-app-bar color="white" height="100" max-height="100" class="px-md-10">
            <v-app-bar-nav-icon v-if="loggedIn" @click.stop="toggleSideBar" id="btnMenu"
                                color="primary"></v-app-bar-nav-icon>
            <v-toolbar-title>
                {{ ENV_MIX_APP_NAME }}
                <div v-if="ENV_MIX_APP_DESCRIPTION !== null"
                     class="subtitle-2 grey--text lighten-1--text font-weight-regular">
                    {{ ENV_MIX_APP_DESCRIPTION }}
                </div>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <div>            
                <v-menu v-if="loggedIn" v-model="showPerfis" :close-on-content-click="false" :nudge-width="200" offset-x>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn text rounded v-bind="attrs" v-on="on" class="grey--text">
                            <v-icon dark left color="primary">mdi-account</v-icon>
                            {{ usuario.nm_usuario }}                            
                            <v-icon dark right color="black">mdi-chevron-down</v-icon>
                            <v-divider></v-divider>
                        </v-btn>
                    </template>
                    <v-card>
                        <v-list>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-icon dark left color="primary">mdi-account</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>{{ usuario.nm_usuario }}</v-list-item-title>
                                    <v-list-item-subtitle>
                                        Perfis:
                                        <span v-for="item in perfis" :key="item">
                                            ( {{ item }} )
                                        </span>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="orgaoFunctionsVisible && orgao != null">
                                <v-list-item-avatar>
                                    <v-icon dark left color="primary">mdi-domain</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>Orgão utilizado na Sessão</v-list-item-title>
                                    <v-list-item-subtitle>
                                        <span>
                                            <strong>
                                                {{ orgao.nm_orgao }}
                                            </strong>
                                        </span>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn v-if="orgaoFunctionsVisible" color="primary" text rounded @click="changeOrgao()">
                                Trocar Orgão
                            </v-btn>                        
                            <v-btn color="primary" text rounded @click="showPerfis = false">
                                Fechar
                            </v-btn>                        
                        </v-card-actions>
                    </v-card>
                </v-menu>
                <v-btn @click="logout" color="primary" rounded>
                    Sair
                </v-btn>
            </div>
    
           
        </v-app-bar>
    </div>
</template>

<script>

import {getMe} from "../../api/usuario";
import {deleteNotificacao, deleteNotificacoes, getNotificacoes, markAsReadNotificacao} from "../../api/notificacoes";
import moment from 'moment';
import { getOrgaosByUser } from "../../api/orgao";
import { updateUsuarioOrgao } from "../../api/usuario";
import { validationMixin } from "../../mixins/validationMixin";

import store from '../../store'

moment.locale('pt-br');

export default {
    name: 'app-header',
    components: {},
    mixins: [ validationMixin ],
    props: {
        loggedIn: Boolean
    },
    data() {
        return {
            form: {
                id_orgao: null,
            },
            orgaosSelect: [],
            ENV_MIX_APP_NAME: process.env.MIX_APP_NAME,
            ENV_MIX_APP_DESCRIPTION: process.env.MIX_APP_DESCRIPTION,
            interval: null,
            showPerfis: false,
            showNotificacoes: false,
            dialogMeusDados: false,
            confirmarExclusao: false,
            perfis: [],
            usuario: {},
            orgao: {},
            notificacoes: [],
            orgaoFunctionsVisible: false,
        };
    },
    created() {
        this.getPerfis();
        this.getDadosUsuario()
        this.setNotificacoes()
        this.interval = setInterval(() => {
            this.setNotificacoes()
        }, 2000000)
        
        if (this.$store.getters['auth/usuarioHasRole'](['Cadastrador', 'Consultor'])) {            
            getOrgaosByUser().then((orgaosResponse) => {                        
                this.orgaosSelect = orgaosResponse.data.data;
                this.orgaoFunctionsVisible = true;
            })
        }

    },
    mounted () {
        if (this.$store.getters['auth/usuarioHasRole'](['Cadastrador', 'Consultor']) && localStorage.getItem("setOrgao") == "false") {
            this.$modal.show('orgaoSelectModel');
        }
    },
    destroyed() {
        clearInterval(this.interval)
    },
    computed: {
        completarDados() {
            return (!this.usuario.nm_usuario || !this.usuario.nr_cpf || !this.usuario.nr_telefone ||
                !this.usuario.ds_email_institucional)
        },
        totalNotificacoes() {
            return this.notificacoes.filter(notificacao => notificacao.read_at == null).length ?? 0
        }
    },
    methods: {
        salvar() {
            if (this.$refs.form.validate()) {

                localStorage.setItem('setOrgao', true);
                
                let promise = updateUsuarioOrgao(this.form.id_orgao)

                promise.then(response => {
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: response.data.message,
                    })
                    this.orgao = response.data.data.orgao;
                    this.$modal.hide('orgaoSelectModel');

                    if (this.$route.name != 'home') {
                        this.$router.push({name: 'home'})
                    } else {
                        window.location.reload();
                        store.dispatch('loader/pending');
                    }

                })
                .catch(error => this.handleError(error))
            }            
        },
        logout() {

            this.$store.dispatch('auth/logout').then(
                () => {                    
                    
                    let url = '';
            let params = '';
            url = process.env.MIX_GOVBR_URL_LOGOUT;
            console.log(url);
            params = 'post_logout_redirect_uri=' + location.origin + '/logout';
            console.log(params);

            window.open(`${url}?${params}`, '_self');
            
            
                },
                error => {
                    this.message =
                        (error.response && error.response.data) ||
                        error.message ||
                        error.toString();
                }
            );       
            
        },
        toggleSideBar() {
            this.$store.commit('menu/toggleSideBar');
        },
        async getDadosUsuario() {
            if (this.loggedIn) {
                await getMe().then(response => {
                    this.usuario = {...response.data} 
                    this.orgao = this.usuario.orgao;
                });
            }
        },
        getPerfis() {
            this.perfis = this.$store.getters['auth/usuarioAutenticadoRoles'] ? this.$store.getters['auth/usuarioAutenticadoRoles'] : [];
        },

        dateFromNow(date) {
            return moment(date).fromNow()
        },

        setNotificacoes() {
            getNotificacoes().then(response => {
                this.notificacoes = response.data.data
            })
        },

        markAsReadAndRedirect(id, link) {
            markAsReadNotificacao(id).then(response => {
                this.notificacoes.find(notificacao => notificacao.id == id).read_at = new Date()
                this.showNotificacoes = false
                this.$router.push(link)
            })
        },

        deletarNotificacao(id) {
            deleteNotificacao(id).then(response => {
                this.notificacoes = this.notificacoes.filter(notificacao => notificacao.id != id)
            })
        },

        deletarNoficiacoes() {
            this.confirmarExclusao = false
            deleteNotificacoes().then(response => {
                this.notificacoes = []
                this.showNotificacoes = false
            })
        },

        changeOrgao() {
            this.$modal.show('orgaoSelectModel');
        }
    }
}
</script>

<style>
header {
    z-index: 100 !important;
}
.border-notification {
    border-left-width: 4px;
    border-left-style: solid;
}
.border-notification-read {
    border-left-color: rgb(19, 81, 180, 0.2);
}
.border-notification-unread {
    border-left-color: rgb(19, 81, 180);
}
.border-notification:hover {
    border-left-width: 8px;
}

.box {
    background: white;
    overflow: hidden;
    padding: 30px;
    height: 200px;
    border-radius: 2px;
    border-width: 50px;
    border-color: #8b8c8d;
    box-sizing: border-box;
    box-shadow: 0 0 40px black;
    color: #8b8c8d;
    font-size: 0;
}

.partition {
    width: 100%;
    height: 100%;

    .partition-title {
        box-sizing: border-box;
        padding: 0px 0px 0px 0px;
        width: 100%;
        text-align: center;        
        font-size: 20px;
        font-weight: 300;
    }

    .partition-subtitle {
        box-sizing: border-box;
        padding: 0px 0px 30px 0px;
        width: 100%;
        text-align: center;
        letter-spacing: 1px;
        font-size: 14px;
        font-weight: bold;
    }

    .partition-form {
        padding: 0 20px;
        box-sizing: border-box;
    }
}
  
.pop-out-enter-active,
.pop-out-leave-active {
    transition: all 0.5s;
}

.pop-out-enter,
.pop-out-leave-active {
    opacity: 0;
    transform: translateY(24px);
}
</style>
