<template>
    <div>
        <v-card class="mb-9">
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="12">
                        <v-text-field
                            label="Nº SEI"
                            outlined
                            dense
                            @keyup.enter="filtrar()"
                            v-model="filtro.nr_sei"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    outlined
                    @click="limparFiltro()"
                >
                    Limpar
                </v-btn>
                <v-btn
                    color="primary"
                    @click="filtrar()"
                >
                    <v-icon left>
                        mdi-filter
                    </v-icon>
                    Filtrar
                </v-btn>
            </v-card-actions>
        </v-card>

        <div class="d-flex my-3">
            <v-spacer></v-spacer>
            <v-btn v-if="$store.getters['auth/usuarioHasPermissao'](['AlertaCriar'])"
                   color="primary"
                   :to="{name: 'alerta-novo'}"
            >
                Novo Alerta
            </v-btn>
        </div>

        <v-data-table
            :headers="headers"
            :items="items"
            class="elevation-1"
            @update:options="filtrar()"
            :items-per-page="pagination.itemsPerPage"
            :footer-props="footerProps"
            :options.sync="pagination"
            :server-items-length="pagination.total"
        >
            <template v-slot:item.dt_alerta="{ item }">
                <span>{{ dateFormat(item.dt_alerta) }}</span>
            </template>
            <template v-if="$store.getters['auth/usuarioHasPermissao'](['AlertaListar'])"
                      v-slot:item.actions="{ item }">

                <v-btn
                    fab
                    color="cyan"
                    x-small
                    elevation="0"
                    :to="{name: 'alerta-visualizar', params: {alerta: item.id_alerta}}"
                >
                    <v-icon>
                        mdi-magnify
                    </v-icon>
                </v-btn>

                <DeleteAction v-if="$store.getters['auth/usuarioHasPermissao'](['AlertaDeletar'])"
                    @delete="deletar(item[column_id])"/>
            </template>
        </v-data-table>
    </div>
</template>

<script>

    import DeleteAction from "../../components/inputs/DeleteAction.vue";
    import {deletarAlerta, getAlertaPaginate} from "../../api/alerta";
    import {dataTableMixin} from "../../mixins/dataTableMixin";
    import {filterNormalizeMixin} from "../../mixins/filterNormalizeMixin";

    import moment from 'moment';
    import FilterService from "../../filters/filterService";
    moment.locale('pt-br');

    export default {
        name: 'AlertaListar',
        components: {DeleteAction},
        mixins: [dataTableMixin, filterNormalizeMixin],
        data() {
            return {
                headers: [
                    // {text: 'ID', key: 'id', value: 'id_aviso', sortable: false, width: '20px'},
                    {text: 'Nº SEI', sortable: false, value: 'nr_sei'},
                    {text: 'Data alerta', sortable: false, value: 'dt_alerta'},
                    {text: 'Ações', value: 'actions', sortable: false, width: '200px', align: 'center'},
                ],
                items: [],
                column_id: 'id_alerta',
                filtro: {
                    nr_sei: null
                },
            }
        },
        created() {
            getAlertaPaginate(this.pagination.page, this.pagination.itemsPerPage, this.filtro)
                .then(response => {
                    this.items = response.data.data.data;
                    this.pagination.total = response.data.data.total;
                    if (this.pagination.total < this.pagination.itemsPerPage) {
                        this.pagination.page = 1;
                    }
                });
        },
        mounted() {
            const savedFilters = FilterService.loadFilters(this.$route.name);
            if (savedFilters) {
                this.filtro = savedFilters;
            }
        },
        beforeRouteLeave(to, from, next) {
            FilterService.saveFilters(from.name, this.filtro);
            next();
        },
        methods: {
            filtrar() {
                getAlertaPaginate(this.pagination.page, this.pagination.itemsPerPage, this.filtro)
                    .then(response => {
                        this.items = response.data.data.data;
                        this.pagination.total = response.data.data.total;
                        if (this.pagination.total < this.pagination.itemsPerPage) {
                            this.pagination.page = 1;
                        }
                    });
            },
            limparFiltro() {
                this.filtro = {
                    nr_sei: null,
                };

                FilterService.clearFilters(this.$route.name);
                this.filtrar()
            },
            deletar(id) {
                deletarAlerta(id).then(() => {
                    this.items = this.items.filter(item => item[this.column_id] !== id)
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: response.data.success.message,
                    });
                    this.$vuetify.goTo(0);
                });
            },
            dateFormat(date) {
                if (!!date) {
                    return moment(date).format("DD/MM/YYYY");
                }
                return null;
            },
        }
    }
</script>
