import api from './index';

const getOrgaos = () => api.get('orgao/orgaos');
const getOrgaosByConselho = (idConselho) => api.get('orgao/orgaos-por-conselho', {
    params: {
        idConselho: idConselho,
    },
    showLoader: true
});
const getOrgao = (id_orgao) => api.get(`orgao/${id_orgao}`);
const getOrgaoByUser = () => api.get(`orgao/orgao-por-usuario`);
const getOrgaosByUser = () => api.get(`orgao/orgaos-por-usuario`, { showLoader: true });
const createOrgao = (dados) => api.post('orgao', dados);
const updateOrgao = (id_orgao, dados) => api.put(`orgao/${id_orgao}`, dados);
const deleteOrgao = (id_orgao) => api.delete(`orgao/${id_orgao}`);

const getMesesSemQuestionario = (id_orgao) => api.get(`orgao/meses-sem-questionario/${id_orgao}`);

const getOrgaosPaginate = async (page, itemsPerPage, filtrosAplicados = []) => await api.get('orgao/orgaos-paginate', {
    params: {
        page: page,
        limit: itemsPerPage,
        filtros: filtrosAplicados,
    },
    showLoader: true
});

const getOrgaosWithoutPaginate = async (filtrosAplicados = []) => await api.get('orgao/orgao-without-paginate', {
    params: {
        filtros: filtrosAplicados,
    },
    showLoader: true
});

const ativarInativarOrgao = (id_orgao, st_Status) => api.put(`orgao/ativar-inativar/${id_orgao}`, st_Status);

export {
    getOrgaos,
    getOrgaoByUser,
    getOrgaosByUser,
    getOrgaosByConselho,
    getOrgaosPaginate,
    getOrgao,
    createOrgao,
    updateOrgao,
    deleteOrgao,
    getMesesSemQuestionario,
    getOrgaosWithoutPaginate,
    ativarInativarOrgao
}
