<template>
    <div id="chart">
        <apexchart type="line" height="400" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script lang="ts">
import * as apexcharts from "vue-apexcharts";

export default {
    name: "LineChart",
    components: {
        apexchart: apexcharts
    },
    data() {
        return {
            series: [
                {
                    name: 'Pendentes',
                    color: '#FF4560',
                    data: [56, 52, 49, 35, 20, 15, 12, 11, 5, 2]
                },
                {
                    name: 'Enviados',
                    color: '#2ecc71',
                    data: [5, 5, 10, 20, 5, 4, 2, 1, 3, 2]
                }],
            chartOptions: {
                chart: {
                    type: 'line',
                    zoom: {
                        enabled: false
                    }
                },
                annotations: {
                    xaxis: [{
                        x: '15/02',
                        strokeDashArray: 1,
                        borderColor: '#d05d5d',
                        label: {
                            borderColor: '#FF4560',
                            style: {
                                color: '#fff',
                                fontSize: '12pt',
                                background: '#FF4560',
                            },
                            text: 'Prazo Final',
                            orientation: 'horizontal'
                        }
                    }]
                },
                dataLabels: {
                    enabled: true
                },
                stroke: {
                    curve: 'smooth'
                },
                title: {
                    text: 'Questionários Pendentes X Enviados',
                    align: 'center'
                },
                xaxis: {
                    categories: ['01/02', '03/02', '05/02', '07/02', '09/02', '11/02', '14/02', '15/02', '18/02', '20/02'],
                }
            }
        }
    }
}
</script>
