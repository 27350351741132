<template>
    <div>
        <v-form ref="form">
            <v-row>
                <v-col cols="12" md="12">
                    <h4>Opções de resposta</h4>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="12">
                    <v-text-field
                        label="Descrição opção de resposta *"
                        outlined
                        dense
                        v-model="form.ds_opcao_resposta"
                        :rules="validate('required', 'Descrição opção de resposta')"
                        :disabled="visualizar"
                    ></v-text-field>
                </v-col>
                <v-col cols="6" md="2">
                    <v-btn
                        color="primary"
                        @click="addOpcao()"
                    >
                        Adicionar
                    </v-btn>
                </v-col>
            </v-row>
            <v-data-table
                :headers="opcaoHeaders"
                :items="this.opcoes"                
                class="elevation-1 mb-0 mt-4"
            >
                <template v-slot:item.actions="{ item }">
                    <DeleteAction v-if="!visualizar && !responder"                  
                        @delete="deletar(item.id_opcao_resposta_pergunta_qualitativa)"
                    />
                </template>
            </v-data-table>
        </v-form>
    </div>
</template>

<script>

import { getPerguntaQualitativa } from "../../api/pergunta_qualitativa";
import { validationMixin } from "../../mixins/validationMixin";
import DeleteAction from "../inputs/DeleteAction.vue";

export default {
    name: "OpcaoRespostaQualitativa",
    mixins: [validationMixin],
    components: { DeleteAction },
    props: {       
        disabled: {
            type: Boolean,
        },
        opcaoItens: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {
            opcoes: [],
            form: {
                ds_questionario_avulso_anexo: null,
                file: null,
            },
            opcaoHeaders: [
                { text: 'Descrição', value: 'ds_opcao_resposta', sortable: false },
                { text: 'Ações', value: 'actions', sortable: false },
            ],
            visualizar: false,
            responder: false
        }
    },
    created() {
        this.visualizar = this.$route.name == 'questionario-avulso-visualizar' ? true : false;
        this.responder = this.$route.name == 'questionario-avulso-responder' ? true : false;

        if (this.$route.params.perguntaQualitativa) { 
            getPerguntaQualitativa(this.$route.params.perguntaQualitativa).then((response) => {
                this.opcoes = response.data.data.opcao;
                this.$emit("opcaoRespostaQualitativaEmit", response.data.data.opcao);
            })
        }
    },
    methods: {
        addOpcao() {
            if (this.$refs.form.validate()) {
                let arrOpcao = [];
                if ( this.opcoes ) {
                    arrOpcao = this.opcoes;
                }
                
                arrOpcao.push({"id_opcao_resposta_pergunta_qualitativa":"add"+arrOpcao.length,"ds_opcao_resposta": this.form.ds_opcao_resposta});

                this.opcoes = arrOpcao;
                this.$emit("opcaoRespostaQualitativaEmit", this.opcoes);

                this.form.ds_opcao_resposta = '';
            }                
        },

        deletar(id_opcao_resposta_pergunta_qualitativa) {
            var arrOpcao = this.opcoes.filter(function(item){ return item.id_opcao_resposta_pergunta_qualitativa !== id_opcao_resposta_pergunta_qualitativa });
            this.opcoes = arrOpcao;
            this.$emit("opcaoRespostaQualitativaEmit", this.opcoes);
        },
    },
}
</script>
