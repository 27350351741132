<template>
    <div>
        <v-form ref="form">
            <v-row class="pb-1">
                <v-col cols="12" md="12">
                    <h4>
                        Despacho {{ tipo }}
                    </h4>
                </v-col>
            </v-row>
            
            <v-row>
                <v-col cols="12" md="12">
                    <v-textarea                        
                        v-model="form.ds_despacho"
                        :rules="validate('required', 'Despacho ' + tipo)"
                        label="Despacho *"
                        outlined
                        dense
                        :disabled="!enablePanel"
                    ></v-textarea>
                </v-col>
            </v-row>
            <div class="d-flex">
                <v-spacer></v-spacer>
                <v-btn                    
                    color="primary"
                    @click="salvar()"
                    :disabled="!enablePanel"
                >
                    Salvar Despacho
                </v-btn>
            </div>
            
            <v-row class="pb-3">
                <v-col cols="12" md="12">
                    <h4>
                        Histórico
                    </h4>
                </v-col>
            </v-row>
            <v-card            
                class="mx-auto"
            >
                <v-list two-line>
                <template v-for="(item) in despachosItens">
                    <v-list-item
                    >
                        <v-list-item-content>
                            <div class="pb-2">
                                {{ item.ds_despacho }}

                            </div>
                            <v-list-item-subtitle v-html="item.usuario.nm_usuario + ' em ' + fotmatDate(item.created_at)"></v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                </template>
                </v-list>
            </v-card>
        </v-form>
    </div>
</template>

<script>

import { validationMixin } from "../../mixins/validationMixin";
import { getDespachos, createDespacho  } from "../../api/despacho";

import moment from 'moment';
moment.locale('pt-br');

export default {
    name: "Despacho",
    mixins: [ validationMixin ],
    components: { },
    props: {       
        despachos: {
            type: Array,
        },
        tipo: {
            type: String,
            default: 'Federal'
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            form: {
                ds_despacho: null,
                id_resposta: null,
                tp_despacho: null,
            },
            despachosItens: [],            
            enablePanel: true,
        }
    },
    created() {
        this.despachosItens = this.despachos;

        if (this.tipo == 'Federal') {
            this.form.tp_despacho = 'F';
            this.filtrarDespacho('E');
        } else {
            this.form.tp_despacho = 'E';
            this.filtrarDespacho('F');
        }

        if ((this.$store.getters['auth/usuarioAutenticadoTipoAnalista'] != this.tipo) || this.disabled ) {
            this.enablePanel = false;
        }
    },
    methods: {
        salvar() {

            this.form.id_resposta = this.$route.params.resposta;            

            if (this.$refs.form.validate()) {

                let promise;
                        
                promise = createDespacho(this.form);

                promise.then(response => {
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: response.data.message,
                    })

                    getDespachos(this.form.id_resposta).then(response => {
                        this.despachosItens = response.data.data
                        this.filtrarDespacho(this.form.tp_despacho == 'F' ? 'E' : 'F');
                        this.form.ds_despacho = null;

                        if (this.form.tp_despacho == 'F') {                            
                            this.$emit("despachoEmit");
                        }
                    })

                    //update historico
                }).catch(error => this.handleError(error))
            }
        },
        handleError(error) {
            
            if(error.response.status == 422) {
                this.$store.dispatch('alertas/show', {
                    tipo: 'Erro',
                    titulo: 'Ocorreu uma falha no upload do arquivo de despacho.',
                    mensagem: 'O arquivo é de um tipo inválido ou excede o tamanho máximo permitido.',
                });
            } else {
                this.$store.dispatch('alertas/show', {
                    tipo: 'Erro',
                    titulo: error.response.data.error.message.title,
                    mensagem: error.response.data.error.message.msg,
                });
            }

            this.$vuetify.goTo(0);

            if (error.response.data.error.message.type == 403) {
                this.$router.push({name: 'home'});                
            }
        },
        fotmatDate(date) {
            return moment(date).format('DD/MM/YYYY | HH:mm:ss');
        },
        filtrarDespacho(charTipo) {
            this.despachosItens = this.despachosItens.filter(function( obj ) {
                return obj.tp_despacho !== charTipo;
            });
        }
    },
}
</script>
