<template>
    <div>
        <v-form ref="form">
            <h1 class="text-h5 my-4">
                Cadastro de Orgão
            </h1>
            <v-sheet class="pa-4  mb-5" rounded outlined>
                <v-row v-if="!novo">
                    <v-spacer></v-spacer>
                    <toggle-button
                    class="changed-font"
                    :width="100"
                    :height="30"
                    :speed="480"
                    :color="{checked: '#79BD8F', unchecked: '#FF877B'}"
                    :labels="{checked: 'Ativo', unchecked: 'Inativo'}"
                    :sync="true"
                    v-model="form.st_status"
                    :disabled="visualizar"
                    />
                </v-row>
                <h2 class="subtitle-1 mb-3">Dados do Orgão</h2>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-text-field
                            label="Nome do do Orgão *"
                            outlined
                            dense
                            v-model="form.nm_orgao"
                            :rules="validate('required|max 100', 'Nome do Orgão')"
                            :disabled="visualizar"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field
                            label="E-mail do do Orgão *"
                            outlined
                            dense
                            v-model="form.ds_email"
                            :rules="validate('required|email', 'E-mail do Orgão')"
                            :disabled="visualizar"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-text-field
                            label="Sigla *"
                            outlined
                            dense
                            v-model="form.sg_sigla"
                            :rules="validate('required|max 10', 'Sigla')"
                            :disabled="visualizar"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" md="8">
                        <ConselhoSelect
                            label="Conselho"
                            v-model="form.id_conselho"
                            :rules="validate('required', 'Conselho')"
                            :disabled="visualizar"
                        />
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-text-field
                            label="Telefone"
                            outlined
                            dense
                            v-model="form.nr_telefone"
                            v-mask="['(##) ####-####', '(##) #####-####']"
                            :disabled="visualizar"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-text-field
                            label="Data de Entrada"
                            outlined
                            type="date"
                            dense
                            v-model="form.dt_entrada"
                            :rules="validate('required', 'Data de Entrada')"
                            :disabled="visualizar"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="6" v-if="visible_obs">
                        <v-textarea
                            :disabled="visualizar"
                            v-model="form.obs"
                            :rules="validate('required|max 500', 'Observações')"
                            label="Observações"
                            outlined
                            dense
                            :maxlength="maxLength"
                            @input="updateCharCount"
                        ></v-textarea>
                        <span v-if="!visualizar" class="char-count">{{ charCount }}/{{ maxLength }} caracteres</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <AnexosComponent
                            :disabled="visualizar_anexo"
                            :api="orgaoApi"
                            :routes="{ entity_id: this.$route.params.orgao, entity_type: this.entity_type, path: this.path }"
                        />
                    </v-col>
                </v-row>
            </v-sheet>
            <div class="d-flex">
                <v-spacer></v-spacer>
                <v-btn
                    class="mx-2"
                    color="error"
                    @click="voltar()"
                >
                    Voltar
                </v-btn>

                <v-btn
                    v-if="visualizar_btn_historico"
                    class="ml-2"
                    @click="historicoAcaoDialog = true"
                >
                    Histórico
                </v-btn>

                <HistoricoAcaoDialog
                    :entityId="entity_id"
                    :entityType="entity_type"
                    :dialog.sync="historicoAcaoDialog"
                />

                <v-btn v-if="!visualizar"
                    color="primary"
                    @click="salvar()"
                >
                    Salvar
                </v-btn>
            </div>
        </v-form>
    </div>
</template>

<script>

import moment from 'moment';
import { getOrgao, createOrgao, updateOrgao } from "../../api/orgao";
import { validationMixin } from "../../mixins/validationMixin";
import ConselhoSelect from "../../components/inputs/ConselhoSelect.vue";
import {EntityTypes} from "../../constants/constants";
import HistoricoAcaoDialog from "../../components/components/HistoricoAcaoDialog";
import AnexosComponent from "../../components/components/Anexo.vue";
import {uploadAnexo, getListAnexo, deleteAnexo, downloadAnexo} from "../../api/anexo";

moment.locale('pt-br');

export default {
    name: "Orgao",
    components: { AnexosComponent, ConselhoSelect, HistoricoAcaoDialog },
    props: {
        orgao: {
            type: Object,
            default() {
                return {id_orgao: null}
            }
        },
    },
    mixins: [validationMixin],
    data() {
        return {
            form: {
                nm_orgao: null,
                ds_email: null,
                sg_sigla: null,
                nr_telefone: null,
                dt_entrada: null,
                id_conselho: null,
                st_status: true,
                obs: null,
            },
            visualizar: null,
            visible_obs: false,
            visualizar_btn_historico: null,
            novo: null,
            fields: {
                id_conselho: [],
            },
            error: {},
            historicoAcaoDialog: false,
            entity_id: null,
            entity_type: EntityTypes.ORGAO,
            visualizar_anexo: null,
            path: 'orgao',
            orgaoApi: {
                upload: (formData) => uploadAnexo(formData),
                delete: (id) => deleteAnexo(id),
                get: (dados) => getListAnexo(dados),
                download: (id) => downloadAnexo(id),
            },
            charCount: 0,
            maxLength: 500
        }
    },
    created() {

        this.visualizar = this.$route.name == 'orgao-visualizar' ? true : false;
        this.visualizar_btn_historico = this.$route.name == 'orgao-visualizar' ? true : false;
        this.entity_id = this.$route.name == 'orgao-novo' ? "0" : this.$route.params.orgao;
        this.novo = this.$route.name == 'orgao-novo' ? true : false;
        this.visualizar_anexo = this.$route.name == 'orgao-visualizar' ? true : false;
        this.visualizar_anexo = this.$route.name == 'orgao-editar' ? true : false;

        if (this.$store.getters['auth/usuarioHasRole'](['Administrador']) || this.$store.getters['auth/usuarioHasRole'](['Analista'])) {
           this.visible_obs = true;
        }
        // update
        if (this.$route.params.orgao) {
            getOrgao(this.$route.params.orgao).then((response) => {
                this.form.nm_orgao = response.data.data.nm_orgao;
                this.form.ds_email = response.data.data.ds_email;
                this.form.sg_sigla = response.data.data.sg_sigla;
                this.form.nr_telefone = response.data.data.nr_telefone;
                this.form.dt_entrada = moment(response.data.data.dt_entrada).format("YYYY-MM-DD");
                this.form.id_conselho = response.data.data.id_conselho;
                this.form.st_status = response.data.data.st_status;
                this.form.obs = response.data.data.obs;
            })
        }
    },
    methods: {
        salvar() {
            if (this.$refs.form.validate()) {
                let promise;
                if (this.$route.params.orgao) {
                    promise = updateOrgao(this.$route.params.orgao, this.form)
                } else {
                    promise = createOrgao(this.form)
                }

                promise.then(response => {
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: response.data.message,
                    })
                    this.$vuetify.goTo(0)
                    this.$router.push({name: 'orgaos'})
                })
            }
        },
        voltar() {
            this.$router.push({name: 'orgaos'});
        },
        updateCharCount() {
            this.charCount = this.form.obs.length;
        },
    },
}
</script>

<style scoped>
    .char-count {
        margin-top: 8px;
        color: #666;
        font-size: 0.875rem;
    }
</style>
