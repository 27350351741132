import AuthService from '../../services/auth.service';

const user = AuthService.getLocalUser();
const tipoAnalista = AuthService.getTipoAnalista();
const servidor = AuthService.getLocalServidor();
const roles = AuthService.getLocalRoles();
const permissoes = AuthService.getLocalPermissoes();
const token = AuthService.getLocalToken();

export const auth = {
    namespaced: true,
    state: {
        usuarioAutenticado: user,
        usuarioAutenticadoTipoAnalista: tipoAnalista,
        usuarioAutenticadoServidor: servidor,
        usuarioAutenticadoRoles: roles,
        usuarioAutenticadoPermissoes: permissoes,
        usuarioAutenticadoToken: token,
        isLoggedIn: !!user,
        isLoggingOut: false,
    },
    getters: {
        isLoggedIn(state) {
            state.isLoggingOut = false;
            return state.isLoggedIn;
        },
        isLoggingOut(state) {
            return state.isLoggingOut;
        },
        usuarioAutenticado(state) {
            return state.usuarioAutenticado;
        },
        usuarioAutenticadoServidor(state) {
            return state.usuarioAutenticadoServidor;
        },
        usuarioAutenticadoRoles(state) {
            return state.usuarioAutenticadoRoles;
        },
        usuarioAutenticadoTipoAnalista(state) {
            return state.usuarioAutenticadoTipoAnalista;
        },
        usuarioAutenticadoPermissoes(state) {
            return state.usuarioAutenticadoPermissoes;
        },
        usuarioAutenticadoToken(state) {
            return state.usuarioAutenticadoToken;
        },
        usuarioHasRole: (state) => (roles = []) => {
            return !!roles.filter(role => state.usuarioAutenticadoRoles.includes(role)).length
        },
        usuarioHasPermissao: (state) => (permissoes = []) => {
            return !!permissoes.filter(permissao => state.usuarioAutenticadoPermissoes.includes(permissao)).length
        }
    },
    actions: {
        login({ commit }, code) {
            return AuthService.login(code).then(
                () => {
                    commit('loginSuccess');
                    return Promise.resolve();
                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            );
        },
        logout({ commit }) {            
            AuthService.logout();
            commit('menu/closeSideBar', null, { root: true });
            commit('logout');
        }
    },
    mutations: {
        loginSuccess(state) {
            state.isLoggedIn = true;
            state.usuarioAutenticado = AuthService.getLocalUser();
            state.usuarioAutenticadoServidor = AuthService.getLocalServidor();
            state.usuarioAutenticadoRoles = AuthService.getLocalRoles();
            state.usuarioAutenticadoTipoAnalista = AuthService.getTipoAnalista();
            state.usuarioAutenticadoPermissoes = AuthService.getLocalPermissoes();
            state.usuarioAutenticadoToken = AuthService.getLocalToken();
        },
        loginFailure(state) {
            state.isLoggedIn = false;
            state.usuarioAutenticado = null;
            state.usuarioAutenticadoTipoAnalista = null;
            state.usuarioAutenticadoServidor = null;
            state.usuarioAutenticadoRoles = null;
            state.usuarioAutenticadoPermissoes = null;
        },
        logout(state) {
            state.isLoggingOut = true;
            state.isLoggedIn = false;
            state.usuarioAutenticado = null;
            state.usuarioAutenticadoTipoAnalista = null;
            state.usuarioAutenticadoServidor = null;
            state.usuarioAutenticadoRoles = null;
            state.usuarioAutenticadoPermissoes = null;
        }
    }
};
