<template>
    <div v-if="loader" >
        <Loader />
    </div>
</template>

<script>

import Loader from '../app/Loader.vue';

export default {
    name: "Logout",
    components: {Loader},
    mixins: [],
    data() {
        return {
            loader: true,
        }
    },
    mounted() {
        this.$router.push({ name: 'home'});
        this.loader = false;

    },
    methods: {
    },
}
</script>

<style scoped>

</style>