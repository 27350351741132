<template>
    <div>
        <v-card class="mb-9">
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="12">
                        <v-text-field
                            label="Nome"
                            outlined
                            dense
                            @keyup.enter="filtrar()"
                            v-model="filtro.nm_situacao_regime"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    outlined
                    @click="limparFiltro()"
                >
                    Limpar
                </v-btn>
                <v-btn
                    color="primary"
                    @click="filtrar()"
                >
                    <v-icon left>
                        mdi-filter
                    </v-icon>
                    Filtrar
                </v-btn>
            </v-card-actions>
        </v-card>

        <div class="d-flex my-3">
            <v-spacer></v-spacer>
            <v-btn v-if="$store.getters['auth/usuarioHasRole'](['Administrador'])"
                color="primary"
                :to="{name: 'situacao-regime-novo'}"
            >
                Novo Registro
            </v-btn>
        </div>

        <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page="pagination.itemsPerPage"
            :footer-props="footerProps"
            :options.sync="pagination"
            @update:options="filtrar()"
            :server-items-length="pagination.total"
            class="elevation-1"
        >

            <template v-if="$store.getters['auth/usuarioHasPermissao'](['SituaçãoRegimeListar'])" v-slot:item.actions="{ item }">
                <v-tooltip v-if="$store.getters['auth/usuarioHasPermissao'](['SituaçãoRegimeAtualizar'])" top>
                    <template v-slot:activator="{ on: tooltip}">
                        <v-btn
                            fab
                            color="success"
                            x-small
                            elevation="0"
                            :to="{name: 'situacao-regime-editar', params: {situacaoRegime: item.id_situacao_regime}}"
                            v-on="{ ...tooltip}"
                        >
                            <v-icon>
                                mdi-pencil
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Editar</span>
                </v-tooltip>
                <DeleteAction v-if="$store.getters['auth/usuarioHasPermissao'](['SituaçãoRegimeDeletar'])" @delete="deletar(item[column_id])"/>
            </template>
        </v-data-table>
    </div>
</template>

<script>

import { getSituacaoPaginate, deletarSituacaoRegime, getSituacaoRegime } from "../../api/situacao_regime";
import { dataTableMixin } from "../../mixins/dataTableMixin";
import _ from "lodash";
import { filterNormalizeMixin } from "../../mixins/filterNormalizeMixin";
import DeleteAction from "../../components/inputs/DeleteAction.vue";
import FilterService from "../../filters/filterService";

export default {
    name: "SituacaoRegimeListar",
    components: { DeleteAction },
    mixins: [dataTableMixin, filterNormalizeMixin],
    data() {
        return {
            filtro: {
                nm_situacao_regime: null,
            },
            loading: true,
            column_id: 'id_situacao_regime',
            headers: [
                // { text: 'ID', value: 'id_situacao_regime', sortable: false, width: '100px' },
                { text: 'Nome', value: 'nm_situacao_regime', sortable: false },
                { text: 'Ações', value: 'actions', sortable: false, width: '200px' },
            ]
        }
    },
    methods: {
        limparFiltro() {
            this.filtro = {
                nm_situacao_regime: null,
            };

            FilterService.clearFilters(this.$route.name);
            this.filtrar()
        },
        filtrar() {
            getSituacaoPaginate(this.pagination.page, this.pagination.itemsPerPage, this.filtro)
                .then(response => {
                    this.items = response.data.data.data;
                    this.pagination.total = response.data.data.total;
                    if (this.pagination.total < this.pagination.itemsPerPage) {
                        this.pagination.page = 1;
                    }
                }).catch(error => this.handleError(error));
        },
        deletar(id) {
            getSituacaoRegime(id).then((response) => {
                deletarSituacaoRegime(id).then((response) => {
                    this.items = this.items.filter(item => item[this.column_id] !== id)
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: response.data.success.message,
                    });
                    this.$vuetify.goTo(0);
                })
                .catch(error => this.handleError(error))
            })
        },
        handleError(error) {
            this.$store.dispatch('alertas/show', {
                tipo: 'Erro',
                titulo: error.response.data.error.message.title,
                mensagem: error.response.data.error.message.msg,
            });
            this.$vuetify.goTo(0);

            if (error.response.data.error.message.type == 403) {
                this.$router.push({name: 'home'});
            }
        }
    },
    mounted() {
        const savedFilters = FilterService.loadFilters(this.$route.name);
        if (savedFilters) {
            this.filtro = savedFilters;
        }
    },
    beforeRouteLeave(to, from, next) {
        FilterService.saveFilters(from.name, this.filtro);
        next();
    },
}
</script>
