<template>
    <div class="d-flex flex-wrap">
        <div v-if="(Array.isArray(conselhos))" >
            <div v-for="(conselho, key) in conselhos" :key="key" class="mx-1">
                <v-chip x-small color="blue" text-color="white">{{conselho.sg_sigla}}</v-chip>
            </div>
        </div>
        <div v-else>
            <v-chip v-if="conselhos !== null" x-small color="blue" text-color="white">{{conselhos.sg_sigla}}</v-chip>
        </div>
    </div>
</template>

<script>
export default {
    name: "ConselhosChip",
    props: ['conselhos']
}
</script>

<style scoped>

</style>
