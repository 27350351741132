<template>
    <v-select
        :label="label"
        outlined
        dense
        :disabled="disabled"
        clearable
        :value="value"        
        @input="$emit('input', $event)"
        :rules="rules"
        :items="conselhos"
        item-value="id_conselho"
        item-text="nm_conselho"
    />
</template>

<script>
import { getConselhos } from "../../api/conselho";

export default {
    name: "ConselhoSelect",
    props: {
        label: {
            type: String,
            default: 'Conselho'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        value: {
            type: Number|Array,
        },
        multiple: {
            type: Boolean,
            default: false
        },
        rules: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            conselhos: [],
        }
    },
    created() {
        getConselhos().then(response => this.conselhos = response.data.data);
    }
}
</script>
