<template>
    <v-app>
        <template v-if="routesWithoutAuth.filter((name) => name == $route.name).length">
            <router-view :key="$route.path"></router-view>
        </template>

        <template v-else-if="loggedIn">
            <Loader v-if="loading" />
            <Header :loggedIn="loggedIn"/>

            <v-main>
                <Menu />
                <v-container fluid app>
                    <Alerta class="px-md-10 mt-md-5"/>
                    <Breadcrumb class="px-md-10 mt-md-3"/>

                    <router-view :key="$route.path" class="col-12 px-md-10 pt-0"></router-view>
                </v-container>
            </v-main>
            <v-footer
                color="#071d41"
                class="font-weight-medium"
                height="100"
            >
                <v-col
                    class="text-center"
                    cols="12">
                </v-col>
            </v-footer>
        </template>

        <template v-else-if="isLoggingOut">
              
        </template>

        <Login v-else/>
    </v-app>
</template>


<script>
    import Header from '../app//Header.vue';
    import Menu from '../app/Menu.vue';
    import Alerta from '../app/Alerta.vue';
    import Breadcrumb from '../app/Breadcrumb.vue';
    import { mapState, mapMutations, mapAction } from 'vuex';
    import Loader from '../app/Loader.vue';
    import Login from "../auth/Login";

    export default {
        name: 'main-app',
        components: {
            Login,
            Header,
            Menu,
            Alerta,
            Breadcrumb,
            Loader
        },
        data() {
            return {
                routesWithoutAuth: ['valida', 'logout']
            }
        },
        computed: {
            ...mapState('loader', ['loading']),
            loggedIn() {
                return this.$store.getters['auth/isLoggedIn'];
            },
            isLoggingOut() {
                return this.$store.getters['auth/isLoggingOut'];
            }
        }
    }
</script>

<style scoped>
    @media print{
        .v-content {
            padding: 0 !important;
        }
    }
</style>

<style>
    .v-application--wrap {
        min-height: auto !important;
    }

    html, body {
        height: 100%;
    }

    h1 {
        font-weight: normal;
        margin-bottom: 30px;
    }

    #app {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    a { text-decoration: none; }
</style>
