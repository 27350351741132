<template>
    <div>
        <v-form ref="form">
            <h1 class="text-h5 my-4">
                Comunicação em grupo
            </h1>
            <v-sheet class="pa-4  mb-5" rounded outlined>
                <h2 class="subtitle-1 mb-3">Dados do Email</h2>

                <v-row>
                    <v-col cols="12" md="12">
                        <ConselhoSelect
                            label="Destinatário *"                            
                            v-model="form.id_conselho"  
                            :rules="validate('required', 'Destinatário')"
                        />
                    </v-col>
                </v-row> 

                <v-row>
                    <v-col cols="12" md="12">
                        <v-text-field
                            label="Título *"
                            outlined
                            dense
                            v-model="form.tx_titulo"
                            :rules="validate('required', 'Título')"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" md="12">
                        <v-textarea
                            label="Corpo do E-mail *"
                            outlined
                            dense
                            v-model="form.ds_email"
                            :rules="validate('required', 'Corpo do E-mail')"
                        ></v-textarea>
                    </v-col>                  
                </v-row>
                
            </v-sheet>
            <div class="d-flex">
                <v-spacer></v-spacer>
                <v-btn
                    class="mx-2"
                    color="error"
                    @click="voltar()"
                >
                    Voltar
                </v-btn>
                
                <v-btn
                    color="primary"
                    @click="enviar()"
                >
                    Enviar
                </v-btn>
            </div>
        </v-form>
    </div>
</template>

<script>

import moment from 'moment';
import { enviarEmailGrupo } from "../../api/comunicacao";
import { validationMixin } from "../../mixins/validationMixin";
import ConselhoSelect from "../../components/inputs/ConselhoSelect.vue";

moment.locale('pt-br');

export default {
    name: "Orgao",
    components: { ConselhoSelect },
    props: {
        orgao: {
            type: Object,
            default() {
                return {id_orgao: null}
            }
        },
    },
    mixins: [validationMixin],
    data() {
        return {
            form: {
                nm_comunicacao: 'grupo',
                id_conselho: null,
                tx_titulo: null,
                ds_email: null
            },
            fields: {
                id_conselho: [],
            },      
            error: {}
        }
    },
    created() {

    },
    methods: {
        enviar() {
            if (this.$refs.form.validate()) {
                let promise;
                promise = enviarEmailGrupo(this.form)

                promise.then(response => {
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: response.data.success.message,
                    })
                    this.$vuetify.goTo(0)
                    this.$router.push({name: 'comunicacoes'})
                })
            }            
        },
        voltar() {           
            this.$router.push({name: 'comunicacoes'});
        },
    },
}
</script>
