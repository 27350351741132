const FILTER_KEY_PREFIX = 'filters-';

class FilterService {
    static saveFilters(screenName, filters) {
        sessionStorage.setItem(`${FILTER_KEY_PREFIX}${screenName}`, JSON.stringify(filters));
    }

    static loadFilters(screenName) {
        const filters = sessionStorage.getItem(`${FILTER_KEY_PREFIX}${screenName}`);
        return filters ? JSON.parse(filters) : null;
    }

    static clearFilters(screenName) {
        sessionStorage.removeItem(`${FILTER_KEY_PREFIX}${screenName}`);
    }
    static clearAllFilters() {
        sessionStorage.clear();
    }
}

export default FilterService;
