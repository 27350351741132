<template>
    <Loader />
</template>


<script>
    import Loader from '../app/Loader.vue';

    export default {
        name: 'auth-valida-app',
        components: {
            Loader
        },
        mounted() {

            let code;
            code = this.$route.query.code;

        	this.$store.dispatch('auth/login',code).then(
                
                response => {                        
                        this.$router.push({ name: 'home' });                 
                },
                error => {
                    
                    this.$router.push({ name: 'home'},
                        () => this.$store.dispatch('alertas/show', {
                            tipo: 'Erro',
                            titulo: error.response.data.title,
                            mensagem: error.response.data.msg
                        })
                    )
                }
            );
		},
    }
</script>
