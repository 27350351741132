import api from './index';

const getConfiguracoes = () => api.get('configuracao/configuracoes');
const getConfiguracao = (id_configuracao) => api.get(`configuracao/${id_configuracao}`);
const createConfiguracao = (dados) => api.post('configuracao', dados);
const updateConfiguracao = (id_configuracao, dados) => api.put(`configuracao/${id_configuracao}`, dados);
const deleteConfiguracao = (id_configuracao) => api.delete(`configuracao/${id_configuracao}`);

const getConfiguracoesPaginate = async (page, itemsPerPage, filtrosAplicados = []) => await api.get('configuracao/configuracoes-paginate', {
    params: {
        page: page,
        limit: itemsPerPage,
        filtros: filtrosAplicados,
    },
    showLoader: false
});

// metodo sem authenticação pras POGs sem gov br
const updateConfiguracaoOut = (id_configuracao, dados) => api.put(`configuracao-out/${id_configuracao}`, dados);

export {    
    getConfiguracoes,
    getConfiguracoesPaginate,
    getConfiguracao,
    createConfiguracao,
    updateConfiguracao,
    deleteConfiguracao,
    updateConfiguracaoOut
}
