import api from './index';

const getTipoAtoConcessoes = () => api.get('tipo-ato-concessao/tipo-ato-concessoes');
const createTipoAto = (dados) => api.post('tipo-ato-concessao', dados);
const getTipoAto = (id_tipo_ato) => api.get(`tipo-ato-concessao/${id_tipo_ato}`);
const updateTipoAto = (id_tipo_ato, dados) => api.put(`tipo-ato-concessao/${id_tipo_ato}`, dados);
const deletarTipoAto = (id_tipo_ato) => api.delete(`tipo-ato-concessao/${id_tipo_ato}`);

const getAtosPaginate = async (page, itemsPerPage, filtrosAplicados = []) => await api.get('tipo-ato-concessao/tipo-ato-paginate', {
    params: {
        page: page,
        limit: itemsPerPage,
        filtros: filtrosAplicados,
    },
    showLoader: true
});

export {    
    getTipoAtoConcessoes,
    getAtosPaginate,
    createTipoAto,
    getTipoAto,
    updateTipoAto,
    deletarTipoAto
}
