<!-- source: https://github.com/vuetifyjs/vuetify/blob/master/packages/docs/src/examples/v-date-picker-month/misc-dialog-and-menu.vue -->
<script lang="ts">
export default {
    props: {
        rules: null
    },
    data: () => ({
        date: null,
        menu: false,
        dateDisplay: null
    }),
    methods: {
        setDate(date) {
            const [year, month] = date.split('-');
            this.dateDisplay = `${month}/${year}`;
            this.$emit('input', this.dateDisplay);
            this.$refs.menu.save(date);
        },
        clearDate() {
            this.date = null;
            this.dateDisplay = null;
            this.$emit('input', null);
        }
    },
    emits: ['input']
}
</script>

<template>
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="dateDisplay"
                label="Selecione Mês/Ano"
                readonly
                outlined
                dense
                v-bind="attrs"
                v-on="on"
                :rules="rules"
            ></v-text-field>
        </template>
        <v-date-picker
            v-model="date"
            type="month"
            no-title
            scrollable
            @change="setDate(date)"
        >
            <v-spacer></v-spacer>
        </v-date-picker>
    </v-menu>
</template>
