import api from './index';

const getRespostaQuestionarioAvulsAnexoDownload = (id_questionario_avulso_anexo) => api.get('resposta-questionario-avulso-anexo/' + id_questionario_avulso_anexo + '/download', {responseType: 'blob'})

const uploadRespostaQuestionarioAvulsoAnexo = (id_resposta_questionario_avulso, dados) => api.post(`resposta-questionario-avulso-anexo/upload/${id_resposta_questionario_avulso}`, dados, {
    headers: {
        "Content-Type": "multipart/form-data",
    },
    maxContentLength: 11000,
    maxBodyLength: 11000,
});

const deleteRespostaQuestionarioAvulsoAnexo = (id_resposta_questionario_avulso_anexo) => api.delete(`resposta-questionario-avulso-anexo/${id_resposta_questionario_avulso_anexo}`);

export {    
    getRespostaQuestionarioAvulsAnexoDownload,
    uploadRespostaQuestionarioAvulsoAnexo,
    deleteRespostaQuestionarioAvulsoAnexo,
}