<template>
    <div>
        <v-form ref="form">
            <h1 class="text-h5 my-4">
                <span v-if="loaded && !analiseSectionDisable">
                    Análise da
                </span>
                <span v-if="loaded">
                    Resposta
                </span>
            </h1>

            <v-alert
                v-if="respostaDevolvidaEnable"
                border="top"
                colored-border
                type="warning"
                elevation="2"
            >
                <h3>Observação da Devolução</h3>
                {{ this.form.ds_observacao_devolucao }}
            </v-alert>
            <input type="hidden" name="id_questionario" class="form-control" :value="form.id_questionario">
            <input type="hidden" name="id_resposta_status" class="form-control" :value="form.id_resposta_status">
            <v-sheet v-if="loaded" class="pa-4 mb-5" rounded outlined>

                <v-row class="pb-1">
                    <v-col cols="12" md="12">
                        <h3>
                            {{ this.form.id_pergunta }} - {{ this.form.ds_pergunta }}
                        </h3>
                    </v-col>
                </v-row>
                <v-row class="pb-1">
                    <v-col cols="12" md="12">
                        <v-radio-group
                            :disabled="visualizar || respostaStatusDisable || analiseDisable"
                            v-model="form.st_resposta"
                            :rules="validate('required', 'Resposta')"
                            @change="stRespostaChange()"
                            row
                        >
                            <v-radio label="Sim" :value="true"></v-radio>
                            <v-radio label="Não" :value="false"></v-radio>
                        </v-radio-group>
                        <v-alert
                            v-if="respostaStatusDisable"
                            border="top"
                            colored-border
                            type="info"
                            elevation="2"
                        >
                        Só é permitido adicionar respostas SIM para esta pergunta, pois já foi adicionada pelo menos uma resposta SIM.
                        </v-alert>
                    </v-col>
                </v-row>
            </v-sheet>
            <v-row>
                <v-col cols="12" md="9">
                    <v-sheet v-if="form.st_resposta" class="pa-4 mb-5" rounded outlined>
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-select
                                    label="Tipo do Ato de Concessão *"
                                    outlined
                                    :disabled="visualizar || analiseDisable"
                                    v-model="form.id_tipo_ato_concessao"
                                    dense
                                    clearable
                                    :items="tipoAtoConcessaoSelect"
                                    item-value="id_tipo_ato_concessao"
                                    item-text="nm_tipo_ato_concessao"
                                    :rules="validate('required', 'Tipo do Ato de Concessão')"
                                />
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    label="Data do Ato de Concessão *"
                                    outlined
                                    type="date"
                                    dense
                                    v-model="form.dt_ato_concessao"
                                    :rules="validate('required', 'Data do Ato de Concessão')"
                                    :disabled="visualizar || analiseDisable"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-select
                                    label="Situação do Regime *"
                                    outlined
                                    :disabled="visualizar || analiseDisable"
                                    v-model="form.id_situacao_regime"
                                    dense
                                    clearable
                                    :items="situacoesRegimeSelect"
                                    item-value="id_situacao_regime"
                                    item-text="nm_situacao_regime"
                                    @change="situacaoRegimeChange()"
                                    :rules="validate('required', 'Situação do Regime')"
                                />
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    label="Status"
                                    outlined
                                    dense
                                    v-model="form.nm_resposta_status"
                                    disabled
                                ></v-text-field>
                            </v-col>

                        </v-row>

                        <div v-if="justificativaDespesaIrrelevanteEnable">
                            <v-row>
                                <v-col cols="12" md="12">
                                    <v-alert
                                        outlined
                                        type="warning"
                                        prominent
                                        border="left"
                                        >
                                        Identificamos que "Despesa Irrelevante" já foi utilizado neste exercício. Por favor, justifique.
                                    </v-alert>
                                </v-col>
                            </v-row>

                            <v-row >
                                <v-col cols="12" md="12">
                                    <v-textarea
                                        label="Justificativa da utilização de 'Despesa Irrelevante' *"
                                        :disabled="visualizar || analiseDisable"
                                        outlined
                                        dense
                                        v-model="form.ds_justificativa_despesa_irrelevante"
                                        :rules="validate('required', 'justificativa')"
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </div>

                        <v-row v-if="enableInciso()">
                            <v-col cols="12" md="12">
                                <v-text-field
                                    label="A qual inciso o ressavaldo no plano de Recuperação Fiscal se refere? *"
                                    :disabled="visualizar || analiseDisable"
                                    outlined
                                    dense
                                    v-model="form.ds_inciso"
                                    :rules="validate('required', 'inciso')"
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" md="6">
                                <v-textarea
                                    :disabled="visualizar || analiseDisable"
                                    v-model="form.ds_meio_publicacao"
                                    :rules="validate('required', 'Meio de Publicação')"
                                    label="Meio de Publicação"
                                    outlined
                                    dense
                                ></v-textarea>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-textarea
                                    :disabled="visualizar || analiseDisable"
                                    v-model="form.ds_descricao"
                                    :rules="validate('required', 'Descrição')"
                                    label="Descrição"
                                    outlined
                                    dense
                                ></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row v-if="form.id_pergunta == 2 || form.id_pergunta == 3">
                            <v-col cols="12" md="8">
                                <v-text-field
                                    label="Especificação do Cargo *"
                                    :disabled="visualizar || analiseDisable"
                                    outlined
                                    dense
                                    v-model="form.ds_especificacao_cargo"
                                    :rules="validate('required|max 250', 'especificação do cargo')"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-text-field
                                    label="Número de Vagas *"
                                    :disabled="visualizar || analiseDisable"
                                    outlined
                                    dense
                                    v-model="form.vl_numero_vagas"
                                    :rules="validate('required|max 250', 'número de vagas')"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row v-if="form.id_pergunta == 4">
                            <v-col cols="12" md="12">
                                <v-text-field
                                    label="Número de Servidores Nomeados *"
                                    :disabled="visualizar || analiseDisable"
                                    outlined
                                    dense
                                    v-model="form.vl_numero_servidores_nomeados"
                                    :rules="validate('required|max 250', 'número de servidores nomeados')"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row v-if="form.id_pergunta == 7">
                            <v-col cols="12" md="12">
                                <v-text-field
                                    label="Convênio Confaz *"
                                    :disabled="visualizar || analiseDisable"
                                    outlined
                                    dense
                                    v-model="form.ds_convenio_confaz"
                                    :rules="validate('required|max 250', 'convênio Confaz')"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row v-if="form.id_pergunta == 8">
                            <v-col cols="12" md="6">
                                <v-currency-field
                                    label="Alíquota Anterior *"
                                    :disabled="visualizar || analiseDisable"
                                    outlined
                                    dense
                                    :rules="validate('required', 'alíquota anterior')"
                                    locale="pt-BR"
                                    prefix=""
                                    v-model="form.vl_aliquota_anterior"
                                />
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-currency-field
                                    label="Alíquota Após Alteração *"
                                    :disabled="visualizar || analiseDisable"
                                    outlined
                                    dense
                                    :rules="validate('required', 'alíquota após alteração')"
                                    locale="pt-BR"
                                    prefix=""
                                    v-model="form.vl_aliquota_apos_alteracao"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <RespostaAnexo
                                    @respostaAnexoEmit="respostaAnexoEmit"
                                    :disabled="visualizar || analiseDisable"
                                    :anexoItens="this.anexos"
                                ></RespostaAnexo>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-alert
                                    v-if="this.anexosEmpty"
                                    outlined
                                    type="warning"
                                    prominent
                                    border="left"
                                    >
                                    É necessário anexar ao menos um arquivo.
                                </v-alert>
                            </v-col>
                        </v-row>
                    </v-sheet>

                </v-col>
                <v-col cols="12" md="3">
                    <v-sheet v-if="loaded && form.st_resposta" class="pa-4 mb-5" rounded outlined>
                        <v-row>
                            <v-col cols="12" md="12">
                                <h4>
                                    Há impacto financeiro?
                                </h4>
                            </v-col>
                            <v-col cols="12" md="12">
                                <v-radio-group
                                    :disabled="visualizar || analiseDisable"
                                    v-model="form.st_impacta_orcamento"
                                    :rules="validate('required', 'Impacta Orçamento')"
                                    row
                                >
                                    <v-radio label="Sim" :value="true"></v-radio>
                                    <v-radio label="Não" :value="false"></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row v-if="form.st_impacta_orcamento">
                            <v-col>
                                <v-data-table
                                    :headers="orcamentoHeaders"
                                    :items="form.acrescimos_orcamentarios"
                                    :hide-default-footer="true"
                                    class="elevation-1 mb-7"
                                >
                                <template v-slot:item.dt_ano="{ item }">
                                    <span>{{ dateFormat(item.dt_ano) }}</span>
                                </template>
                                <template v-slot:item.vl_acrescimo="{ item }">
                                    <v-currency-field
                                        :disabled="visualizar || analiseDisable"
                                        outlined
                                        dense
                                        locale="pt-BR"
                                        prefix="R$ "
                                        v-model="item.vl_acrescimo"
                                        hide-details="false"
                                        @change="(event) => acrescimosOrcamentariosChange(event)"
                                    />
                                </template>
                                <template slot="body.append">
                                    <tr>
                                        <th>Total</th>
                                        <th>
                                            <v-currency-field
                                                disabled
                                                outlined
                                                dense
                                                locale="pt-BR"
                                                prefix="R$ "
                                                v-model="this.totalAcrescimos"
                                                hide-details="false"
                                            />
                                        </th>
                                    </tr>
                                </template>
                                </v-data-table>
                                <v-alert
                                    v-if="this.totalAcrescimos <= 0"
                                    outlined
                                    type="warning"
                                    prominent
                                    border="left"
                                    >
                                    O valor total dos acréscimos precisa ser maior que R$ 0,00.
                                </v-alert>
                            </v-col>
                        </v-row>
                    </v-sheet>

                </v-col>
            </v-row>

            <div v-if="loaded && !analiseSectionDisable && despachoSectionShow">
                <v-row class="pb-2">
                    <v-col cols="12" md="12">
                        <h3>
                            Análise da Resposta
                        </h3>
                    </v-col>
                </v-row>
            </div>

            <v-row class="pb-4" v-if="despachoSectionShow">
                <v-col cols="12" md="6">
                    <v-sheet v-if="loaded && !analiseSectionDisable" class="pa-4 mb-5" rounded outlined>
                        <Despacho
                        :disabled="visualizar"
                        :despachos="this.despachosItens"
                        tipo="Estadual"
                        ></Despacho>
                    </v-sheet>
                </v-col>
                <v-col cols="12" md="6">
                    <v-sheet v-if="loaded && !analiseSectionDisable" class="pa-4 mb-5" rounded outlined>
                        <Despacho
                            :disabled="visualizar"
                            @despachoEmit="despachoEmit"
                            :despachos="this.despachosItens"
                            tipo="Federal"
                        ></Despacho>
                    </v-sheet>
                </v-col>
            </v-row>

            <div v-if="loaded && seiSectionEnable">
                <v-row class="pb-2">
                    <v-col cols="12" md="12">
                        <h3>
                            Informações do SEI
                        </h3>
                    </v-col>
                </v-row>
            </div>

            <v-row class="pb-4">
                <v-col cols="12" md="12">
                    <v-sheet v-if="loaded && seiSectionEnable" class="pa-4 mb-5" rounded outlined>
                        <v-divider></v-divider>
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field
                                    label="Número do SEI"
                                    outlined
                                    type="text"
                                    dense
                                    @input="nrSeiChange()"
                                    v-model="form.nr_sei"
                                    :disabled="visualizar"
                                    hide-details="false"
                                    :rules="validate('max 250', 'Número do SEI')"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col v-if="delibercaoSeiShow" cols="12" md="12">
                                <v-textarea
                                    :disabled="visualizar"
                                    v-model="form.ds_deliberacao_sei"
                                    label="Deliberação"
                                    outlined
                                    dense
                                    hide-details="false"
                                ></v-textarea>
                            </v-col>

                        </v-row>
                    </v-sheet>
                </v-col>
            </v-row>

            <div class="d-flex">
                <v-spacer></v-spacer>
                <v-btn
                    v-if="loaded"
                    color="error"
                    @click="voltar()"
                >
                    Voltar
                </v-btn>

                <v-btn v-if="!visualizar && devolverEnable"
                    class="ml-2"
                    color="warning"
                    @click="devolver_dialog = true"
                >
                    Devolver
                </v-btn>

                <v-btn
                    v-if="loaded && imprimePDFEnable"
                    class="ml-2"
                    color="primary"
                    @click="callPDF()"
                >
                    Exportar como PDF
                </v-btn>

                <RespostaPDF v-if="loaded && callPF" :resposta="resposta"/>

                <v-dialog v-model="devolver_dialog" persistent class="v-dialog">
                    <v-card class="v-dialog-card">
                        <v-card-title class="headline">
                            Deseja realmente devolver esta Resposta?
                        </v-card-title>
                        <v-card-text>
                            Se sim, insira a observação que será exibida ao Cadastrador.
                            <v-divider></v-divider>
                            <v-form ref="devForm">
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <v-textarea
                                            v-model="devForm.ds_observacao_devolucao"
                                            :rules="validate('required', 'Observação de Devolução')"
                                            label="Observação de Devolução"
                                            outlined
                                            dense
                                        ></v-textarea>
                                    </v-col>
                                </v-row>

                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="error"
                                @click="devolver_dialog = false">
                                Cancelar
                            </v-btn>
                            <v-btn
                                color="primary"
                                @click="devolver()"
                            >
                                Devolver
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-btn v-if="!visualizar && validarEnable"
                    class="ml-2"
                    color="success"
                    @click="validar_dialog = true"
                >
                    Validar
                </v-btn>

                <v-dialog v-model="validar_dialog" persistent class="v-dialog">
                    <v-card class="v-dialog-card">
                        <v-card-title class="headline">
                            Deseja realmente validar esta Resposta?
                        </v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="error"
                                @click="validar_dialog = false">
                                Cancelar
                            </v-btn>
                            <v-btn
                                color="success"
                                @click="validar()"
                            >
                                Validar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-btn v-if="!visualizar && respostaDevolvidaEnable"
                    class="ml-2"
                    color="success"
                    @click="corrigir_dialog = true"
                >
                    Submeter Correção
                </v-btn>

                <v-dialog v-model="corrigir_dialog" persistent class="v-dialog">
                    <v-card class="v-dialog-card">
                        <v-card-title class="headline">
                            Deseja realmente submeter a correção solicitada para esta Resposta?
                        </v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="error"
                                @click="corrigir_dialog = false">
                                Cancelar
                            </v-btn>
                            <v-btn
                                color="success"
                                @click="salvar()"
                            >
                                Submeter Correção
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-btn v-if="podeSalvar()"
                    class="ml-2"
                    color="primary"
                    @click="salvar()"
                >
                    Salvar
                </v-btn>
            </div>
        </v-form>
    </div>
</template>

<script>

import { getQuestionario, getUsoDespesaIrrelevante } from "../../api/questionario";
import { getResposta, updateResposta, createResposta, validaResposta, devolverResposta  } from "../../api/resposta";
import { getPergunta } from "../../api/pergunta";
import { getTipoAtoConcessoes  } from "../../api/tipo_ato_concessao";
import { getSituacoesRegime  } from "../../api/situacao_regime";
import { validationMixin } from "../../mixins/validationMixin";
import RespostaAnexo from "../../components/components/RespostaAnexo.vue";
import Despacho from "../../components/components/Despacho.vue";
import CurrencyInput from '../../components/inputs/CurrencyInput.vue'

import RespostaPDF from '../../views/pdf/RespostaPDF.vue'

import moment from 'moment';

moment.locale('pt-br');

export default {
    name: "Resposta",
    components: { RespostaAnexo, CurrencyInput, Despacho, RespostaPDF },
    mixins: [validationMixin],
    data() {
        return {
            devolver_dialog: false,
            validar_dialog: false,
            corrigir_dialog: false,
            seiSectionEnable: false,
            devForm: {
                id_questionario: null,
                id_pergunta: null,
                ds_observacao_devolucao: null,
            },
            form: {
                id_questionario: null,
                ds_pergunta: null,
                id_pergunta: null,
                st_resposta: null,
                ds_descricao: null,
                ds_meio_publicacao: null,
                ds_observacao_devolucao: null,
                ds_especificacao_cargo: null,
                vl_numero_vagas: null,
                vl_numero_servidores_nomeados: null,
                ds_convenio_confaz: null,
                vl_aliquota_anterior: 0.0,
                vl_aliquota_apos_alteracao: 0.0,
                ds_deliberacao_sei: null,
                nr_sei: null,
                id_tipo_ato_concessao: null,
                dt_ato_concessao: null,
                id_situacao_regime: null,
                id_resposta_status: null,
                nm_resposta_status: null,
                st_impacta_orcamento: null,
                ds_despacho_federal: null,
                acrescimos_orcamentarios: [],
            },
            totalAcrescimos: 0,
            orcamentoHeaders: [
                { text: 'Ano', value: 'dt_ano', sortable: false },
                { text: 'Valor', value: 'vl_acrescimo', sortable: false },
            ],
            anexos: [],
            resposta: {},
            questionario: {},
            anexosEmpty: false,
            visualizar: null,
            editar: null,
            imprimePDFEnable: false,
            respostaDevolvidaEnable: false,
            respostaStatusDisable: false,
            analiseDisable: false,
            devolverEnable: false,
            validarEnable: false,
            delibercaoSeiShow: false,
            analiseSectionDisable: false,
            justificativaDespesaIrrelevanteEnable: false,
            despachoSectionShow: false,
            contRespostas: 0,
            despachosItens: [],
            tipoAtoConcessaoSelect: [],
            situacoesRegimeSelect: [],
            temRespostaNao: false,
            loaded: false,
            error: {},
            callPF: false,
        }
    },
    created() {

        this.visualizar = this.$route.name == 'qv-resposta-visualizar' || this.$route.name == 'qe-resposta-visualizar' || this.$route.name == 'qr-resposta-visualizar'? true : false;
        this.editar = this.$route.name == 'qe-resposta-editar' ? true : false;

        if (this.editar) {
            if (this.$store.getters['auth/usuarioHasRole'](['Analista'])) {
                this.analiseDisable = true;
            }
        }

        getTipoAtoConcessoes().then(response => this.tipoAtoConcessaoSelect = response.data.data);
        getSituacoesRegime().then(response => this.situacoesRegimeSelect = response.data.data);

        // update and view
        if (this.$route.params.resposta) {
            getResposta(this.$route.params.resposta).then((response) => {
                this.resposta = response.data.data;
                this.questionario = response.data.data.questionario;
                this.form.id_questionario = response.data.data.id_questionario;
                this.form.ds_pergunta = response.data.data.pergunta.ds_pergunta;
                this.form.id_pergunta = response.data.data.pergunta.id_pergunta;

                this.form.ds_descricao = response.data.data.ds_descricao;
                this.form.ds_inciso = response.data.data.ds_inciso;

                this.form.ds_especificacao_cargo = response.data.data.ds_especificacao_cargo;
                this.form.vl_numero_vagas = response.data.data.vl_numero_vagas;
                this.form.vl_numero_servidores_nomeados = response.data.data.vl_numero_servidores_nomeados;
                this.form.ds_convenio_confaz = response.data.data.ds_convenio_confaz;

                if (response.data.data.vl_aliquota_anterior != null) {
                    this.form.vl_aliquota_anterior = response.data.data.vl_aliquota_anterior.replace('.', ',');
                }

                if (response.data.data.vl_aliquota_anterior != null) {
                    this.form.vl_aliquota_apos_alteracao = response.data.data.vl_aliquota_apos_alteracao.replace('.', ',');
                }

                this.form.ds_meio_publicacao = response.data.data.ds_meio_publicacao;
                this.form.ds_observacao_devolucao = response.data.data.ds_observacao_devolucao;
                this.form.id_tipo_ato_concessao = response.data.data.id_tipo_ato_concessao;

                this.form.ds_justificativa_despesa_irrelevante = response.data.data.ds_justificativa_despesa_irrelevante;

                // SEI
                this.form.ds_deliberacao_sei = response.data.data.ds_deliberacao_sei;
                this.form.nr_sei = response.data.data.nr_sei;

                this.despachosItens = response.data.data.despachos;

                if (response.data.data.dt_ato_concessao == null) {
                    this.form.dt_ato_concessao = moment().format("yyyy-MM-DD");
                } else {
                    this.form.dt_ato_concessao = moment(response.data.data.dt_ato_concessao).format("yyyy-MM-DD");
                }

                this.form.id_situacao_regime = response.data.data.id_situacao_regime;
                this.form.id_resposta_status = response.data.data.status.id_resposta_status;

                if (this.form.id_resposta_status == 1) {
                    this.analiseSectionDisable = true;
                }

                this.form.nm_resposta_status = response.data.data.status.nm_resposta_status;
                this.form.st_impacta_orcamento = response.data.data.st_impacta_orcamento;

                this.form.acrescimos_orcamentarios = response.data.data.acrescimos_orcamentarios;

                this.form.acrescimos_orcamentarios.forEach(element => {
                    this.totalAcrescimos += parseFloat(element.vl_acrescimo);
                    element.vl_acrescimo = element.vl_acrescimo.replace('.', ',');
                });

                response.data.data.questionario.respostas.forEach(resposta => {
                    // verifica se já tem resposta NAO além dessa
                    if (resposta.id_pergunta == response.data.data.pergunta.id_pergunta && resposta.st_resposta == false && resposta.id_resposta != this.$route.params.resposta) {
                        this.temRespostaNao = true;
                    }

                    // verifica a quantidade de respostas para esta pergunta
                    if (resposta.id_pergunta == response.data.data.pergunta.id_pergunta) {
                        this.contRespostas += 1;
                    }
                });

                // se tiver mais de uma resposta só pode ser SIM
                if (this.contRespostas > 1) {
                    this.form.st_resposta = true;
                    this.respostaStatusDisable = true;
                } else {
                    this.form.st_resposta = response.data.data.st_resposta;
                }

                this.anexos = response.data.data.anexos;

                if (this.anexos.length == 0) {
                    this.anexosEmpty = true;
                }

                // se
                // Analista
                if (this.$store.getters['auth/usuarioHasRole'](['Analista'])) {

                    // exibe a seção de Despacho
                    this.despachoSectionShow = true;
                }

                // se
                // Analista Federal
                // Resposta estiver "Validada"
                // Questionário "Encerrado"
                if (this.$store.getters['auth/usuarioHasRole'](['Analista'])
                    && this.form.id_resposta_status == 4
                    && this.questionario.id_questionario_status == 4
                    && this.$store.getters['auth/usuarioAutenticadoTipoAnalista'] == 'Federal') {

                    // libera o botão de exportar como PDF
                    this.imprimePDFEnable = true;
                }

                if (this.editar) {

                    // se
                    // Cadastrador
                    // Permissão de atualização
                    // Resposta estiver "Em Ajuste"
                    // Questionário "Em Análise & Ajuste"
                    if (this.$store.getters['auth/usuarioHasRole'](['Cadastrador'])
                        && this.$store.getters['auth/usuarioHasPermissao'](['RespostaAtualizar'])
                        && this.form.id_resposta_status == 3
                        && this.questionario.id_questionario_status == 3) {

                        this.respostaDevolvidaEnable = true;
                        this.respostaStatusDisable = false;
                    }

                    // verifica se tem despacho federal e estadual
                    if (this.$store.getters['auth/usuarioHasRole'](['Analista'])
                        && this.$store.getters['auth/usuarioAutenticadoTipoAnalista'] == 'Federal'){
                        if (response.data.data.despachos.find((d) => d.tp_despacho === 'E')) {
                            if (response.data.data.despachos.find((d) => d.tp_despacho === 'F')) {
                                this.devolverEnable = true;
                                if (this.form.id_resposta_status == 2) {
                                    this.validarEnable = true;
                                }
                            }
                        }
                    }

                    // se
                    // Cadastrador
                    // Permissão de atualização
                    // Resposta estiver "Em Análise"
                    // Questionário "Devolvido para Ajustes"
                    if (this.$store.getters['auth/usuarioHasRole'](['Cadastrador'])
                        && this.$store.getters['auth/usuarioHasPermissao'](['RespostaAtualizar'])
                        && this.form.id_resposta_status == 2
                        && this.questionario.id_questionario_status == 5) {

                        this.respostaDevolvidaEnable = true;
                    }
                }

                // se
                // Resposta estiver "Validada"
                // Questionário "Encerrado"
                if (this.form.id_resposta_status == 4
                    && this.questionario.id_questionario_status == 4) {
                    this.seiSectionEnable = true;
                }

                this.nrSeiChange();

                this.loaded = true;
            })
        } else {

            this.respostaStatusDisable = true;
            this.analiseSectionDisable = true;
            getPergunta(this.$route.params.pergunta).then((perguntaResponse) => {
                getQuestionario(this.$route.params.questionario).then((questionarioResponse) => {
                    this.form.id_pergunta = perguntaResponse.data.data.id_pergunta;
                    this.form.ds_pergunta = perguntaResponse.data.data.ds_pergunta;

                    this.form.id_questionario = this.$route.params.questionario;
                    this.form.id_resposta_status = 1;
                    this.form.st_impacta_orcamento = true;
                    this.form.st_resposta = true;

                    for (let index = 0; index < 9; index++) {
                        let acrescimo = {
                            dt_ano: moment(questionarioResponse.data.data.dt_mes_ano).add(index, 'Y'),
                            vl_acrescimo: 0
                        }
                        this.form.acrescimos_orcamentarios.push(acrescimo);
                    }

                    this.loaded = true;
                })
            })

        }
    },
    methods: {
        salvar() {

            if (this.temRespostaNao && !this.form.st_resposta) {
                this.$store.dispatch('alertas/show', {
                    tipo: 'Warning',
                    titulo: 'Não é possível salvar resposta.',
                    mensagem: 'Já existe uma resposta NÃO para esta pergunta. Somente uma resposta NÃO pode ser salva.',
                })

                this.$vuetify.goTo(0);
            } else {

                if (this.anexosEmpty && this.form.st_resposta) {
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Warning',
                        titulo: 'É necessário anexar ao menos um arquivo.',
                    })

                    this.$vuetify.goTo(0);
                } else {

                    if (this.totalAcrescimos == 0 && this.form.st_impacta_orcamento == 1) {
                        this.$store.dispatch('alertas/show', {
                            tipo: 'Warning',
                            titulo: 'O valor total dos acréscimos precisa ser maior que R$ 0,00.',
                        })
                        this.$vuetify.goTo(0);
                    } else {

                        if (this.$refs.form.validate()) {

                            if (this.form.id_pergunta == 8) {

                                if (isNaN(+this.form.vl_aliquota_anterior)) { // n eh float
                                    this.form.vl_aliquota_anterior = this.form.vl_aliquota_anterior.replace(',', '.');
                                }

                                this.form.vl_aliquota_anterior = parseFloat(this.form.vl_aliquota_anterior);

                                if (isNaN(+this.form.vl_aliquota_apos_alteracao)) { // n eh float
                                    this.form.vl_aliquota_apos_alteracao = this.form.vl_aliquota_apos_alteracao.replace(',', '.');
                                }

                                this.form.vl_aliquota_apos_alteracao = parseFloat(this.form.vl_aliquota_apos_alteracao);

                            }

                            this.acrescimosOrcamentariosChange();

                            let promise;

                            if (this.$route.params.resposta) {
                                promise = updateResposta(this.$route.params.resposta, this.form)
                            } else {
                                promise = createResposta(this.form)
                            }

                            promise.then(response => {
                                this.$store.dispatch('alertas/show', {
                                    tipo: 'Sucesso',
                                    titulo: response.data.message,
                                })

                                if (response.data.message == 'Resposta corrigida com sucesso.') {

                                    getQuestionario(this.$route.params.questionario).then((questionarioResponse) => {

                                        if (questionarioResponse.data.data.id_questionario_status == 2) {
                                            this.$store.dispatch('alertas/show', {
                                                tipo: 'Sucesso',
                                                titulo: 'Todas as respostas foram corrigidas, assim, o Questionário foi colocado Em Análise automaticamente.',
                                            })
                                            this.$router.push({ name: 'questionarios' });
                                        } else {
                                            this.goBack();
                                        }
                                    })

                                } else {
                                    this.goBack();
                                }

                            }).catch(error => this.handleError(error))
                        }
                    }
                }
            }
        },
        devolver() {

            if (this.$refs.devForm.validate()) {

                this.devForm.id_questionario = this.form.id_questionario;
                this.devForm.id_pergunta = this.form.id_pergunta;

                let promise = devolverResposta(this.$route.params.resposta, this.devForm);

                promise.then(response => {
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: response.data.message,
                    })

                    this.goBack();
                }).catch(error => this.handleError(error))

            }
        },
        validar() {

            let promise;

            promise = validaResposta(this.$route.params.resposta);

            promise.then(response => {

                // se for true é pq o questionário foi validado automaticamente (todas as respostas validadas)
                if (response.data.data) {
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: 'O Questionário foi encerado automaticamente, pois todas as respostas foram validadas.',
                    })

                    this.$router.push({ name: 'questionarios' });

                } else { // se false é pq apenas a resposta foi validada
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: response.data.message,
                    })

                    this.goBack();
                }

            }).catch(error => this.handleError(error))
        },
        handleError(error) {

            if(error.response.status == 422) {
                this.$store.dispatch('alertas/show', {
                    tipo: 'Erro',
                    titulo: 'Ocorreu uma falha no upload do arquivo de despacho.',
                    mensagem: 'O arquivo é de um tipo inválido ou excede o tamanho máximo permitido.',
                });
            } else {
                this.$store.dispatch('alertas/show', {
                    tipo: 'Erro',
                    titulo: error.response.data.error.message.title,
                    mensagem: error.response.data.error.message.msg,
                });
            }

            this.$vuetify.goTo(0);

            if (error.response.data.error.message.type == 403) {
                this.$router.push({name: 'home'});
            }
        },
        goBack() {
            switch (this.$route.name) {
                case 'qr-resposta-visualizar':
                    this.$router.push({ name: 'relatorio-questionario-respondido' });
                    break;
                case 'qv-resposta-visualizar':
                    this.$router.push({ name: 'questionario-visualizar', params: { questionario: this.$route.params.questionario } });
                    break;
                default:
                    this.$router.push({ name: 'questionario-editar', params: { questionario: this.$route.params.questionario } });
                    break;
            }
        },
        voltar() {
            this.goBack();
        },
        dateFormat(date) {
            if (!!date) {
                return moment(date).format("YYYY")
            }
            return null;
        },
        changeStResposta(){
            if(this.form.st_resposta == 1){
                this.form.ds_meio_publicacao = null;
                this.form.id_tipo_ato_concessao = null;
                this.form.dt_ato_concessao = null;
                this.form.id_situacao_regime = null;
                this.form.st_impacta_orcamento = null;
            }
        },
        acrescimosOrcamentariosChange() {
            this.totalAcrescimos = 0;
            this.form.acrescimos_orcamentarios.forEach(element => {

                if (isNaN(+element.vl_acrescimo)) { // n eh float
                    element.vl_acrescimo = element.vl_acrescimo.replace(',', '.');
                }

                element.vl_acrescimo = parseFloat(element.vl_acrescimo);
                this.totalAcrescimos += element.vl_acrescimo;
            });
        },
        respostaStatusChange(event) {

            console.log(event);
        },
        respostaAnexoEmit(anexos) {
            if (anexos.length == 0) {
                // this.anexosEmpty = true; // postergado
            } else {
                this.anexosEmpty = false;
                this.anexos = anexos;
            }
        },
        enableInciso() {
            if (this.form.id_situacao_regime == 2) {
                return true;
            }
            return false;
        },
        despachoEmit() {
            // verifica se já existe ao menos um despacho estadual e libera os botões de devolver e validar
            if (this.despachosItens.find((d) => d.tp_despacho === 'E')) {
                this.devolverEnable = true;
                this.validarEnable = true;
            }
        },
        stRespostaChange() {
            if (this.form.st_resposta) {
                this.form.st_impacta_orcamento = true;
            } else {
                this.form.st_impacta_orcamento = false;
            }
        },
        podeSalvar(){
            // se
            // Cadastrador
            // Permissão de atualização
            // Resposta estiver "Em Preenchimento" ou "Em Ajuste"
            // Questionário "Em Análise & Ajuste" ou "Em Preenchimento"
            if (!this.visualizar
                && this.$store.getters['auth/usuarioHasRole'](['Cadastrador'])
                && this.$store.getters['auth/usuarioHasPermissao'](['RespostaAtualizar'])
                && (this.form.id_resposta_status == 3 || this.form.id_resposta_status == 1 || this.form.id_resposta_status == 4)
                && (this.questionario.id_questionario_status == 3 || this.questionario.id_questionario_status == 1 || this.questionario.id_questionario_status == 5)) {

                return true;
            }

            // se
            // Analista
            // Permissão de atualização
            // Resposta estiver "Em Analise" ou "Em Analise & Ajuste"
            // Questionário diferente de "Aberto"
            if (!this.visualizar
                && this.$store.getters['auth/usuarioHasRole'](['Analista'])
                && this.$store.getters['auth/usuarioHasPermissao'](['RespostaAtualizar'])
                && (this.form.id_resposta_status == 2 || this.form.id_resposta_status == 4)
                && this.questionario.id_questionario_status != 1) {

                return true;
            }

            return false;
        },
        nrSeiChange(){
            if (this.form.nr_sei == null || this.form.nr_sei == '') {
                this.delibercaoSeiShow = false;
            } else {
                if (this.$store.getters['auth/usuarioHasRole'](['Analista'])) {
                    this.delibercaoSeiShow = true;
                }
            }
        },
        callPDF() {
            this.callPF = true;
        },
        situacaoRegimeChange() {

            if (this.form.id_situacao_regime == 3) {
                getUsoDespesaIrrelevante(this.form.id_questionario).then((response) => {

                    if (Object.entries(response.data.data).length != 0) {
                        this.justificativaDespesaIrrelevanteEnable = true;
                    } else {
                        this.justificativaDespesaIrrelevanteEnable = false;
                    }
                })
            }
            else {
                this.justificativaDespesaIrrelevanteEnable = false;
            }
        }
    },
}
</script>

<style>

.vue-js-switch.changed-font {
    font-size: 16px !important;
    margin: 10px;;
}

.v-dialog {
    max-width: 50%;
}

.v-dialog-card {
    padding: 10px;
}

</style>


