<template>
    <div >
        <VueHtml2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="false"
            :paginate-elements-by-height="1400"
            :filename="'questao' + resposta.pergunta.id_pergunta + '_' + '_' + dateFormatMesAnoPDFName(resposta.questionario.dt_mes_ano)"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="800px"
            pdf-content-height="1100px"
            ref="html2Pdf"
        >
            <section slot="pdf-content">
                <div class="pdf-content">

                    <table class="tb-header">
                        <tr>
                            <td>Orgão</td>
                            <td>{{ resposta.questionario.orgao.nm_orgao }}</td>
                        </tr>
                        <tr>
                            <td>Mês/Ano</td>
                            <td>{{ dateFormatMesAno(resposta.questionario.dt_mes_ano) }}</td>
                        </tr>
                        <tr>
                            <td>Questão&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                            <td>{{ resposta.pergunta.id_pergunta }} | {{ resposta.pergunta.ds_pergunta }}</td>
                        </tr>
                    </table>
                    
                    <br/>
                    <v-divider></v-divider>
                    <br/>
    
                    <table class="tb-content">
                        <th>
                            <td>Tipo de Ato de Concessão</td>
                        </th>
                        <th>
                            <td>Data do Ato de Concessão</td>
                        </th>
                        <th>
                            <td>Situação do Regime</td>                        
                        </th>
                        <th>
                            <td>Status</td>                        
                        </th>
                        <tr>
                            <td>{{ resposta.tipo_ato_concessao.nm_tipo_ato_concessao }}</td>
                            <td>{{ dateFormat(resposta.dt_ato_concessao) }}</td>
                            <td>{{ resposta.situacao_regime.nm_situacao_regime }}</td>
                            <td>{{ resposta.status.nm_resposta_status }}</td>
                        </tr>
                    </table>

                    <div v-if="resposta.situacao_regime.id_situacao_regime == 3 && resposta.ds_justificativa_despesa_irrelevante != null">
                        <br/>
                        <table class="tb-cargo">
                            <th>
                                <td>Justificativa da utilização de 'Despesa Irrelevante'</td>
                            </th>
                            <tr>
                                <td>{{ resposta.ds_justificativa_despesa_irrelevante }}</td>
                            </tr>
                        </table>
                    </div>
                    
                    <div v-if="resposta.situacao_regime.id_situacao_regime == 2">
                        <br/>
                        <table class="tb-cargo">
                            <th>
                                <td>A qual inciso o ressavaldo no plano de Recuperação Fiscal se refere?</td>
                            </th>
                            <tr>
                                <td>{{ resposta.ds_inciso }}</td>
                            </tr>
                        </table>
                    </div>

                    <br/>
    
                    <table class="tb-content">
                        <th>
                            <td>Meio de Publicação</td>
                        </th>
                        <th>
                            <td>Descrição</td>
                        </th>
                        <tr>
                            <td>{{ resposta.ds_meio_publicacao }}</td>
                            <td>{{ resposta.ds_descricao }}</td>
                        </tr>
                    </table>
    
                    <div v-if="resposta.pergunta.id_pergunta == 2 || resposta.pergunta.id_pergunta == 3">
                        <br/>
        
                        <table class="tb-cargo">
                            <th>
                                <td>Especificação do Cargo</td>
                            </th>
                            <th>
                                <td>Número de Vagas</td>
                            </th>
                            <tr>
                                <td>{{ resposta.ds_especificacao_cargo }}</td>
                                <td>{{ resposta.vl_numero_vagas }}</td>
                            </tr>
                        </table>
    
                    </div>
    
                    <div v-if="resposta.pergunta.id_pergunta == 4">
                        <br/>
        
                        <table class="tb-servidores">
                            <th>
                                <td>Número de Servidores Nomeados</td>
                            </th>
                            <tr>
                                <td>{{ resposta.vl_numero_servidores_nomeados }}</td>
                            </tr>
                        </table>
    
                    </div>
    
                    <div v-if="resposta.pergunta.id_pergunta == 7">
                        <br/>
        
                        <table class="tb-convenio">
                            <th>
                                <td>Convênio Confaz</td>
                            </th>
                            <tr>
                                <td>{{ resposta.ds_convenio_confaz }}</td>
                            </tr>
                        </table>
    
                    </div>
    
                    <div v-if="resposta.pergunta.id_pergunta == 8">
                        <br/>
        
                        <table class="tb-cargo">
                            <th>
                                <td>Alíquota Anterior</td>
                            </th>
                            <th>
                                <td>Alíquota Após Alteração</td>
                            </th>
                            <tr>
                                <td>{{ resposta.vl_aliquota_anterior }}</td>
                                <td>{{ resposta.vl_aliquota_apos_alteracao }}</td>
                            </tr>
                        </table>
    
                    </div>
    
                    <div v-if="resposta.st_impacta_orcamento">
                        <div class="div-subtitles">
                            <h3>Acréscimos Orçamentários</h3>
                        </div>
        
                        <table class="tb-acrescimos">
                            <th>
                                <td>Ano</td>
                            </th>
                            <th>
                                <td>Valor</td>
                            </th>
                            <tr v-for="acrescimo in resposta.acrescimos_orcamentarios">
                                <td>{{ dateFormatAno(acrescimo.dt_ano) }}</td>
                                <td>R$ {{ acrescimo.vl_acrescimo }}</td>
                            </tr>
                        </table>                    
                    </div>
    
                    <!-- ocultando despachos #103 
                        
                    <div class="div-subtitles">
                        <h3>Despachos</h3>
                    </div>
    
                    <table class="tb-despachos">
                        <tr>
                            <td>
                                <table>
                                    <th>
                                        <td>Estadual</td>
                                    </th>
                                    <tr v-for="despacho in despachosEstaduais">
                                        <td>
                                            {{ despacho.ds_despacho }}
                                            <v-divider></v-divider>
                                            {{ despacho.usuario.nm_usuario }} em {{ dateFormat(despacho.created_at) }}
                                        </td>
                                    </tr>
                                </table>
                            </td>
                            <td>
                                <table>
                                    <th>
                                        <td>Federal</td>
                                    </th>
                                    <tr v-for="despacho in despachosFederais">
                                        {{ despacho.ds_despacho }}
                                        <v-divider></v-divider>
                                        {{ despacho.usuario.nm_usuario }} em {{ dateFormat(despacho.created_at) }}
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                    -->
    
                    <div v-if="resposta.nr_sei != null">
                        <div class="div-subtitles">
                            <h3>Informações do SEI</h3>
                        </div>
        
                        <table class="tb-sei">
                            <tr>
                                <td>
                                    <table>
                                        <th>
                                            <td>Número do SEI</td>
                                        </th>
                                        <tr>
                                            <td>
                                                {{ resposta.nr_sei }}
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td>
                                    <table>
                                        <th>
                                            <td>Deliberação</td>
                                        </th>
                                        <tr>
                                            <td>
                                                {{ resposta.ds_deliberacao_sei }}
                                            </td>                                    
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </div>

                </div>

            </section>
        </VueHtml2pdf>
    </div>
</template>

<script>

import VueHtml2pdf from 'vue-html2pdf'
import moment from 'moment';

moment.locale('pt-br');

export default {
    name: "RespostaPDF",
    components: { VueHtml2pdf },    
    props: {
        resposta: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            despachosFederais: [],
            despachosEstaduais: [],
        }
    },
    created() {

        this.despachosEstaduais = this.resposta.despachos.filter(function( obj ) {
            return obj.tp_despacho !== 'F';
        });

        this.despachosFederais = this.resposta.despachos.filter(function( obj ) {
            return obj.tp_despacho !== 'E';
        });
    },
    mounted() {
        this.$refs.html2Pdf.generatePdf();
    },
    methods: {
        dateFormat(date) {
            if (!!date) {
                return moment(date.toString()).format('DD/MM/YYYY');
            }
            return null;
        },
        dateFormatMesAno(date) {
            if (!!date) {
                return moment(date.toString()).format('MMMM/YYYY');
            }
            return null;
        },
        dateFormatAno(date) {
            if (!!date) {
                return moment(date.toString()).format('YYYY');
            }
            return null;
        },
        dateFormatMesAnoPDFName(date) {
            if (!!date) {
                return moment(date.toString()).format('MM-YYYY');
            }
            return null;
        },
    },
}
</script>

<style>

.vue-js-switch.changed-font {
    font-size: 16px !important;
    margin: 10px;;
}

.v-dialog {
    max-width: 50%;
}

.v-dialog-card {
    padding: 10px;
}

.pdf-content {
    margin: auto;
    margin-top: 60px;
    width: 86%;  
}

table {
    margin: auto;
    width: 96%;  
}

.tb-header {
    margin-top: 2%;    
}

.tb-header td {
    padding: 1%;
    font-weight: bold;    
    margin-right: 5%;
    border-left: 1px solid black;
}

.tb-content {
    border: 1px solid black;
    padding: 1%;
    margin: auto;
    width: 96%;    
}

.div-subtitles {
    padding: 2%;
}

.tb-acrescimos {
    border: 1px solid black;
    padding: 1%;
    margin: auto;
    width: 96%;
}

.tb-acrescimos tr {
    border-bottom: 1px solid black;
}

.tb-despachos {
    border: 1px solid black;
    padding: 1%;
    margin: auto;
    width: 96%;
}

.tb-despachos tr {
    border-bottom: 1px solid black;
}

.tb-sei {
    border: 1px solid black;
    padding: 1%;
    margin: auto;
    width: 96%;
}

.tb-cargo {
    border: 1px solid black;
    padding: 1%;
    margin: auto;
    width: 96%;
}

.tb-servidores {
    border: 1px solid black;
    padding: 1%;
    margin: auto;
    width: 96%;
}

.tb-convenio {
    border: 1px solid black;
    padding: 1%;
    margin: auto;
    width: 96%;
}

</style>


