<template>
    <div>
        <v-form ref="form">
            <h1 class="text-h5 my-4">
                Cadastro de Conselho
            </h1>
            <v-sheet class="pa-4  mb-5" rounded outlined>
                <v-row v-if="!novo">
                    <v-spacer></v-spacer>
                    <toggle-button
                    class="changed-font"
                    :width="100"
                    :height="30"
                    :speed="480"
                    :color="{checked: '#79BD8F', unchecked: '#FF877B'}"
                    :labels="{checked: 'Ativo', unchecked: 'Inativo'}"
                    :sync="true"
                    v-model="form.st_status"
                    :disabled="visualizar"
                    />
                </v-row>
                <h2 class="subtitle-1 mb-3">Dados do Conselho</h2>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-text-field
                            label="Nome do do Conselho *"
                            outlined
                            dense
                            v-model="form.nm_conselho"
                            :rules="validate('required', 'nome do conselho')"
                            :disabled="visualizar"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field
                            label="E-mail do do Conselho *"
                            outlined
                            dense
                            v-model="form.ds_email"
                            :rules="validate('required|email', 'e-mail do conselho')"
                            :disabled="visualizar"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-text-field
                            label="Sigla *"
                            outlined
                            dense
                            v-model="form.sg_sigla"
                            :rules="validate('required', 'sigla do conselho')"
                            :disabled="visualizar"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" md="2">
                        <v-text-field
                            label="Telefone"
                            outlined
                            dense
                            v-model="form.nr_telefone"
                            v-mask="['(##) ####-####', '(##) #####-####']"
                            :disabled="visualizar"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="10">
                        <ConselhoSelect
                            label="Conselho Pai"
                            v-model="form.id_conselho_pai"
                            :disabled="visualizar"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-col cols="12" md="2">
                                <v-text-field
                                    label="Último ano no Regime de Recuperação Fiscal *"
                                    outlined
                                    dense
                                    v-model="form.ultimo_ano_rec_fiscal"
                                    :rules="[
                                        v => !!v || 'O campo é obrigatório',
                                        v => /^[0-9]+$/.test(v) || 'Deve ser um número',
                                        v => parseInt(v) >= new Date().getFullYear() || `O ano deve ser maior ou igual ao ano atual (${new Date().getFullYear()})`
                                    ]"
                                    :disabled="visualizar"
                                    type="number"
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </v-col>
                        </template>
                        <span>Último ano no Regime de Recuperação Fiscal</span>
                    </v-tooltip>
                </v-row>
            </v-sheet>
            <div class="d-flex">
                <v-spacer></v-spacer>
                <v-btn
                    class="mx-2"
                    color="error"
                    @click="voltar()"
                >
                    Voltar
                </v-btn>

                <v-btn
                    v-if="visualizar_btn_historico"
                    class="ml-2"
                    @click="historicoAcaoDialog = true"
                >
                    Histórico
                </v-btn>

                <HistoricoAcaoDialog
                    :entityId="entity_id"
                    :entityType="entity_type"
                    :dialog.sync="historicoAcaoDialog"
                />

                <v-btn v-if="!visualizar"
                    color="primary"
                    @click="salvar()"
                >
                    Salvar
                </v-btn>
            </div>
        </v-form>
    </div>
</template>

<script>

import { getConselho, createConselho, updateConselho } from "../../api/conselho";
import { validationMixin } from "../../mixins/validationMixin";
import ConselhoSelect from "../../components/inputs/ConselhoSelect.vue";
import { EntityTypes } from "../../constants/constants";
import HistoricoAcaoDialog from "../../components/components/HistoricoAcaoDialog";

export default {
    name: "Conselho",
    components: {HistoricoAcaoDialog, ConselhoSelect },
    props: {
        conselho: {
            type: Object,
            default() {
                return {id_conselho: null}
            }
        },
    },
    mixins: [validationMixin],
    data() {
        return {
            form: {
                nm_conselho: null,
                ds_email: null,
                sg_sigla: null,
                nr_telefone: null,
                id_conselho_pai: null,
                st_status: true,
                ultimo_ano_rec_fiscal: null,
            },
            visualizar: null,
            visualizar_btn_historico: null,
            novo: null,
            fields: {
                id_conselho_pai: [],
            },
            error: {},
            historicoAcaoDialog: false,
            entity_id: null,
            entity_type: EntityTypes.CONSELHO
        }
    },
    created() {

        this.visualizar = this.$route.name == 'conselho-visualizar' ? true : false;
        this.visualizar_btn_historico = this.$route.name == 'conselho-visualizar' ? true : false;
        this.entity_id = this.$route.name == 'conselho-novo' ? "0" : this.$route.params.conselho;
        this.novo = this.$route.name == 'conselho-novo' ? true : false;

        // update
        if (this.$route.params.conselho) {
            getConselho(this.$route.params.conselho).then((response) => {
                this.form.nm_conselho = response.data.data.nm_conselho;
                this.form.ds_email = response.data.data.ds_email;
                this.form.sg_sigla = response.data.data.sg_sigla;
                this.form.nr_telefone = response.data.data.nr_telefone;
                this.form.id_conselho_pai = response.data.data.id_conselho_pai;
                this.form.st_status = response.data.data.st_status;
                this.form.ultimo_ano_rec_fiscal = response.data.data.ultimo_ano_rec_fiscal;
            })
        }
        this.atualizarAno();
    },
    methods: {
        salvar() {
            if (this.$refs.form.validate()) {
                this.form.ultimo_ano_rec_fiscal = Number(this.form.ultimo_ano_rec_fiscal);
                let promise;
                if (this.$route.params.conselho) {
                    promise = updateConselho(this.$route.params.conselho, this.form)
                } else {
                    promise = createConselho(this.form)
                }

                promise.then(response => {
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: response.data.message,
                    })
                    this.$vuetify.goTo(0)
                    this.$router.push({name: 'conselhos'})
                })
                    .catch(error => this.handleError(error))
            }
        },
        voltar() {
            this.$router.push({name: 'conselhos'});
        },
        handleError(error) {
            if (error.response.status == 422) {
                if (error.response.data.errors.ultimo_ano_rec_fiscal) {
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Erro',
                        titulo: 'Ocorreu uma falha ao atualizar o Conselho.',
                        mensagem: 'O ano informado deve ser maior ou igual ao ano atual.',
                    });
                }
            } else {
                this.$store.dispatch('alertas/show', {
                    tipo: 'Erro',
                    titulo: error.response.data.error.message.title,
                    mensagem: error.response.data.error.message.msg,
                });
            }

            this.$vuetify.goTo(0);
        },
        atualizarAno() {
            if (this.$route.name == 'conselho-novo') {
                this.form.ultimo_ano_rec_fiscal = new Date().getFullYear();
            }
        }
    },
    watch: {
        novo(newVal) {
            // Atualiza o valor de form.ultimo_ano_rec_fiscal quando this.novo muda
            this.atualizarAno();
        }
    }
}
</script>

<style>

.vue-js-switch.changed-font {
    font-size: 16px !important;
    margin: 10px;;
}

</style>


