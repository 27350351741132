import api from './index';

const getSituacoesRegime = () => api.get('situacao-regime/situacoes-regime');
const createSituacaoRegime = (dados) => api.post('situacao-regime', dados);
const getSituacaoRegime = (id_situacao_regime) => api.get(`situacao-regime/${id_situacao_regime}`);
const updateSituacaoRegime = (id_situacao_regime, dados) => api.put(`situacao-regime/${id_situacao_regime}`, dados);
const deletarSituacaoRegime = (id_situacao_regime) => api.delete(`situacao-regime/${id_situacao_regime}`);

const getSituacaoPaginate = async (page, itemsPerPage, filtrosAplicados = []) => await api.get('situacao-regime/situacoes-regime-paginate', {
    params: {
        page: page,
        limit: itemsPerPage,
        filtros: filtrosAplicados,
    },
    showLoader: true
});

export {    
    getSituacoesRegime,
    createSituacaoRegime,
    getSituacaoRegime,
    updateSituacaoRegime,
    deletarSituacaoRegime,
    getSituacaoPaginate
}
