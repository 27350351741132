<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="items"
            class="elevation-1"
            :items-per-page="pagination.itemsPerPage"
            :footer-props="footerProps"
            :options.sync="pagination"
            :server-items-length="pagination.total"
        >
            <template v-if="$store.getters['auth/usuarioHasPermissao'](['PerguntaQuestionarioEditar'])"
                      v-slot:item.actions="{ item }">

                <v-btn
                    fab
                    color="success"
                    x-small
                    elevation="0"
                    :to="{name: 'pergunta-questionario-editar', params: {pergunta: item.id_pergunta}}"
                >
                    <v-icon>
                        mdi-pencil
                    </v-icon>
                </v-btn>
            </template>
        </v-data-table>
    </div>
</template>

<script>

    import {getPerguntaPaginate} from "../../api/pergunta";
    import {dataTableMixin} from "../../mixins/dataTableMixin";
    
    export default {
        name: 'PerguntaQuestionarioListar',
        components: {},
        mixins: [dataTableMixin],
        data() {
            return {
                headers: [
                    {text: 'Descrição pergunta', sortable: false, value: 'ds_pergunta'},
                    {text: 'Ações', value: 'actions', sortable: false, width: '100px', align: 'center'}
                ],
                items: [],
                column_id: 'id_pergunta',
            }
        },
        created() {
            getPerguntaPaginate(this.pagination.page, this.pagination.itemsPerPage)
                .then(response => {
                    this.items = response.data.data.data;
                    this.pagination.total = response.data.data.total;
                    if (this.pagination.total < this.pagination.itemsPerPage) {
                        this.pagination.page = 1;
                    }
                });
        },
        methods: {

        }
    }
</script>