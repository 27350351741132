<template>
    <div>
        <v-card class="mb-9">
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-select
                            label="Conselho"
                            outlined
                            :disabled="disableConselho"
                            v-model="filtro.id_conselho"
                            dense
                            clearable
                            :items="conselhosSelect"
                            item-value="id_conselho"
                            item-text="nm_conselho"
                            hide-details="false"
                            @change="(event) => conselhoChange(event)"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-select
                            label="Orgão"
                            outlined
                            :disabled="disableOrgao"
                            v-model="filtro.id_orgao"
                            dense
                            clearable
                            :items="orgaosSelect"
                            item-value="id_orgao"
                            item-text="nm_orgao"
                            hide-details="false"
                        />
                    </v-col>
                </v-row>
                <v-row v-if="!this.$store.getters['auth/usuarioHasRole'](['Cadastrador', 'Consultor'])">
                    <v-col cols="12" md="8">
                        <v-text-field
                            label="Busca Aproximada"
                            outlined
                            dense
                            @keyup.enter="filtrar()"
                            v-model="filtro.nm_orgao"
                            hide-details="false"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-select
                                label="Status"
                                outlined
                                dense
                                clearable
                                v-model="filtro.id_questionario_avulso_status"
                                :items="[
                                    {value: 1, text: 'Em Preenchimento'},
                                    {value: 2, text: 'Enviado'},
                                    {value: 3, text: 'Respondido'},
                                    {value: 4, text: 'Encerrado'},
                                ]"
                            ></v-select>
                        </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    outlined
                    @click="limparFiltro()"
                >
                    Limpar
                </v-btn>
                <v-btn
                    color="primary"
                    @click="filtrar()"
                >
                    <v-icon left>
                        mdi-filter
                    </v-icon>
                    Filtrar
                </v-btn>
            </v-card-actions>
        </v-card>

        <div class="d-flex my-3">
            <v-spacer></v-spacer>
            <v-btn v-if="$store.getters['auth/usuarioHasRole'](['Analista'])"
                color="primary"
                :to="{name: 'questionario-avaulso-novo'}"
            >
                Novo Questionario
            </v-btn>
        </div>

        <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page="pagination.itemsPerPage"
            :footer-props="footerProps"
            :options.sync="pagination"
            :server-items-length="pagination.total"
            class="elevation-1"
        >

            <template v-slot:item.dt_referencia="{ item }">
                <span>{{ dateFormat(item.dt_referencia) }}</span>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-tooltip v-if="$store.getters['auth/usuarioHasPermissao'](['QuestionárioAvulsoVisualizar'])" top>
                    <template v-slot:activator="{ on: tooltip}">
                        <v-btn
                            fab
                            color="cyan"
                            x-small
                            elevation="0"
                            :to="{name: 'questionario-avulso-visualizar', params: {questionario: item.id_questionario_avulso}}"
                            v-on="{ ...tooltip}"
                        >
                            <v-icon>
                                mdi-magnify
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Visualizar</span>
                </v-tooltip>
                <v-tooltip v-if="showEditPreencher(item)" top>
                    <template v-slot:activator="{ on: tooltip}">
                        <v-btn
                            fab
                            color="success"
                            x-small
                            elevation="0"
                            :to="{name: 'questionario-avulso-editar', params: {questionario: item.id_questionario_avulso}}"
                            v-on="{ ...tooltip}"
                        >
                            <v-icon>
                                mdi-pencil
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Preencher Questionário Avulso</span>
                </v-tooltip>
                <v-tooltip v-if="showResponder(item)" top>
                    <template v-slot:activator="{ on: tooltip}">
                        <v-btn
                            fab
                            color="success"
                            x-small
                            elevation="0"
                            :to="{name: 'questionario-avulso-responder', params: {questionario: item.id_questionario_avulso}}"
                            v-on="{ ...tooltip}"
                        >
                            <v-icon>
                                mdi-pencil
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Responder Questionário Avulso</span>
                </v-tooltip>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { getQuestionariosAvulsoPaginate } from "../../api/questionario_avulso";
import { getConselhos } from "../../api/conselho";
import { getOrgaosByConselho } from "../../api/orgao";
import { dataTableMixin } from "../../mixins/dataTableMixin";
import _ from "lodash";
import { filterNormalizeMixin } from "../../mixins/filterNormalizeMixin";
import DeleteAction from "../../components/inputs/DeleteAction.vue";
import { getMe } from "../../api/usuario";

import moment from 'moment';
import { defaultsDeep } from "lodash";
import FilterService from "../../filters/filterService";
moment.locale('pt-br');

export default {
    name: "QuestionarioAvulsoListar",
    components: { DeleteAction },
    mixins: [dataTableMixin, filterNormalizeMixin],
    data() {
        return {
            filtro: {
                id_orgao: null,
                id_conselho: null,
                id_questionario_avulso_status: null,
                nm_orgao: null,
            },
            editIcon: null,
            editTooltip: null,
            filtro_id_conselho: null,
            conselhosSelect: [],
            orgaosSelect: [],
            loading: false,
            user: null,
            disableConselho: false,
            disableOrgao: false,
            column_id: 'id_questionario_avulso',
            headers: [
                { text: 'ID', value: 'id_questionario_avulso', sortable: false },
                { text: 'Orgão', value: 'orgao.nm_orgao', sortable: false },
                { text: 'Data de referencia', value: 'dt_referencia', sortable: false },
                { text: 'Status', value: 'status.nm_questionario_avulso_status', sortable: false },
                { text: 'Ações', value: 'actions', sortable: false },
            ]
        }
    },
    created() {
        getConselhos().then((conselho_response) => {

            this.conselhosSelect = conselho_response.data.data;

            if (!this.$store.getters['auth/usuarioHasRole'](['Administrador'])) { // se não for administrador

                getMe().then(user_response => {
                    this.user = user_response.data;

                    if (this.$store.getters['auth/usuarioHasRole'](['Analista'])) {

                        if (this.user.conselho.id_conselho_pai != null) { // Analista Estadual
                            this.disableConselho = true;
                            this.filtro.id_conselho = this.user.id_conselho;

                            getOrgaosByConselho(this.user.id_conselho).then((orgao_response) => {
                                this.orgaosSelect = orgao_response.data.data;
                            });

                            this.filtrar();
                        } else { // Analista Federal
                            this.filtrar();
                        }
                    } else if (this.$store.getters['auth/usuarioHasRole'](['Cadastrador'])) {

                        getOrgaosByConselho(this.user.id_conselho).then((orgao_response) => {

                            this.disableOrgao = true;
                            this.disableConselho = true;
                            this.orgaosSelect = orgao_response.data.data;
                            this.filtro.id_conselho = this.user.id_conselho;

                            orgao_response.data.data.forEach(orgao => {
                                if (orgao.id_orgao == user_response.data.id_orgao) {
                                    this.filtro.id_orgao = orgao.id_orgao;
                                }
                            });

                            this.filtrar();
                        });
                    }
                });
            } else {
                this.filtrar();
            }

        });
    },
    mounted() {
        const savedFilters = FilterService.loadFilters(this.$route.name);
        if (savedFilters) {
            this.filtro = savedFilters;
        }
    },
    beforeRouteLeave(to, from, next) {
        FilterService.saveFilters(from.name, this.filtro);
        next();
    },
    methods: {
        conselhoChange(event) {
            if (this.filtro.id_conselho != null) {
                getOrgaosByConselho(event).then(response => this.orgaosSelect = response.data.data);
                this.filtro.id_orgao = null;
            } else {
                this.orgaosSelect = [];
            }
        },
        limparFiltro() {
            this.filtro = {
                id_conselho: null,
                id_orgao: null,
                id_status: null,
                nm_orgao: null,
            };

            FilterService.clearFilters(this.$route.name);
            this.filtrar();
        },
        filtrar() {
            getQuestionariosAvulsoPaginate(this.pagination.page, this.pagination.itemsPerPage, this.filtro)
                .then(response => {
                    this.items = response.data.data.data;
                    this.pagination.total = response.data.data.data.total;
                    if (this.pagination.total < this.pagination.itemsPerPage) {
                        this.pagination.page = 1;
                    }
                });
        },

        showEditPreencher(item) {
            // se for analista e o questionário estiver em preenchimento e tiver permissão de atualização
            return this.$store.getters['auth/usuarioHasRole'](['Analista']) && this.$store.getters['auth/usuarioHasPermissao'](['QuestionárioAvulsoAtualizar']) && item.status.nm_questionario_avulso_status == 'Em Preenchimento';
        },

        showResponder(item) {
            // se for cadastrador e o questionário estiver enviado e tiver permissão de responder
            return this.$store.getters['auth/usuarioHasRole'](['Cadastrador']) && this.$store.getters['auth/usuarioHasPermissao'](['QuestionárioAvulsoResponder']) && item.status.nm_questionario_avulso_status == 'Enviado';
        },

        handleError(error) {

            this.$store.dispatch('alertas/show', {
                tipo: 'Erro',
                titulo: error.response.data.error.message.title,
                mensagem: error.response.data.error.message.msg,
            });
            this.$vuetify.goTo(0);

            if (error.response.data.error.message.type == 403) {
                this.$router.push({name: 'home'});
            }
        },
        dateFormat(date) {
            if (!!date) {
                return moment(date).format("DD/MM/YYYY")
            }
            return null;
        },
    }
}
</script>
