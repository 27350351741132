<template>
    <div>
        <v-card class="mb-9">
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            label="Nome"
                            outlined
                            dense
                            @keyup.enter="filtrar()"
                            v-model="filtro.nm_comunicacao"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    outlined
                    @click="limparFiltro()"
                >
                    Limpar
                </v-btn>
                <v-btn
                    color="primary"
                    @click="filtrar()"
                >
                    <v-icon left>
                        mdi-filter
                    </v-icon>
                    Filtrar
                </v-btn>
            </v-card-actions>
        </v-card>

        <div class="d-flex my-3">
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                :to="{name: 'comunicacao-grupo'}"
            >
                Email em grupo
            </v-btn>
        </div>

        <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page="pagination.itemsPerPage"
            :footer-props="footerProps"
            :options.sync="pagination"
            @update:options="filtrar()"
            :server-items-length="pagination.total"
            class="elevation-1"
        >

            <template v-slot:item.st_status="{ item }">
                <v-chip
                    v-if="item.st_status"
                    small
                    outlined
                    color="primary"
                >
                    Ativo
                </v-chip>
                <v-chip
                    v-else
                    small
                    outlined
                    color="error"
                >
                    Inativo
                </v-chip>
            </template>
            <!-- <template v-slot:item.actions="{ item }">
                <v-tooltip v-if="$store.getters['auth/usuarioHasPermissao'](['ComunicaçãoAtualizar'])" top>
                    <template v-slot:activator="{ on: tooltip}">
                        <v-btn
                            fab
                            color="success"
                            x-small
                            elevation="0"
                            @click="activateComms(item.id_comunicacao)"
                            v-on="{ ...tooltip}"
                        >
                            <v-icon>
                                mdi-pencil
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Editar</span>
                </v-tooltip>

            </template> -->
        </v-data-table>
    </div>
</template>

<script>
import { getComunicacoesPaginate, activateComunicacao } from "../../api/comunicacao";
import { dataTableMixin } from "../../mixins/dataTableMixin";
import _ from "lodash";
import { filterNormalizeMixin } from "../../mixins/filterNormalizeMixin";
import FilterService from "../../filters/filterService";

export default {
    name: "ComunicacaoListar",
    components: { },
    mixins: [dataTableMixin, filterNormalizeMixin],
    data() {
        return {
            filtro: {
                nm_comunicacao: null,
            },
            loading: false,
            column_id: 'id_comunicacao',
            headers: [
                { text: 'ID', value: 'id_comunicacao', sortable: false },
                { text: 'Nome', value: 'nm_comunicacao', sortable: false },
                { text: 'Descrição', value: 'ds_descricao', sortable: false },
                { text: 'Tipo', value: 'tp_comunicacao', sortable: false },
                // { text: 'Ações', value: 'actions', sortable: false },
            ]
        }
    },
    methods: {
        limparFiltro() {
            this.filtro = {
                nm_comunicacao: null,
            };

            FilterService.clearFilters(this.$route.name);
            this.filtrar()
        },
        filtrar() {
            getComunicacoesPaginate(this.pagination.page, this.pagination.itemsPerPage, this.filtro)
                .then(response => {
                    this.items = response.data.data.data;
                    this.pagination.total = response.data.data.data.total;
                    if (this.pagination.total < this.pagination.itemsPerPage) {
                        this.pagination.page = 1;
                    }
                });
        },
        handleError(error) {

            this.$store.dispatch('alertas/show', {
                tipo: 'Erro',
                titulo: error.response.data.error.message.title,
                mensagem: error.response.data.error.message.msg,
            });
            this.$vuetify.goTo(0);

            if (error.response.data.error.message.type == 403) {
                this.$router.push({name: 'home'});
            }
        },
        activateComms(comunicacao_id) {

            let promise;
            promise = activateComunicacao(comunicacao_id);

            promise.then((response) => {
                this.$store.dispatch('alertas/show', {
                    tipo: 'Sucesso',
                    titulo: response.data.success.message,
                });
                this.$vuetify.goTo(0);
            })
            .catch(error => this.handleError(error))
        }

    },
    mounted() {
        const savedFilters = FilterService.loadFilters(this.$route.name);
        if (savedFilters) {
            this.filtro = savedFilters;
        }
    },
    beforeRouteLeave(to, from, next) {
        FilterService.saveFilters(from.name, this.filtro);
        next();
    },
}
</script>
