import api from './index';

const getComunicacoes = () => api.get('comunicacao/comunicacoes');
const getComunicacao = (id_comunicacao) => api.get(`comunicacao/${id_comunicacao}`);
const createComunicacao = (dados) => api.post('comunicacao', dados);
const updateComunicacao = (id_comunicacao, dados) => api.put(`comunicacao/${id_comunicacao}`, dados);
const deleteComunicacao = (id_comunicacao) => api.delete(`comunicacao/${id_comunicacao}`);
const activateComunicacao = (id_comunicacao) => api.post(`comunicacao/activate/${id_comunicacao}`);
const enviarEmailGrupo = (dados) => api.put('comunicacao/emailGrupo', dados);

const getComunicacoesPaginate = async (page, itemsPerPage, filtrosAplicados = []) => await api.get('comunicacao/comunicacoes-paginate', {
    params: {
        page: page,
        limit: itemsPerPage,
        filtros: filtrosAplicados,
    },
    showLoader: false
});

export {    
    getComunicacoes,
    getComunicacoesPaginate,
    activateComunicacao,
    getComunicacao,
    createComunicacao,
    updateComunicacao,
    deleteComunicacao,
    enviarEmailGrupo
}
