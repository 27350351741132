import api from './index';

const getRelatorioQualitativoPaginate = async (page, itemsPerPage, filtrosAplicados = []) => await api.get('relatorio-qualitativo/relatorio-qualitativo-paginate', {
    params: {
        page: page,
        limit: itemsPerPage,
        filtros: filtrosAplicados,
    },
    showLoader: true
});
const getValorTotalRelatorioQualitativo = (filtros) => api.post('relatorio-qualitativo/getValorTotalRelatorioQualitativo', filtros);
const extracaoRelatorioQualitativo = (filtrosAplicados = []) => api.get('relatorio-qualitativo/extracaoRelatorioQualitativo', { params: {filtros: filtrosAplicados}, responseType: 'blob' } );

export {    
    getRelatorioQualitativoPaginate,
    getValorTotalRelatorioQualitativo,
    extracaoRelatorioQualitativo
}
