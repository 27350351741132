import api from './index';

const getMe = () => api.get('usuario/me');

const getUsuarios = () => api.get('usuario/usuarios');
const getUsuario = (id_usuario) => api.get(`usuario/${id_usuario}`);
const getUsuarioByCpf = (cpf) => api.get(`usuario/cpf/${cpf}`);

const createUsuario = (dados) => api.post('usuario', dados, {
    headers: {
        "Content-Type": "multipart/form-data",
    },
    maxContentLength: 11000,
    maxBodyLength: 11000,
});

const updateUsuarioOrgao = (id_orgao) => api.post(`usuario/usuario-orgao/${id_orgao}`, {
    showLoader: true
});

const updateUsuario = (id_usuario, dados) => api.post(`usuario/${id_usuario}`, dados, {
    headers: {
        "Content-Type": "multipart/form-data",
    },
    maxContentLength: 11000,
    maxBodyLength: 11000,
});

const deleteUsuario = (id_usuario) => api.delete(`usuario/${id_usuario}`);

const getUsuariosPaginate = async (page, itemsPerPage, filtrosAplicados = []) => await api.get('usuario/usuarios-paginate', {
    params: {
        page: page,
        limit: itemsPerPage,
        filtros: filtrosAplicados,
    },
    showLoader: true
});
const getUsuariosWithOutPaginate = async (filtrosAplicados = []) => await api.get('usuario/usuarios-without-paginate', {
    params: {
        filtros: filtrosAplicados,
    },
    showLoader: true
});

const getUsuarioDespachoDownload = (id_usuario) => api.get('usuario/' + id_usuario + '/despacho', {responseType: 'blob'})

const ativarInativarUsuario = (id_usuario, st_Status) => api.put(`usuario/ativar-inativar/${id_usuario}`, st_Status);

export {
    getMe,
    getUsuarios,
    getUsuariosPaginate,
    getUsuario,
    createUsuario,
    updateUsuarioOrgao,
    updateUsuario,
    deleteUsuario,
    getUsuarioDespachoDownload,
    getUsuarioByCpf,
    getUsuariosWithOutPaginate,
    ativarInativarUsuario
}
