<template>
    <v-select
        :label="label"
        outlined
        dense
        :disabled="disabled"
        clearable
        :value="value"        
        @input="$emit('input', $event)"
        :rules="rules"
        :items="orgaos"
        item-value="id_orgao"
        item-text="nm_orgao"
    />
</template>

<script>
import { getOrgaos } from "../../api/orgao";

export default {
    name: "OrgaoSelect",
    props: {
        label: {
            type: String,
            default: 'Orgao'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        value: {
            type: Number|Array,
        },
        multiple: {
            type: Boolean,
            default: false
        },
        rules: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            orgaos: [],
        }
    },
    created() {
        getOrgaos().then(response => this.orgaos = response.data.data);        
    }
}
</script>
