<template>
    <v-dialog v-model="internalDialog" class="v-dialog">
        <div class="div-historico">
            <v-data-table
                :headers="historicoAcaoHeader"
                :items="historicoAcao"
                class="elevation-1"
                :hide-default-footer="true"
                :items-per-page="-1"
                :sort-desc="true"
            >
            </v-data-table>
        </div>
    </v-dialog>
</template>

<script>
import { getHistoricoAcaoByEntity } from "../../api/historico_acao";

export default {
    name: "HistoricoAcaoDialog",
    props: {
        entityId: {
            type: [Number, String],
            required: true,
        },
        entityType: {
            type: String,
            required: true,
        },
        dialog: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            historicoAcao: [],
            historicoAcaoHeader: [
                { text: 'Data', value: 'created_at', sortable: false },
                { text: 'Usuário', value: 'user_action', sortable: false },
                { text: 'Ação', value: 'action_description', sortable: false },
            ],
            internalDialog: this.dialog,
        };
    },
    watch: {
        dialog(val) {
            this.internalDialog = val;
            if (val) {
                this.fetchHistorico();
            }
        },
        internalDialog(val) {
            this.$emit('update:dialog', val);
        }
    },
    methods: {
        fetchHistorico() {
            getHistoricoAcaoByEntity({ id_usuario: this.entityId, entity: this.entityType }).then((response) => {
                this.historicoAcao = response.data.data;
            });
        },
    },
};
</script>

<style>
.v-dialog {
    max-width: 50%;
}

.div-historico {
    max-height: 800px;
    overflow-y: auto;
}
</style>
