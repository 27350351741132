import Vue from 'vue';
import VueRouter from 'vue-router';
import store from './store';

Vue.use(VueRouter);

import Home from './components/app/Home.vue';
import ConselhosListar from './views/Conselho/ConselhosListar.vue'
import QuestionarioListar from './views/Questionario/QuestionarioListar.vue'
import ConfiguracaoListar from './views/Configuracao/ConfiguracaoListar.vue'
import ComunicacaoListar from './views/Comunicacao/ComunicacaoListar.vue'
import UsuarioListar from './views/Usuario/UsuarioListar.vue'
import PerfilListar from './views/Perfil/PerfilListar.vue'
import OrgaosListar from './views/Orgao/OrgaoListar.vue'
import Conselho from "./views/Conselho/Conselho.vue";
import Configuracao from "./views/Configuracao/Configuracao.vue";
import Questionario from "./views/Questionario/Questionario.vue";
import Resposta from "./views/Resposta/Resposta.vue";
import Orgao from "./views/Orgao/Orgao.vue";
import Usuario from "./views/Usuario/Usuario.vue";
import Perfil from "./views/Perfil/Perfil.vue";
import Valida from './components/auth/Valida.vue';
import Logout from './components/auth/Logout.vue';
import ComunicacaoGrupo from './views/Comunicacao/ComunicacaoGrupo.vue';
import TipoAtoListar from './views/TipoAto/TipoAtoListar.vue';
import TipoAto from "./views/TipoAto/TipoAto.vue";
import SituacaoRegimeListar from "./views/SituacaoRegime/SituacaoRegimeListar.vue";
import SituacaoRegime from "./views/SituacaoRegime/SituacaoRegime.vue";
import RelatorioQuestionario from "./views/Relatorios/RelatorioQuestionario.vue";
import QuestionarioAvulsoListar from './views/QuestionarioAvulso/QuestionarioAvulsoListar.vue'
import QuestionarioAvulso from "./views/QuestionarioAvulso/QuestionarioAvulso.vue";
import AvisosListar from "./views/Avisos/CaixaAvisosListar.vue";
import Avisos from "./views/Avisos/CaixaAvisos.vue";
import PerguntaQualitativaListar from "./views/PerguntaQualitativa/PerguntaQualitativaListar.vue";
import PerguntaQualitativa from "./views/PerguntaQualitativa/PerguntaQualitativa.vue";
import RespostaQualitativa from "./views/RespostaQualitativa/RespostaQualitativa.vue";
import RelatorioQuestionarioRespondido from "./views/Relatorios/RelatorioQuestionarioRespondido.vue";
import RelatorioQualitativo from "./views/Relatorios/RelatorioQualitativo.vue";
import AlertaListar from "./views/Alerta/AlertaListar.vue";
import Alerta from "./views/Alerta/Alerta.vue";
import PerguntaQuestionarioListar from "./views/PerguntaQuestionario/PerguntaQuestionarioListar.vue";
import PerguntaQuestionario from "./views/PerguntaQuestionario/PerguntaQuestionario.vue";

let routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            requiresAuth: false,
            roles: ['Administrador', 'Analista', 'Cadastrador', 'Consultor'],
            menu: {
                show: true,
                titulo: 'Página Inicial'
            },
            breadcrumb: {
                show: false,
            }
        }
    },
    {
        path: '/logout',
        name: 'logout',
        component: Logout,
        meta: {
            requiresAuth: false,
            menu: {
                show: false,
                titulo: 'Página Inicial'
            },
            breadcrumb: {
                show: false,
            }
        }
    },
    {
        path: '/gerenciar',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        meta: {
            requiresAuth: true,
            roles: ['Administrador', 'Analista'],
            menu: {
                show: true,
                titulo: 'Gerenciar',
            },
            breadcrumb: {
                // show: false,
                // titulo: 'Listar Usuários'
            }
        },
        children: [
            {
                path: '/questionarios',
                name: 'questionarios',
                submenu: true,
                component: QuestionarioListar,
                meta: {
                    requiresAuth: true,
                    permissoes: ['QuestionárioListar'],
                    roles: ['Administrador', 'Analista', 'Cadastrador', 'Consultor'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Questionários'
                    }
                }
            },
            {
                path: '/questionarios/visualizar/:questionario',
                name: 'questionario-visualizar',
                submenu: false,
                component: Questionario,
                meta: {
                    requiresAuth: true,
                    permissoes: ['QuestionárioVisualizar'],
                    roles: ['Administrador', 'Analista', 'Cadastrador', 'Consultor'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Cadastro de Questionário'
                    }
                },
            },
            {
                path: '/questionarios/editar/:questionario',
                name: 'questionario-editar',
                submenu: false,
                component: Questionario,
                meta: {
                    requiresAuth: true,
                    permissoes: ['QuestionárioAtualizar'],
                    roles: ['Analista', 'Cadastrador'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Cadastro de Questionário'
                    }
                },
            },
            {
                path: '/questionarios/novo/:questionario?',
                name: 'questionario-novo',
                submenu: false,
                component: Questionario,
                meta: {
                    requiresAuth: true,
                    permissoes: ['QuestionárioCriar'],
                    roles: ['Cadastrador'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Cadastro de Questionário'
                    }
                }
            },
            {
                path: '/questionarios/visualizar/:questionario/pergunta/:pergunta/resposta/visualizar/:resposta',
                name: 'qv-resposta-visualizar',
                submenu: false,
                component: Resposta,
                meta: {
                    requiresAuth: true,
                    permissoes: ['RespostaVisualizar'],
                    roles: ['Administrador', 'Analista', 'Cadastrador', 'Consultor'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Cadastro de Resposta'
                    }
                },
            },
            {
                path: '/questionarios/visualizar/:questionario/pergunta/:pergunta/resposta/visualizar/:resposta',
                name: 'qr-resposta-visualizar',
                submenu: false,
                component: Resposta,
                meta: {
                    requiresAuth: true,
                    permissoes: ['RespostaVisualizar'],
                    roles: ['Administrador', 'Analista', 'Cadastrador', 'Consultor'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Cadastro de Resposta'
                    }
                },
            },
            {
                path: '/questionarios/editar/:questionario/pergunta/:pergunta/resposta/visualizar/:resposta',
                name: 'qe-resposta-visualizar',
                submenu: false,
                component: Resposta,
                meta: {
                    requiresAuth: true,
                    permissoes: ['RespostaVisualizar'],
                    roles: ['Administrador', 'Analista', 'Cadastrador'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Cadastro de Resposta'
                    }
                },
            },
            {
                path: '/questionarios/editar/:questionario/pergunta/:pergunta/resposta/editar/:resposta',
                name: 'qe-resposta-editar',
                submenu: false,
                component: Resposta,
                meta: {
                    requiresAuth: true,
                    permissoes: ['RespostaAtualizar'],
                    roles: ['Administrador', 'Analista', 'Cadastrador'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Cadastro de Resposta'
                    }
                },
            },
            {
                path: '/comunicacoes',
                name: 'comunicacoes',
                submenu: true,
                component: ComunicacaoListar,
                meta: {
                    requiresAuth: true,
                    permissoes: ['ComunicaçãoListar'],
                    roles: ['Administrador'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Comunicações'
                    }
                }
            },
            {
                path: '/comunicacoes/grupo',
                name: 'comunicacao-grupo',
                submenu: false,
                component: ComunicacaoGrupo,
                meta: {
                    requiresAuth: true,
                    permissoes: ['ComunicaçãoCriar'],
                    roles: ['Administrador'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Comunicações para grupo'
                    }
                }
            },
            {
                path: '/configuracoes',
                name: 'configuracoes',
                submenu: true,
                component: ConfiguracaoListar,
                meta: {
                    requiresAuth: true,
                    permissoes: ['ConfiguraçãoListar'],
                    roles: ['Administrador'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Configurações'
                    }
                }
            },
            {
                path: '/configuracoes/editar/:configuracao',
                name: 'configuracao-editar',
                submenu: false,
                component: Configuracao,
                meta: {
                    requiresAuth: true,
                    permissoes: ['ConfiguraçãoAtualizar'],
                    roles: ['Administrador'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Cadastro de Configuração'
                    }
                },
            },
            {
                path: '/configuracoes/visualizar/:configuracao',
                name: 'configuracao-visualizar',
                submenu: false,
                component: Configuracao,
                meta: {
                    requiresAuth: true,
                    permissoes: ['ConfiguraçãoVisualizar'],
                    roles: ['Administrador', 'Analista'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Cadastro de Configuração'
                    }
                },
            },
            {
                path: '/conselhos',
                name: 'conselhos',
                submenu: true,
                component: ConselhosListar,
                meta: {
                    requiresAuth: true,
                    permissoes: ['ConselhoListar'],
                    roles: ['Administrador', 'Analista'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Conselhos'
                    }
                }
            },
            {
                path: '/conselhos/editar/:conselho',
                name: 'conselho-editar',
                submenu: false,
                component: Conselho,
                meta: {
                    requiresAuth: true,
                    permissoes: ['ConselhoAtualizar'],
                    roles: ['Administrador'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Cadastro de Conselho'
                    }
                },
            },
            {
                path: '/conselhos/visualizar/:conselho',
                name: 'conselho-visualizar',
                submenu: false,
                component: Conselho,
                meta: {
                    requiresAuth: true,
                    permissoes: ['ConselhoVisualizar'],
                    roles: ['Administrador', 'Analista'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Cadastro de Conselho'
                    }
                },
            },
            {
                path: '/conselhos/novo/:conselho?',
                name: 'conselho-novo',
                submenu: false,
                component: Conselho,
                meta: {
                    requiresAuth: true,
                    permissoes: ['ConselhoCriar'],
                    roles: ['Administrador'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Cadastro de Conselho'
                    }
                }
            },
            {
                path: '/orgaos',
                name: 'orgaos',
                submenu: true,
                component: OrgaosListar,
                meta: {
                    requiresAuth: true,
                    permissoes: ['OrgãoListar'],
                    roles: ['Administrador', 'Analista', 'Consultor'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Orgãos'
                    }
                }
            },
            {
                path: '/orgaos/editar/:orgao',
                name: 'orgao-editar',
                submenu: false,
                component: Orgao,
                meta: {
                    requiresAuth: true,
                    permissoes: ['OrgãoAtualizar'],
                    roles: ['Administrador', 'Analista'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Cadastro de Orgão'
                    }
                },
            },
            {
                path: '/orgaos/visualizar/:orgao',
                name: 'orgao-visualizar',
                submenu: false,
                component: Orgao,
                meta: {
                    requiresAuth: true,
                    permissoes: ['OrgãoVisualizar'],
                    roles: ['Administrador', 'Analista', 'Consultor'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Cadastro de Orgão'
                    }
                },
            },
            {
                path: '/orgaos/novo/:orgao?',
                name: 'orgao-novo',
                submenu: false,
                component: Orgao,
                meta: {
                    requiresAuth: true,
                    permissoes: ['OrgãoCriar'],
                    roles: ['Administrador', 'Analista'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Cadastro de Orgão'
                    }
                }
            },
            {
                path: '/perfis',
                name: 'perfis',
                submenu: true,
                component: PerfilListar,
                meta: {
                    requiresAuth: true,
                    permissoes: ['PerfilListar'],
                    roles: ['Administrador'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Perfis'
                    }
                }
            },
            {
                path: '/perfis/editar/:perfil',
                name: 'perfil-editar',
                submenu: false,
                component: Perfil,
                meta: {
                    requiresAuth: true,
                    permissoes: ['PerfilAtualizar'],
                    roles: ['Administrador'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Cadastro de Perfil'
                    }
                },
            },
            {
                path: '/perfis/visualizar/:perfil',
                name: 'perfil-visualizar',
                submenu: false,
                component: Perfil,
                meta: {
                    requiresAuth: true,
                    permissoes: ['PerfilVisualizar'],
                    roles: ['Administrador'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Cadastro de Perfil'
                    }
                },
            },
            {
                path: '/usuarios',
                name: 'usuarios',
                submenu: true,
                component: UsuarioListar,
                meta: {
                    requiresAuth: true,
                    permissoes: ['UsuárioListar'],
                    roles: ['Administrador', 'Analista'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Usuários'
                    }
                }
            },
            {
                path: '/usuarios/editar/:usuario',
                name: 'usuario-editar',
                submenu: false,
                component: Usuario,
                meta: {
                    requiresAuth: true,
                    permissoes: ['UsuárioAtualizar'],
                    roles: ['Administrador', 'Analista'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Cadastro de Usuário'
                    }
                },
            },
            {
                path: '/usuarios/visualizar/:usuario',
                name: 'usuario-visualizar',
                submenu: false,
                component: Usuario,
                meta: {
                    requiresAuth: true,
                    permissoes: ['UsuárioVisualizar'],
                    roles: ['Administrador', 'Analista'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Cadastro de Usuário'
                    }
                },
            },
            {
                path: '/usuarios/novo/:usuario?',
                name: 'usuario-novo',
                submenu: false,
                component: Usuario,
                meta: {
                    requiresAuth: true,
                    permissoes: ['UsuárioCriar'],
                    roles: ['Administrador', 'Analista'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Cadastro de Usuário'
                    }
                }
            },
            {
                path: '/tipoAto',
                name: 'tipoAto',
                submenu: true,
                component: TipoAtoListar,
                meta: {
                    requiresAuth: true,
                    permissoes: ['TipoAtoListar'],
                    roles: ['Administrador'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Tipos de Ato de Concessão'
                    }
                }
            },
            {
                path: '/tipoAto/novo/:tipoAto?',
                name: 'tipo-ato-novo',
                submenu: false,
                component: TipoAto,
                meta: {
                    requiresAuth: true,
                    permissoes: ['TipoAtoCriar'],
                    roles: ['Administrador'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Cadastro de tipos de ato de concessão'
                    }
                },
            },
            {
                path: '/tipoAto/editar/:tipoAto',
                name: 'tipo-ato-editar',
                submenu: false,
                component: TipoAto,
                meta: {
                    requiresAuth: true,
                    permissoes: ['TipoAtoAtualizar'],
                    roles: ['Administrador'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Cadastro de tipos de ato de concessão'
                    }
                },
            },
            {
                path: '/situacaoRegime',
                name: 'situacaoRegime',
                submenu: true,
                component: SituacaoRegimeListar,
                meta: {
                    requiresAuth: true,
                    permissoes: ['SituaçãoRegimeListar'],
                    roles: ['Administrador'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Situação do Regime'
                    }
                }
            },
            {
                path: '/situacaoRegime/novo/:situacaoRegime?',
                name: 'situacao-regime-novo',
                submenu: false,
                component: SituacaoRegime,
                meta: {
                    requiresAuth: true,
                    permissoes: ['SituaçãoRegimeCriar'],
                    roles: ['Administrador'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Cadastro da situação do regime'
                    }
                },
            },
            {
                path: '/situacaoRegime/editar/:situacaoRegime',
                name: 'situacao-regime-editar',
                submenu: false,
                component: SituacaoRegime,
                meta: {
                    requiresAuth: true,
                    permissoes: ['SituaçãoRegimeAtualizar'],
                    roles: ['Administrador'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Cadastro da situação do regime'
                    }
                },
            },
            {
                path: '/questionarios-avulsos',
                name: 'questionarios-avulsos',
                submenu: true,
                component: QuestionarioAvulsoListar,
                meta: {
                    requiresAuth: true,
                    permissoes: ['QuestionárioAvulsoListar'],
                    roles: ['Administrador','Analista','Cadastrador'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Questionários Avulsos'
                    }
                }
            },
            {
                path: '/questionarios-avulsos/novo/:questionario?',
                name: 'questionario-avaulso-novo',
                submenu: false,
                component: QuestionarioAvulso,
                meta: {
                    requiresAuth: true,
                    permissoes: ['QuestionárioAvulsoCriar'],
                    roles: ['Analista'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Cadastro de Questionário Avulso'
                    }
                }
            },
            {
                path: '/questionarios-avulsos/editar/:questionario',
                name: 'questionario-avulso-editar',
                submenu: false,
                component: QuestionarioAvulso,
                meta: {
                    requiresAuth: true,
                    permissoes: ['QuestionárioAvulsoAtualizar'],
                    roles: ['Analista'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Cadastro de Questionário Avulso'
                    }
                },
            },
            {
                path: '/questionarios-avulsos/visualizar/:questionario',
                name: 'questionario-avulso-visualizar',
                submenu: false,
                component: QuestionarioAvulso,
                meta: {
                    requiresAuth: true,
                    permissoes: ['QuestionárioAvulsoVisualizar'],
                    roles: ['Administrador', 'Analista'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Cadastro de Questionário Avulso'
                    }
                },
            },
            {
                path: '/questionarios-avulsos/responder/:questionario',
                name: 'questionario-avulso-responder',
                submenu: false,
                component: QuestionarioAvulso,
                meta: {
                    requiresAuth: true,
                    permissoes: ['QuestionárioAvulsoResponder'],
                    roles: ['Cadastrador'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Responder Questionário Avulso'
                    }
                },
            },
            {
                path: '/avisos',
                name: 'avisos',
                submenu: true,
                component: AvisosListar,
                meta: {
                    requiresAuth: true,
                    permissoes: ['AvisosListar'],
                    roles: ['Administrador'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Avisos'
                    }
                }
            },
            {
                path: '/avisos/novo',
                name: 'avisos-novo',
                submenu: false,
                component: Avisos,
                meta: {
                    requiresAuth: true,
                    permissoes: ['AvisosCriar'],
                    roles: ['Administrador'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Avisos'
                    }
                }
            },
            {
                path: '/avisos/visualizar/:aviso',
                name: 'avisos-visualizar',
                submenu: false,
                component: Avisos,
                meta: {
                    requiresAuth: true,
                    permissoes: ['AvisosListar'],
                    roles: ['Administrador'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Visualizar Aviso'
                    }
                }
            },
            {
                path: '/pergunta-qualitativa',
                name: 'pergunta-qualitativa',
                submenu: true,
                component: PerguntaQualitativaListar,
                meta: {
                    requiresAuth: true,
                    permissoes: ['PerguntaQualitativaListar'],
                    roles: ['Administrador'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Pergunta Qualitativa'
                    }
                }
            },
            {
                path: '/pergunta-qualitativa/novo/:perguntaQualitativa?',
                name: 'pergunta-qualitativa-novo',
                submenu: false,
                component: PerguntaQualitativa,
                meta: {
                    requiresAuth: true,
                    permissoes: ['PerguntaQualitativaCriar'],
                    roles: ['Administrador'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Cadastro de pergunta qualitativa'
                    }
                },
            },
            {
                path: '/pergunta-qualitativa/editar/:perguntaQualitativa',
                name: 'pergunta-qualitativa-editar',
                submenu: false,
                component: PerguntaQualitativa,
                meta: {
                    requiresAuth: true,
                    permissoes: ['PerguntaQualitativaAtualizar'],
                    roles: ['Administrador'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Cadastro de pergunta qualitativa'
                    }
                },
            },
            {
                path: '/resposta-qualitativa',
                name: 'resposta-qualitativa',
                submenu: true,
                component: RespostaQualitativa,
                meta: {
                    requiresAuth: true,
                    permissoes: ['AvaliacaoQualitativaCriar'],
                    roles: ['Administrador','Analista'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Avaliação Qualitativa'
                    }
                }
            },

            {
                path: '/alerta',
                name: 'alerta',
                submenu: true,
                component: AlertaListar,
                meta: {
                    requiresAuth: true,
                    permissoes: ['AlertaListar'],
                    roles: ['Administrador', 'Analista'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Alerta'
                    }
                }
            },
            {
                path: '/alerta/novo/:alerta?',
                name: 'alerta-novo',
                submenu: false,
                component: Alerta,
                meta: {
                    requiresAuth: true,
                    permissoes: ['AlertaCriar'],
                    roles: ['Analista'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Cadastro de alerta'
                    }
                },
            },
            {
                path: '/alerta/visualizar/:alerta',
                name: 'alerta-visualizar',
                submenu: false,
                component: Alerta,
                meta: {
                    requiresAuth: true,
                    permissoes: ['AlertaListar'],
                    roles: ['Administrador', 'Analista'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Visualizar Alerta'
                    }
                }
            },

            {
                path: '/pergunta-questionario',
                name: 'pergunta-questionario',
                submenu: true,
                component: PerguntaQuestionarioListar,
                meta: {
                    requiresAuth: true,
                    permissoes: ['PerguntaQuestionarioEditar'],
                    roles: ['Administrador'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Pergunta questionário'
                    }
                }
            },
            {
                path: '/pergunta-questionario/editar/:pergunta?',
                name: 'pergunta-questionario-editar',
                submenu: false,
                component: PerguntaQuestionario,
                meta: {
                    requiresAuth: true,
                    permissoes: ['PerguntaQuestionarioEditar'],
                    roles: ['Administrador'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Editar pergunta questionário'
                    }
                },
            },
        ]
    },
    {
        path: '/valida',
        name: 'valida',
        component: Valida,
        meta: {
            requiresAuth: false,
            menu: {
                show: false,
            },
            breadcrumb: {
                show: false,
            }
        }
    },
    {
        path: '/relatorios',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        meta: {
            requiresAuth: true,
            // Ajustar
            roles: ['Administrador', 'Analista'],
            menu: {
                show: true,
                titulo: 'Relatórios',
            },
            breadcrumb: {
                show: true,
                titulo: 'Relatórios'
            }
        },
        children: [
            {
                path: '/relatorios/questionario',
                name: 'relatorio_questionario',
                submenu: true,
                component: RelatorioQuestionario,
                meta: {
                    requiresAuth: true,
                    permissoes: ['Relatório Questionário Enviado X PendentesVisualizar'],
                    roles: ['Administrador', 'Analista', 'Consultor'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Relatório de Questionários'
                    }
                }
            },

            {
                path: '/relatorios/questionario-respondido',
                name: 'relatorio-questionario-respondido',
                submenu: true,
                component: RelatorioQuestionarioRespondido,
                meta: {
                    requiresAuth: true,
                    permissoes: ['RelatorioQuestionarioRespondidoVisualizar'],
                    roles: ['Administrador', 'Analista', 'Consultor'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Questionários Respondidos'
                    }
                }
            },

            {
                path: '/relatorios/qualitativo',
                name: 'relatorio-qualitativo',
                submenu: true,
                component: RelatorioQualitativo,
                meta: {
                    requiresAuth: true,
                    permissoes: ['RelatorioQualitativoVisualizar'],
                    roles: ['Administrador', 'Analista'],
                    breadcrumb: {
                        show: true,
                        titulo: 'Relatório Qualitativo'
                    }
                }
            }
        ]
    },
];

export const router = new VueRouter({
    mode: 'history',
    linkExactActiveClass: 'active',
    routes,
});

router.beforeEach((to, from, next) => {

    // url inválida
    if (to.matched.length === 0) {
        router.push({name: 'home'},
            () => store.dispatch('alertas/show', {
                tipo: 'Erro',
                titulo: 'URL Inválida!',
                mensagem: 'A URL acessada é inválida ou você não possui a permissão necessária para acessá-la.',
            })
        );
    }

    if (to.meta.requiresAuth) {
        let titulo = 'Página requer autenticação';
        let mensagem = 'É necessário realizar login na aplicação para acessar a página desejada.'
        if (store.getters['auth/isLoggedIn']) {
            if (store.getters['auth/usuarioAutenticadoPermissoes'].some(r => to.meta.permissoes.indexOf(r) >= 0)) {
                return next();
            } else {
                titulo = 'Acesso negado';
                mensagem = 'Você não possui permissão necessária para acessar a página desejada.';
            }
        }

        router.push({name: 'home'},
            () => store.dispatch('alertas/show', {
                tipo: 'Erro',
                titulo: titulo,
                mensagem: mensagem,
            })
        );
    }
    next();
});

