<template>
    <div>
        <v-form ref="form">
            <h1 class="text-h5 my-4">
                Cadastro de Usuario
            </h1>
            <v-sheet class="pa-4  mb-5" rounded outlined>
                <v-row>
                    <v-spacer></v-spacer>
                    <toggle-button
                        class="changed-font"
                        :width="100"
                        :height="30"
                        :speed="480"
                        :color="{checked: '#79BD8F', unchecked: '#FF877B'}"
                        :labels="{checked: 'Ativo', unchecked: 'Inativo'}"
                        :sync="true"
                        v-model="form.st_status"
                        :disabled="visualizar || novo"
                    />
                </v-row>
                <h2 class="subtitle-1 mb-3">Dados do Usuario</h2>
                <v-row>
                    <v-col cols="12" md="2">
                        <v-text-field
                            label="CPF *"
                            outlined
                            dense
                            v-model="form.nr_cpf"
                            v-mask="'###.###.###-##'"
                            :rules="validate('required|cpf', 'cpf')"
                            :disabled="visualizar"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="5">
                        <v-text-field
                            label="Nome do do Usuario *"
                            outlined
                            dense
                            v-model="form.nm_usuario"
                            :rules="validate('required', 'nome do usuario')"
                            :disabled="visualizar"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field
                            label="E-mail do do Usuario *"
                            outlined
                            dense
                            v-model="form.ds_email"
                            :rules="validate('required|email', 'e-mail do usuario')"
                            :disabled="visualizar"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-text-field
                            label="Telefone"
                            outlined
                            dense
                            v-model="form.nr_telefone"
                            v-mask="['(##) ####-####', '(##) #####-####']"
                            :disabled="visualizar"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <PerfilAutocomplete
                            @changePerfisEvent="changePerfisEvent"
                            label="Perfis *"
                            v-model="form.perfis"
                            :error="error.perfis"
                            :rules="validate('required', 'perfis')"
                            :disabled="visualizar"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="112">
                        <v-select
                            label="Conselho"
                            outlined
                            v-model="form.id_conselho"
                            dense
                            clearable
                            :items="conselhosSelect"
                            :rules="conselhoRules"
                            item-value="id_conselho"
                            item-text="nm_conselho"
                            :disabled="visualizar"
                            @change="(event) => conselhoChange(event)"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <v-autocomplete
                            label="Orgãos"
                            :value="form.orgaos"
                            outlined
                            :items="orgaosAutoComplete"
                            item-value="id_orgao"
                            item-text="nm_orgao"
                            multiple
                            chips
                            :rules="orgaoRules"
                            :disabled="visualizar"
                            @change="(event) => changeOrgaosEvent(event)"
                        >
                            <template v-slot:selection="{ attrs, item, parent, selected }">
                                <v-chip
                                    :disabled="visualizar"
                                    v-if="item === Object(item)"
                                    v-bind="attrs"
                                    :input-value="selected"
                                >
                                    <v-icon
                                        small
                                        @click="parent.selectItem(item)"
                                    >
                                        $delete
                                    </v-icon>
                                    <span class="pl-2">{{ item.nm_orgao }}</span>
                                </v-chip>
                            </template>
                        </v-autocomplete>
                    </v-col>
                </v-row>
                <v-row v-if="!visualizar">
                    <v-col cols="6" md="12">
                        <v-file-input
                            accept="application/pdf"
                            label="Despacho"
                            outlined
                            dense
                            :rules="despachoRules"
                            v-model="form.despacho"
                            :error-messages="error.despacho"

                        ></v-file-input>
                    </v-col>
                </v-row>

                <v-data-table
                    :headers="headers"
                    :items="items"
                    :hide-default-footer="true"
                    class="elevation-1 mb-7">

                    <template v-slot:item.actions="{ item }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on: tooltip}">
                                <v-btn
                                    fab
                                    color="primary"
                                    elevation="0"
                                    x-small
                                    @click="download(item.id_usuario)"
                                    v-on="{ ...tooltip}"
                                >
                                    <v-icon>
                                        mdi-cloud-download
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Download</span>
                        </v-tooltip>
                    </template>
                </v-data-table>

            </v-sheet>
            <div class="d-flex">
                <v-spacer></v-spacer>
                <v-btn
                class="mx-2"
                color="error"
                @click="voltar()"
                >
                    Voltar
                </v-btn>

                <v-btn
                    v-if="visualizar_btn_historico"
                    class="ml-2"
                    @click="historicoAcaoDialog = true"
                >
                    Histórico
                </v-btn>

                <HistoricoAcaoDialog
                    :entityId="entity_id"
                    :entityType="entity_type"
                    :dialog.sync="historicoAcaoDialog"
                />

                <v-btn v-if="!visualizar"
                       color="primary"
                       @click="salvar()"
                >
                    Salvar
                </v-btn>
            </div>
        </v-form>
    </div>
</template>

<script>

import {getUsuario, createUsuario, updateUsuario, getUsuarioDespachoDownload} from "../../api/usuario";
import {validationMixin} from "../../mixins/validationMixin";
import PerfilAutocomplete from "../../components/inputs/PerfilAutocomplete.vue";
import OrgaoAutocomplete from "../../components/inputs/OrgaoAutocomplete.vue";
import {getConselhos} from "../../api/conselho";
import {getOrgaosByConselho} from "../../api/orgao";
import { EntityTypes } from "../../constants/constants";
import HistoricoAcaoDialog from "../../components/components/HistoricoAcaoDialog";

export default {
    name: "Usuario",
    components: {HistoricoAcaoDialog, PerfilAutocomplete, OrgaoAutocomplete},
    mixins: [],
    props: {
        usuario: {
            type: Object,
            default() {
                return {id_usuario: null}
            }
        },
    },
    mixins: [validationMixin],
    computed: {
        conselhoRules() {
            return this.conselhoRequired && this.form.id_conselho == null ? ['O campo Conselho é obrigatório para Analistas, Cadastradores e Consultores'] : [];
        },
        orgaoRules() {
            return this.orgaoRequired && this.form.orgaos.lengh == 0 ? ['É necessário selecionar ao menos um Orgão é para Cadastradores e Consultores'] : [];
        },
        despachoRules() {
            return false;
            //return this.despachoRequired && this.form.despacho == null ? ['O  Despacho (anexo) é obrigatório para Analistas, Cadastradores e Consultores'] : [];
        }
    },

    data() {
        return {
            conselhoRequired: false,
            orgaoRequired: false,
            despachoRequired: false,
            form: {
                id_usuario: null,
                nm_usuario: null,
                ds_email: null,
                nr_cpf: null,
                nr_telefone: null,
                id_conselho: null,
                id_orgao: null,
                url_despacho: null,
                despacho: null,
                perfis: [],
                orgaos: [],
                st_status: true,
            },
            visualizar: null,
            visualizar_btn_historico: null,
            conselhosSelect: [],
            orgaosAutoComplete: [],
            novo: null,
            error: {},
            headers: [
                {text: 'Anexo', value: 'nm_anexo', sortable: false},
                {text: 'Ações', value: 'actions', sortable: false},
            ],
            items: [],
            historicoAcaoDialog: false,
            entity_id: null,
            entity_type: EntityTypes.USUARIO
        }
    },
    created() {

        this.visualizar = this.$route.name == 'usuario-visualizar' ? true : false;
        this.visualizar_btn_historico = this.$route.name == 'usuario-visualizar' ? true : false;
        this.entity_id = this.$route.name == 'usuario-novo' ? "0" : this.$route.params.usuario;
        this.novo = this.$route.name == 'usuario-novo' ? true : false;

        // editar
        if (this.$route.params.usuario) {
            getUsuario(this.$route.params.usuario).then((response) => {
                this.form.id_usuario = response.data.data.id_usuario;
                this.form.nr_cpf = response.data.data.nr_cpf;
                this.form.nm_usuario = response.data.data.nm_usuario;
                this.form.ds_email = response.data.data.ds_email;
                this.form.nr_telefone = response.data.data.nr_telefone;
                this.form.st_status = response.data.data.st_status;
                this.form.id_conselho = response.data.data.id_conselho;
                this.form.url_despacho = response.data.data.url_despacho;

                if (!!this.form.id_conselho) {
                    getOrgaosByConselho(response.data.data.id_conselho).then((orgaosResponse) => {
                        this.orgaosAutoComplete = orgaosResponse.data.data
                    })
                }

                this.form.id_orgao = response.data.data.id_orgao;
                this.form.perfis = response.data.data.perfis.map((object) => object.id_perfil)
                this.form.orgaos = response.data.data.orgaos.map((object) => object.id_orgao)

                if (!!this.form.url_despacho) {
                    this.items = [
                        {nm_anexo: 'Despacho', id_usuario: this.form.id_usuario},
                    ];
                }

                // this.setRules();

            })
        }

        getConselhos().then(response => this.conselhosSelect = response.data.data);

    },
    methods: {
        salvar() {
            if (this.$refs.form.validate()) {

                this.form.nr_cpf = this.form.nr_cpf.replace(/[-.]/g, '');

                let promise;
                let formData = new FormData();

                formData.append('nr_cpf', this.form.nr_cpf);
                formData.append('nm_usuario', this.form.nm_usuario);
                formData.append('ds_email', this.form.ds_email);
                formData.append('nr_telefone', this.form.nr_telefone);
                formData.append('st_status', this.form.st_status);
                formData.append('id_conselho', this.form.id_conselho);
                formData.append('id_orgao', this.form.id_orgao);
                formData.append('perfis', this.form.perfis);
                formData.append('orgaos', this.form.orgaos);
                formData.append('despacho', this.form.despacho);

                if (this.$route.params.usuario) {
                    promise = updateUsuario(this.$route.params.usuario, formData)
                } else {
                    promise = createUsuario(formData);
                }

                promise.then(response => {
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: response.data.success.message,
                    })
                    this.$vuetify.goTo(0)
                    this.$router.push({name: 'usuarios'})
                })
                    .catch(error => this.handleError(error))
            }
        },
        handleError(error) {

            if (error.response.status == 422) {
                this.$store.dispatch('alertas/show', {
                    tipo: 'Erro',
                    titulo: 'Ocorreu uma falha no upload do arquivo de despacho.',
                    mensagem: 'O arquivo é de um tipo inválido ou excede o tamanho máximo permitido.',
                });
            } else {
                this.$store.dispatch('alertas/show', {
                    tipo: 'Erro',
                    titulo: error.response.data.error.message.title,
                    mensagem: error.response.data.error.message.msg,
                });
            }

            this.$vuetify.goTo(0);

            if (error.response.data.error.message.type == 403) {
                this.$router.push({name: 'home'});
            }
        },
        voltar() {
            this.$router.push({name: 'usuarios'});
        },
        conselhoChange(event) {
            if (this.form.id_conselho != null) {
                getOrgaosByConselho(event).then(response => this.orgaosAutoComplete = response.data.data);
                this.form.orgaos = [];
            } else {
                this.orgaosAutoComplete = [];
            }

            this.resetRules(this.form.perfis);
        },
        setRules() {
            this.resetRules(this.form.perfis);
        },
        resetRules(event) {

            this.conselhoRequired = false;
            this.orgaoRequired = false;
            //this.despachoRequired = false;

            // Administrador
            if (event.indexOf(1) > -1) {
                //console.log('eh admin');
            }

            // Analista
            if (event.indexOf(2) > -1) {
                this.conselhoRequired = true;
                //this.despachoRequired = true;
            }

            // Cadastrador
            if (event.indexOf(3) > -1) {
                this.conselhoRequired = true;
                this.orgaoRequired = true;
                //this.despachoRequired = true;
            }

            // Consultor
            if (event.indexOf(4) > -1) {
                this.conselhoRequired = true;
                this.orgaoRequired = true;
                //this.despachoRequired = true;
            }

            if (this.form.url_despacho != null) {
                //this.despachoRequired = false;
            }

        },
        changePerfisEvent(event) {
            this.resetRules(event);
        },
        changeOrgaosEvent(event) {
            this.form.orgaos = event;
            this.resetRules(event);
        },
        download() {
            getUsuarioDespachoDownload(this.form.id_usuario)
                .then(response => {
                    this.forceFileDownload(response);
                });
        },
        forceFileDownload(response, file = 'despacho.pdf') {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', file) //or any other extension
            document.body.appendChild(link)
            link.click()
        },
    },
}
</script>

<style>

.vue-js-switch.changed-font {
    font-size: 16px !important;
    margin: 10px;
}

</style>
