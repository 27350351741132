<template>
    <div>
        <v-form ref="form">
            <h1 class="text-h5 my-4">
                Questionário
            </h1>
            <v-sheet class="pa-4  mb-5" rounded outlined>
                <h2 class="subtitle-1 mb-3">Cabeçalho do Questionário</h2>
                <input type="hidden" name="id_orgao" class="form-control" :value="form.id_orgao">
                <input type="hidden" name="id_questionario" class="form-control" :value="form.id_questionario">
                <v-row>
                    <v-col cols="12" md="4">
                        <v-text-field
                            label="Conselho"
                            outlined
                            dense
                            v-model="form.nm_conselho"
                            disabled
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field
                            label="Orgão"
                            outlined
                            dense
                            v-model="form.nm_orgao"
                            disabled
                        ></v-text-field>

                    </v-col>
                    <v-col v-if="novo" cols="12" md="4">
                        <v-select
                            label="Mês/Ano *"
                            outlined
                            v-model="form.dt_mes_ano"
                            dense
                            clearable
                            :items="mesesSelect"
                            :disabled="!novo"
                            :rules="validate('required', 'Mês/Ano')"
                        />

                    </v-col>
                    <v-col v-else cols="12" md="2">
                        <v-select
                            label="Mês/Ano *"
                            outlined
                            v-model="form.dt_mes_ano"
                            dense
                            clearable
                            :items="mesesSelect"
                            :disabled="!novo"
                            :rules="validate('required', 'Mês/Ano')"
                        />
                    </v-col>
                    <v-col v-if="!novo" cols="12" md="2">
                        <v-text-field
                            label="Status"
                            outlined
                            dense
                            v-model="form.nm_questionario_status"
                            disabled
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-textarea v-if="form.ds_reabrir_formulario != null"
                            label="Devolução para Ajustes"
                            outlined
                            dense
                            disabled
                            v-model="form.ds_reabrir_formulario"
                        ></v-textarea>
                    </v-col>
                </v-row>
                <template v-if="!novo">
                    <h2 class="subtitle-1 mb-3">Dados do Questionário</h2>
                    <div>
                        <v-expansion-panels
                            multiple
                            v-model="openedPanel"
                        >
                            <v-expansion-panel
                                v-for="pergunta in questionario.perguntas"
                                :key="pergunta.id_pergunta"
                            >
                                <v-expansion-panel-header disable-icon-rotate>
                                    {{ pergunta.id_pergunta }} - {{ pergunta.ds_pergunta }}
                                    <template v-slot:actions>
                                        <v-icon :color=pergunta.icon_color>
                                            {{ pergunta.icon }}
                                        </v-icon>
                                    </template>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <Resposta
                                        :pergunta="pergunta"
                                        :questionario="questionario"
                                    ></Resposta>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </div>
                </template>
            </v-sheet>
            <div class="d-flex">
                <v-spacer></v-spacer>
                <v-btn
                    class="mx-2"
                    color="success"
                    v-if="questionario.id_questionario"
                    @click="callPDF()"
                >
                    Exportar como PDF
                </v-btn>

                <QuestionarioPDF v-if="callPF" :questionario="questionario"/>

                <v-btn
                    class="ml-2"
                    @click="historico_dialog = true"
                >
                    Histórico
                </v-btn>

                <v-dialog v-model="historico_dialog" class="v-dialog">
                    <div class="div-historico">
                        <v-data-table
                            :headers="historicoHeaders"
                            :items="questionario.historico"
                            class="elevation-1"
                            :hide-default-footer="true"
                            :items-per-page=-1
                            :sort-desc="true"
                        >

                            <template v-slot:item.dt_evento="{ item }">
                                <span>{{ dateFormat(item.dt_evento) }}</span>
                            </template>

                        </v-data-table>

                    </div>
                </v-dialog>

                <v-btn
                    class="ml-2"
                    color="error"
                    @click="voltar()"
                >
                    Voltar
                </v-btn>

                <v-btn v-if="reabrir_questionario"
                       class="ml-2"
                       color="primary"
                       @click="reabrir_dialog = true"
                >
                    Reabrir
                </v-btn>
                <v-dialog v-model="reabrir_dialog" persistent class="v-dialog">
                    <v-card>
                        <v-card-title class="headline">
                            Deseja realmente reabrir o Questionário?
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <v-textarea
                                        v-model="form.ds_descricao"
                                        :rules="validate('required', 'Devolução para ajustes ')"
                                        label="Devolução para ajustes *"
                                        outlined
                                        dense
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="error"
                                @click="fecharReabrir()">
                                Cancelar
                            </v-btn>
                            <v-btn
                                color="primary"
                                @click="reabrirQuestionario()"
                            >
                                Reabrir
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-btn v-if="novo"
                       class="ml-2"
                       color="primary"
                       @click="responderQuestionario()"
                >
                    Iniciar Preenchimento
                </v-btn>

                <v-btn v-if="submeter_questionario"
                       class="ml-2"
                       color="primary"
                       @click="submit_dialog = true"

                >
                    Enviar Questionário
                </v-btn>

                <v-dialog v-model="submit_dialog" persistent class="v-dialog">
                    <v-card>
                        <v-card-title class="headline">
                            Deseja realmente submeter o Questionário?
                        </v-card-title>
                        <v-card-text>
                            <h3>
                                Ao submeter, este Questionário entrará em análise pelos responsáveis e você não poderá
                                mais editar as informações nele contidas.
                            </h3>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="error"
                                @click="submit_dialog = false">
                                Cancelar
                            </v-btn>
                            <v-btn
                                color="primary"
                                @click="submeterQuestionario()"
                            >
                                Enviar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

            </div>
        </v-form>
    </div>
</template>

<script>

import {getQuestionario, createQuestionario, submeterQuestionario, reabrirQuestionario} from "../../api/questionario";
import {getOrgaoByUser, getMesesSemQuestionario} from "../../api/orgao";
import {validationMixin} from "../../mixins/validationMixin";
import DeleteAction from "../../components/inputs/DeleteAction.vue";
import Resposta from "../../components/components/Resposta.vue";
import {getMe} from "../../api/usuario";

import QuestionarioPDF from '../../views/pdf/QuestionarioPDF.vue'

import moment from 'moment';

moment.locale('pt-br');

export default {
    name: "Questionario",
    components: {DeleteAction, Resposta, QuestionarioPDF},
    mixins: [validationMixin],
    data() {
        return {
            openedPanel: [],
            submit_dialog: false,
            reabrir_dialog: false,
            valida_dialog: false,
            historico_dialog: false,
            historicoHeaders: [
                {text: 'Data', value: 'dt_evento', sortable: false},
                {text: 'Usuário', value: 'usuario.nm_usuario', sortable: false},
                {text: 'Evento', value: 'ds_evento', sortable: false},
                {text: 'Descrição', value: 'ds_descricao', sortable: false},
            ],
            form: {
                id_questionario: null,
                id_orgao: null,
                nm_orgao: null,
                nm_conselho: null,
                id_questionario_status: null,
                nm_questionario_status: null,
                dt_mes_ano: null,
                ds_descricao: null,
                ds_reabrir_formulario: null
            },
            submeter_questionario: false,
            reabrir_questionario: false,
            mesesSelect: [],
            orgaosSelect: [],
            visualizar: null,
            novo: null,
            editar: null,
            questionario: {},
            error: {},
            callPF: false,
            ultimo_ano_rec_fiscal: null,
        }
    },
    created() {

        this.visualizar = this.$route.name == 'questionario-visualizar' ? true : false;
        this.novo = this.$route.name == 'questionario-novo' ? true : false;
        this.editar = this.$route.name == 'questionario-editar' ? true : false;

        // update/view
        if (this.$route.params.questionario) {
            getQuestionario(this.$route.params.questionario).then((response) => {
                this.questionario = response.data.data;
                this.questionario.historico = this.questionario.historico.sort((a, b) => b.dt_evento - a.dt_evento);

                // verifica se o usuário tem permissão para acessar o questionário
                let canBe = false;
                let canBeMessage = '';
                getMe().then(user_response => {
                    let user = user_response.data;

                    // Administrador pode tudo
                    if (this.$store.getters['auth/usuarioHasRole'](['Administrador'])) {
                        canBe = true;
                    }

                    // Analista Federal ou Estadual
                    if (this.$store.getters['auth/usuarioHasRole'](['Analista']) && !canBe) {

                        canBe = true;

                        // Analista não pode bulir em questionário de outro estado
                        if (!(this.questionario.orgao.conselho.id_conselho == user.id_conselho || this.questionario.orgao.conselho.id_conselho_pai == user.id_conselho)) {
                            canBe = false;
                            canBeMessage = 'Usuário não tem permissão para acessar esse registro. Procure o adminstrador do sistema.';
                        }

                        // Analista não pode editar questionario Em Preencimento
                        if (this.editar && this.questionario.status.nm_questionario_status == 'Em Preenchimento') {
                            canBe = false;
                            canBeMessage = 'Usuário não tem permissão para editar esse questionário. Procure o adminstrador do sistema.';
                        }
                    }

                    // Cadastrador e Consultor
                    if (this.$store.getters['auth/usuarioHasRole'](['Cadastrador']) && !canBe) {
                        canBe = true;

                        // Cadastrador não pode bulir em questionário de outro orgão
                        if (this.questionario.id_orgao != user.id_orgao) {
                            canBe = false;
                            canBeMessage = 'Usuário não tem permissão para acessar esse registro. Procure o adminstrador do sistema.';
                        }

                        // Cadastrador não pode editar questionario Em Análise
                        if (this.editar && this.questionario.status.nm_questionario_status == 'Em Análise') {
                            canBe = false;
                            canBeMessage = 'Usuário não tem permissão para editar esse registro. Procure o adminstrador do sistema.';
                        }
                    }

                    if (this.$store.getters['auth/usuarioHasRole'](['Consultor']) && !canBe) {
                        canBe = true;

                        if (this.questionario.orgao.conselho.id_conselho != user.conselho.id_conselho) {
                            canBe = false;
                            canBeMessage = 'Usuário não tem permissão para acessar esse registro. Procure o adminstrador do sistema.';
                        }
                    }

                    if (!canBe) {
                        this.$store.dispatch('alertas/show', {
                            tipo: 'Erro',
                            titulo: 'Não autorizado!',
                            mensagem: canBeMessage,
                        });

                        this.$router.push({name: 'home'});
                        this.$vuetify.goTo(0);

                    } else {
                        this.form.id_questionario = this.questionario.id_questionario;
                        this.form.id_orgao = this.questionario.orgao.id_orgao;
                        this.form.nm_orgao = this.questionario.orgao.nm_orgao;
                        this.form.nm_conselho = this.questionario.orgao.conselho.sg_sigla;
                        this.form.id_questionario_status = this.questionario.status.id_questionario_status;
                        this.form.nm_questionario_status = this.questionario.status.nm_questionario_status;
                        this.form.ds_reabrir_formulario = this.questionario.ds_reabrir_formulario?.ds_descricao;
                        this.mesesSelect.push({
                            text: moment(this.questionario.dt_mes_ano).format('MMMM/YYYY'),
                            value: moment(this.questionario.dt_mes_ano).format('yyyy-MM-DD')
                        });

                        this.form.dt_mes_ano = moment(this.questionario.dt_mes_ano).format('yyyy-MM-DD');
                        this.questionario.perguntas.forEach(pergunta => {

                            pergunta.respostas = [];

                            this.questionario.respostas.forEach(resposta => {
                                if (pergunta.id_pergunta == resposta.id_pergunta) {
                                    pergunta.respostas.push(resposta);
                                }
                            });

                            // validação da abertura dos paineis
                            // se for Analista e o questionário estiver Em Análise, pode submeter
                            if (this.editar && this.$store.getters['auth/usuarioHasRole'](['Analista']) && ((this.questionario.status.nm_questionario_status == 'Em Análise') || (this.questionario.status.nm_questionario_status == 'Em Análise & Ajustes'))) {

                                let temAberta = false;
                                pergunta.respostas.forEach(resposta => {
                                    if (resposta.status.nm_resposta_status == 'Em Análise') {
                                        this.openPanel(pergunta.id_pergunta - 1);
                                        temAberta = true;
                                    }
                                });

                                if (temAberta) {
                                    pergunta['icon'] = 'mdi-alert-circle';
                                    pergunta['icon_color'] = 'warning';
                                } else {
                                    pergunta['icon'] = 'mdi-check-decagram';
                                    pergunta['icon_color'] = 'success';
                                }
                            }

                            // validação da abertura dos paineis
                            // se for Cadastrador e o questionário estiver Em Análise & Ajustes, pode submeter
                            if (this.editar && this.$store.getters['auth/usuarioHasRole'](['Cadastrador']) && (this.questionario.status.nm_questionario_status == 'Em Análise & Ajustes' || this.questionario.status.nm_questionario_status == 'Devolvido para Ajustes')) {

                                let temAberta = false;
                                pergunta.respostas.forEach(resposta => {
                                    if (resposta.status.nm_resposta_status == 'Em Ajuste' || resposta.status.nm_resposta_status == 'Em Análise') {
                                        this.openPanel(pergunta.id_pergunta - 1);
                                        temAberta = true;
                                    }
                                });

                                if (temAberta) {
                                    pergunta['icon'] = 'mdi-alert-circle';
                                    pergunta['icon_color'] = 'warning';
                                } else {
                                    pergunta['icon'] = 'mdi-check-decagram';
                                    pergunta['icon_color'] = 'success';
                                }
                            }
                        });

                        // se for Cadastrador e o questionário estiver em aberto, pode submeter
                        if (this.editar && this.$store.getters['auth/usuarioHasRole'](['Cadastrador']) && (this.questionario.status.nm_questionario_status == 'Em Preenchimento' || this.questionario.status.nm_questionario_status == 'Devolvido para Ajustes')) {
                            this.submeter_questionario = true;
                        }

                        // se for Analista Federal e o questionário estiver encerrado, pode reabrir
                        if (this.$store.getters['auth/usuarioHasRole'](['Analista']) &&
                            this.$store.getters['auth/usuarioAutenticadoTipoAnalista'] == 'Federal' &&
                            this.$store.getters['auth/usuarioHasPermissao'](['QuestionárioReabrir']) &&
                            (this.questionario.status.nm_questionario_status == 'Encerrado')) {
                            this.reabrir_questionario = true;
                        }
                    }
                });
            })

        } else { // novo

            getOrgaoByUser().then((response) => {
                this.form.id_orgao = response.data.data.id_orgao;
                this.form.nm_orgao = response.data.data.nm_orgao;
                this.form.nm_conselho = response.data.data.conselho.sg_sigla;
                this.ultimo_ano_rec_fiscal = response.data.data.conselho.ultimo_ano_rec_fiscal;

                getMesesSemQuestionario(this.form.id_orgao).then((response) => {
                    for (const key in response.data.data) {
                        this.mesesSelect.push({
                            text: moment(response.data.data[key]).format('MMMM/YYYY'),
                            value: response.data.data[key]
                        });
                    }
                });
            });
        }
    },
    methods: {
        submeterQuestionario() {

            let promise;

            promise = submeterQuestionario(this.$route.params.questionario, this.form)

            promise.then(response => {
                this.$store.dispatch('alertas/show', {
                    tipo: 'Sucesso',
                    titulo: response.data.message,
                })
                this.$router.push({name: 'questionarios'});
            }).catch(error => this.handleError(error))
        },
        reabrirQuestionario() {
            if (this.$refs.form.validate()) {
                let promise;

                promise = reabrirQuestionario(this.$route.params.questionario, this.form)

                promise.then(response => {
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: response.data.message,
                    })
                    this.$router.push({name: 'questionarios'});
                }).catch(error => this.handleError(error))
            }
        },
        fecharReabrir() {
            this.form.ds_descricao = null;
            this.reabrir_dialog = false;
        },
        validarQuestionario() {

            let promise;

            promise = validarQuestionario(this.$route.params.questionario)

            promise.then(response => {
                this.$store.dispatch('alertas/show', {
                    tipo: 'Sucesso',
                    titulo: response.data.message,
                })
                this.$router.push({name: 'questionarios'});
            }).catch(error => this.handleError(error))
        },
        handleError(error) {
            this.$store.dispatch('alertas/show', {
                tipo: 'Erro',
                titulo: error.response.data.error.message.title,
                mensagem: error.response.data.error.message.msg,
            });

            this.$vuetify.goTo(0);

            if (error.response.data.error.message.type == 403) {
                this.$router.push({name: 'home'});
            }
        },
        voltar() {
            this.$router.push({name: 'questionarios'});
        },
        responderQuestionario() {
            if (this.novo && this.ultimo_ano_rec_fiscal == null) {
                const customError = {
                    response: {
                        data: {
                            error: {
                                message: {
                                    title: 'Ocorreu uma falha ao criar o Questionário:',
                                    msg: 'Último ano no Regime de Recuperação Fiscal não informado para o Conselho selecionado.'
                                }
                            }
                        }
                    }
                };

                this.handleError(customError);
                return;
            }

            if (this.$refs.form.validate()) {

                let promise;
                this.form.id_questionario_status = 1;

                promise = createQuestionario(this.form);

                promise.then(response => {
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: response.data.message,
                    })
                    this.$vuetify.goTo(0)
                    this.$router.push({
                        name: 'questionario-editar',
                        params: {questionario: response.data.data.id_questionario}
                    });
                })
            }
        },
        openPanel(index) {
            this.openedPanel.push(index)
        },
        dateFormat(date) {
            if (!!date) {
                return moment(date).format("DD/MM/YYYY [às] HH:mm:ss")
            }
            return null;
        },

        callPDF() {
            this.callPF = true;

            setTimeout(function () {window.location.reload()} , 2000);
        },
    },
}
</script>

<style>

.vue-js-switch.changed-font {
    font-size: 16px !important;
    margin: 10px;;
}

.v-dialog {
    max-width: 50%;
}

.div-historico {
    max-height: 800px;
    overflow-y: auto;
}

</style>


