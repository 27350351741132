<script>

import ConselhoSelect from "../../components/inputs/ConselhoSelect.vue";
import {validationMixin} from "../../mixins/validationMixin";
import {createAviso, getAviso} from "../../api/aviso";
import {getOrgao} from "../../api/orgao";

export default {
    components: {ConselhoSelect},
    mixins: [validationMixin],
    data() {
        return {
            search: '',
            visualizar: null,
            form: {
                id_conselho: null,
                nm_assunto: null,
                nm_mensagem: null
            },
            usuarios: [],
            headers: [
                {key: 'usuario', text: 'Nome', value: 'nm_usuario'},
                {key: 'ciente', text: 'Ciente em:', value: 'read_at'},
            ],
            fields: {
                id_conselho: [],
            },
            error: {},
        }
    },
    methods: {
        voltar() {
            this.$router.push({name: 'avisos'});
        },
        enviar() {
            if (this.$refs.form.validate()) {

                createAviso(this.form).then(response => {
                    this.$store.dispatch('alertas/show', {
                        tipo: 'Sucesso',
                        titulo: response.data.message,
                    })
                    this.$vuetify.goTo(0)
                    this.$router.push({name: 'avisos'})
                });

            }
        }
    },
    created() {
        this.visualizar = this.$route.name === 'avisos-visualizar';

        if (this.$route.params.aviso) {
            getAviso(this.$route.params.aviso).then((response) => {
                this.form.id_conselho = response.data.data.id_conselho;
                this.form.nm_assunto = response.data.data.nm_assunto;
                this.form.nm_mensagem = response.data.data.nm_mensagem;
                this.usuarios = response.data.data.usuarios;
            })
        }
    }
}
</script>

<template>
    <div>
        <v-form ref="form">
            <v-row>
                <v-col>
                    <h1 class="text-h5 my-4">
                        Enviar Aviso
                    </h1>
                </v-col>
                <v-col v-if="this.visualizar">
                    <h1 class="text-h5 my-4">
                        Cientes
                    </h1>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" :md="this.visualizar ? 6 : 12">
                    <v-sheet class="pa-4  mb-5" rounded outlined>
                        <h2 class="subtitle-1 mb-3">Conselho Destino</h2>

                        <v-row>
                            <v-col cols="12" md="12">
                                <ConselhoSelect
                                    label="Destinatário *"
                                    v-model="form.id_conselho"
                                    :rules="validate('required', 'Destinatário')"
                                    :disabled="visualizar"
                                />
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field
                                    label="Assunto *"
                                    outlined
                                    dense
                                    v-model="form.nm_assunto"
                                    :rules="validate('required', 'Título')"
                                    :disabled="visualizar"
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" md="12">
                                <v-textarea
                                    label="Mensagem *"
                                    outlined
                                    dense
                                    v-model="form.nm_mensagem"
                                    :rules="validate('required', 'Corpo do E-mail')"
                                    :disabled="visualizar"
                                ></v-textarea>
                            </v-col>
                        </v-row>

                    </v-sheet>
                </v-col>
                <v-col v-if="this.visualizar">

                    <v-sheet rounded outlined>

                        <v-text-field
                            class="pa-2"
                            v-model="search"
                            label="Pesquisar"
                            prepend-inner-icon="mdi-magnify"
                            variant="outlined"
                            clearable
                            hide-details
                            single-line
                        ></v-text-field>

                        <v-data-table
                            :headers="headers"
                            :items="usuarios"
                            :search="search"
                            fixed-header
                            :hide-default-footer="true"
                            height="373"
                            disable-pagination

                        ></v-data-table>
                        <!--
                        <v-simple-table
                            fixed-header
                            height="375" class="p-0"
                        >
                            <thead>
                            <tr>
                                <th class="text-left">
                                    Name
                                </th>
                                <th class="text-center">
                                    Ciente
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                v-for="item in usuarios"
                                :key="item.id_usuario"
                            >
                                <td>{{ item.nm_usuario }}</td>
                                <td class="text-center">{{ item?.read_at }}</td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                        -->
                    </v-sheet>
                </v-col>
            </v-row>
            <div class="d-flex">
                <v-spacer></v-spacer>
                <v-btn
                    class="mx-2"
                    color="error"
                    @click="voltar()"
                >
                    Voltar
                </v-btn>

                <v-btn v-if="!visualizar"
                       color="primary"
                       @click="enviar()"
                >
                    Enviar
                </v-btn>
            </div>
        </v-form>
    </div>
</template>

<style scoped>

</style>
