import api from './index';

const getRespostas = () => api.get('resposta/respostas');
const getResposta = (id_resposta) => api.get(`resposta/${id_resposta}`);
const createResposta = (dados) => api.post('resposta', dados);
const updateResposta = (id_resposta, dados) => api.put(`resposta/${id_resposta}`, dados);
const devolverResposta = (id_resposta, dados) => api.put(`resposta/devolver/${id_resposta}`, dados);
const validaResposta = (id_resposta) => api.put(`resposta/valida/${id_resposta}`);
const deleteResposta = (id_resposta) => api.delete(`resposta/${id_resposta}`);

const getRespostaAnexoDownload = (id_resposta_anexo) => api.get('resposta/anexo/' + id_resposta_anexo + '/download')

const getRespostasPaginate = async (page, itemsPerPage, filtrosAplicados = []) => await api.get('resposta/respostas-paginate', {
    params: {
        page: page,
        limit: itemsPerPage,
        filtros: filtrosAplicados,
    },
    showLoader: false
});

const getRelatorioQualitativoPaginate = async (page, itemsPerPage, filtrosAplicados = []) => await api.get('resposta/relatorio-qualitativo-paginate', {
    params: {
        page: page,
        limit: itemsPerPage,
        filtros: filtrosAplicados,
    },
    showLoader: true
});
const getValorTotalRelatorioQualitativo = (filtros) => api.post('resposta/getValorTotalRelatorioQualitativo', filtros);

export {    
    getRespostas,
    getRespostasPaginate,
    getRespostaAnexoDownload,
    getResposta,
    createResposta,
    updateResposta,
    devolverResposta,
    validaResposta,
    deleteResposta,
    getRelatorioQualitativoPaginate,
    getValorTotalRelatorioQualitativo
}
