<template>
    <div >
        <VueHtml2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="false"
            :paginate-elements-by-height="1400"
            :filename="'questionarioAvulso' + questionario.id_questionario_avulso + '_' + dateFormatMesAnoPDFName(questionario.dt_referencia)"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="800px"
            pdf-content-height="1100px"
            ref="html2Pdf"
        >
            <section slot="pdf-content">
                
                <div class="pdf-content">
                    <h1>Questionário Avulso</h1>

                    <table class="tb-header">
                        <tr>
                            <td>Orgão</td>
                            <td>{{ questionario.orgao.nm_orgao }}</td>
                        </tr>
                        <tr>
                            <td>Mês/Ano</td>
                            <td>{{ dateFormat(questionario.dt_referencia) }}</td>
                        </tr>
                    </table>

                    <br/>

                    <table class="tb-content">
                        <th>
                            <td>Descrição</td>
                        </th>
                        <tr>
                            <td>{{ questionario.ds_questionario_avulso }}</td>
                        </tr>
                    </table>

                    <br/>
                    <v-divider></v-divider>
                    <br/>

                    <h1>Resposta questionário Avulso</h1>

                    <table class="tb-content">
                        <th>
                            <td>Resposta questionário</td>
                        </th>
                        <tr>
                            <td>{{ questionario.resposta.ds_resposta_questionario_avulso }}</td>
                        </tr>
                    </table>

                    <br/>
                    <v-divider></v-divider>
                    <br/>

                    <table class="tb-content">
                        <th>
                            <td>Número do SEI</td>
                        </th>
                        <tr>
                            <td>{{ questionario.resposta.nu_sei }}</td>
                        </tr>
                    </table>
                </div>

            </section>
        </VueHtml2pdf>
    </div>
</template>

<script>

import VueHtml2pdf from 'vue-html2pdf'
import moment from 'moment';

moment.locale('pt-br');

export default {
    name: "QuestionarioAvulsoPDF",
    components: { VueHtml2pdf },    
    props: {
        questionario: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {

        }
    },
    created() {

    },
    mounted() {
        this.$refs.html2Pdf.generatePdf();
    },
    methods: {
        dateFormat(date) {
            if (!!date) {
                return moment(date.toString()).format('DD/MM/YYYY');
            }
            return null;
        },
        dateFormatMesAnoPDFName(date) {
            if (!!date) {
                return moment(date.toString()).format('DD-MM-YYYY');
            }
            return null;
        },
    },
}
</script>

<style>

.vue-js-switch.changed-font {
    font-size: 16px !important;
    margin: 10px;;
}

.v-dialog {
    max-width: 50%;
}

.v-dialog-card {
    padding: 10px;
}

.pdf-content {
    margin: auto;
    margin-top: 60px;
    width: 86%;  
}

table {
    margin: auto;
    width: 96%;  
}

.tb-header {
    margin-top: 2%;    
}

.tb-header td {
    padding: 1%;
    font-weight: bold;    
    margin-right: 5%;
    border-left: 1px solid black;
}

.tb-content {
    border: 1px solid black;
    padding: 1%;
    margin: auto;
    width: 96%;    
}

.div-subtitles {
    padding: 2%;
}

.tb-acrescimos {
    border: 1px solid black;
    padding: 1%;
    margin: auto;
    width: 96%;
}

.tb-acrescimos tr {
    border-bottom: 1px solid black;
}

.tb-despachos {
    border: 1px solid black;
    padding: 1%;
    margin: auto;
    width: 96%;
}

.tb-despachos tr {
    border-bottom: 1px solid black;
}

.tb-sei {
    border: 1px solid black;
    padding: 1%;
    margin: auto;
    width: 96%;
}

.tb-cargo {
    border: 1px solid black;
    padding: 1%;
    margin: auto;
    width: 96%;
}

.tb-servidores {
    border: 1px solid black;
    padding: 1%;
    margin: auto;
    width: 96%;
}

.tb-convenio {
    border: 1px solid black;
    padding: 1%;
    margin: auto;
    width: 96%;
}

</style>


