export const alertas = {
    namespaced: true,
    state: {
        erro: {
            ativo: false,
            titulo: null,
            mensagem: null,
        },
        sucesso: {
            ativo: false,
            titulo: null,
            mensagem: null,
        },
        info: {
            ativo: false,
            titulo: null,
            mensagem: null,
        },
        warning: {
            ativo: false,
            titulo: null,
            mensagem: null,
        },
    },
    getters: {
        getErro(state) {
            return state.erro;
        },
        getSucesso(state) {
            return state.sucesso;
        },
        getInfo(state) {
            return state.info;
        },
        getWarning(state) {
            return state.warning;
        },
    },
    actions: {
        show({ commit }, payload) {
            commit(`destroyAlertas`);
            commit(`set${payload.tipo}`, payload);

            setTimeout(() => {
                commit(`destroyAlertas`);
            }, "7000");              

            return Promise.resolve();
        },
        hide({ commit }) {
            commit(`destroyAlertas`);
            return Promise.resolve();
        },
    },
    mutations: {
        setErro(state, payload) {
            state.erro.ativo = true;
            state.erro.titulo = payload.titulo;
            state.erro.mensagem = payload.mensagem;
        },
        setSucesso(state, payload) {
            state.sucesso.ativo = true;
            state.sucesso.titulo = payload.titulo;
            state.sucesso.mensagem = payload.mensagem;
        },
        setInfo(state, payload) {
            state.info.ativo = true;
            state.info.titulo = payload.titulo;
            state.info.mensagem = payload.mensagem;
        },
        setWarning(state, payload) {
            state.warning.ativo = true;
            state.warning.titulo = payload.titulo;
            state.warning.mensagem = payload.mensagem;
        },
        destroyAlertas(state) {
            state.erro.ativo = false;
            state.erro.titulo = null;
            state.erro.mensagem = null;
            state.sucesso.ativo = false;
            state.sucesso.titulo = null;
            state.sucesso.mensagem = null;
            state.info.ativo = false;
            state.info.titulo = null;
            state.info.mensagem = null;
            state.warning.ativo = false;
            state.warning.titulo = null;
            state.warning.mensagem = null;
        }
    }
};