<template>
    <div>
        <v-form ref="form">
            <v-row>
                <v-col cols="12" md="12">
                    <h4>Anexos</h4>
                </v-col>
            </v-row>
            <v-row v-if="disabled">
                <v-col cols="12" md="6">
                    <v-text-field
                        label="Descrição do Anexo *"
                        :disabled="!disabled"
                        outlined
                        dense
                        v-model="form.ds_entity_anexo"
                        :rules="validate('required|max 100', 'Descrição do Anexo')"
                    ></v-text-field>
                </v-col>
                <v-col cols="6" md="4">
                    <v-file-input
                        accept="application/pdf"
                        label="Anexo"
                        outlined
                        dense
                        :rules="validate('size 100', 'Anexo')"
                        v-model="form.file"
                    ></v-file-input>
                </v-col>
                <v-col cols="6" md="2">
                    <v-btn
                        color="primary"
                        @click="anexar()"
                    >
                        Anexar
                    </v-btn>
                </v-col>
            </v-row>
            <v-data-table
                :headers="anexoHeaders"
                :items="anexos"
                class="elevation-1 mb-0 mt-4"
            >
                <template v-slot:item.actions="{ item }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on: tooltip}">
                            <v-btn
                                fab
                                color="primary"
                                elevation="0"
                                x-small
                                @click="download(item.id_anexo)"
                                v-on="{ ...tooltip}"
                            >
                                <v-icon>
                                    mdi-cloud-download
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Download</span>
                    </v-tooltip>
                    <DeleteAction
                        v-if="disabled"
                        @delete="deletar(item.id_anexo)"
                    />
                </template>
            </v-data-table>
        </v-form>
    </div>
</template>

<script>
import { validationMixin } from "../../mixins/validationMixin";
import DeleteAction from "../../components/inputs/DeleteAction.vue";

export default {
    name: "AnexosComponent",
    mixins: [validationMixin],
    components: { DeleteAction },
    props: {
        disabled: {
            type: Boolean,
        },
        api: {
            type: Object,
            required: true
        },
        routes: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            anexos: [],
            form: {
                ds_entity_anexo: null,
                file: null,
            },
            anexoHeaders: [
                { text: 'ID', value: 'id_anexo', sortable: false },
                { text: 'Descrição', value: 'ds_entity_anexo', sortable: false },
                { text: 'Ações', value: 'actions', sortable: false },
            ]
        }
    },
    created() {
        this.updateAnexos();
    },
    methods: {
        anexar() {
            if (this.$refs.form.validate()) {

                let promise;
                let formData = new FormData();

                formData.append('entity_id', this.routes.entity_id);
                formData.append('entity_type', this.routes.entity_type);
                formData.append('path', this.routes.path);
                formData.append('ds_entity_anexo', this.form.ds_entity_anexo);
                formData.append('file', this.form.file);

                promise = this.api.upload(formData);

                promise.then(response => {
                    this.updateAnexos();
                    this.form.ds_entity_anexo = null;
                    this.form.file = null;
                })
                    .catch(error => this.handleError(error))
            }
        },
        deletar(id_anexo) {
            this.api.delete(id_anexo)
                .then(response => {
                    this.updateAnexos();
                });
        },
        updateAnexos() {
            this.api.get({'entity_id': this.routes.entity_id, 'entity_type': this.routes.entity_type}).then((response) => {
                this.anexos = response.data.data;
                this.$emit("anexoEmit", this.anexos);
            })
        },
        download(id_anexo) {
            this.api.download(id_anexo)
                .then(response => {
                    this.forceFileDownload(response);
                });
        },
        forceFileDownload(response) {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url

            const contentType = response.headers['content-type'];
            let fileName = 'anexo';

            switch (contentType) {
                case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                    fileName += '.xlsx';
                    break;

                case 'application/vnd.ms-excel':
                    fileName += '.xls';
                    break;

                case 'image/png':
                    fileName += '.png';
                    break;

                case 'application/msword':
                    fileName += '.doc';
                    break;

                case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                    fileName += '.docx';
                    break;

                default:
                    fileName += '.pdf';
                    break;
            }

            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
        },
    },
}
</script>
