<template>
    <v-autocomplete
        :label="label"
        outlined
        :value="value"
        @input="$emit('input', $event)"
        :rules="rules"
        :error-messages="error"
        :items="perfis"
        :filter="filterNormalize"
        item-value="id_perfil"
        item-text="nm_perfil"
        multiple
        chips
        :disabled="disabled"        
        @change="(event) => changePerfil(event)"
    >
        <template v-slot:selection="{ attrs, item, parent, selected }">
            <v-chip
                :disabled="disabled"
                v-if="item === Object(item)"
                v-bind="attrs"
                :input-value="selected"
            >
                <v-icon
                    small
                    @click="parent.selectItem(item)"
                >
                    $delete
                </v-icon>
                <span class="pl-2">{{ item.nm_perfil }}</span>
            </v-chip>
        </template>
    </v-autocomplete>
</template>

<script>
import {filterNormalizeMixin} from "../../mixins/filterNormalizeMixin";
import {getPerfis} from "../../api/perfil";

export default {
    name: "PerfilAutocomplete",
    mixins: [filterNormalizeMixin],
    props: {
        label: {
            type: String,
            default: 'Perfis'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        value: {
            type: Array,
        },
        rules: {
            type: Array,
            default: () => []
        },
        error: {
            type: String|Array,
            default: () => []
        }
    },
    data() {
        return {
            perfis: []
        }
    },
    created() {
        getPerfis().then((response) => {

            this.perfis = response.data.data;

            // se for Analista e não for Administrador desabilita a opção de Administrador
            if (this.$store.getters['auth/usuarioHasRole'](['Analista']) && !this.$store.getters['auth/usuarioHasRole'](['Administrador'])) {
                this.perfis.forEach(element => {
                    if (element.nm_perfil === 'Administrador') {
                        element.disabled = true;
                    }
                });                
            }

        })
    },
    methods: {
        changePerfil(event) {
            this.$emit("changePerfisEvent", event);
        }
    }

}
</script>
