import api from './index';


const createAviso = (dados) => api.post('aviso', dados);

const getAviso = (id_aviso) => api.get(`aviso/${id_aviso}`);

const getAvisosPendentes = () => api.get('aviso/avisos_pendentes');

const marcarLido = (id_aviso) => api.put(`aviso/${id_aviso}`);

const deletarAviso = (id_aviso) => api.delete(`aviso/${id_aviso}`);
const getAvisosPaginate = async (page, itemsPerPage, filtrosAplicados = []) => await api.get('aviso/avisos-paginate', {
    params: {
        page: page,
        limit: itemsPerPage,
        filtros: filtrosAplicados,
    },
    showLoader: true
});

export {
    getAvisosPaginate,
    createAviso,
    deletarAviso,
    getAviso,
    getAvisosPendentes,
    marcarLido
}
