import api from './index';

const getQuestionarios = () => api.get('questionario/questionarios');
const getQuestionario = (id_questionario) => api.get(`questionario/${id_questionario}`);
const createQuestionario = (dados) => api.post('questionario', dados);
const updateQuestionario = (id_questionario, dados) => api.put(`questionario/${id_questionario}`, dados);
const submeterQuestionario = (id_questionario, dados) => api.put(`questionario/submit/${id_questionario}`, dados);
const deleteQuestionario = (id_questionario) => api.delete(`questionario/${id_questionario}`);
const reabrirQuestionario = (id_questionario, dados) => api.put(`questionario/reabrir/${id_questionario}`, dados);

const getUsoDespesaIrrelevante = (id_questionario) => api.get(`questionario/despesa-irrelevante/${id_questionario}`);

const getQuestionariosPaginate = async (page, itemsPerPage, filtrosAplicados = []) => await api.get('questionario/questionarios-paginate', {
    params: {
        page: page,
        limit: itemsPerPage,
        filtros: filtrosAplicados,
    },
    showLoader: true
});

export {    
    getQuestionarios,
    getQuestionariosPaginate,
    getQuestionario,
    createQuestionario,
    updateQuestionario,
    deleteQuestionario,
    submeterQuestionario,
    getUsoDespesaIrrelevante,
    reabrirQuestionario
}
