<template>
    <v-row justify="center">
        <v-dialog
            v-model="dialog"
            transition="dialog-top-transition"
            persistent
            max-width="600"
        >
            <v-card>
                <v-toolbar
                    v-if="messages.length && messages[item_counter - 1].id_aviso"
                    color="primary"
                    dark
                    elevation="0"
                >Avisos
                </v-toolbar>
                <v-toolbar
                    v-if="messages.length && messages[item_counter - 1].id_alerta"
                    color="primary"
                    dark
                    elevation="0"
                >Alerta
                </v-toolbar>
                <v-card-text class="pa-4">
                    <v-row justify="center">
                        <v-list-item three-line v-if="messages.length !== (item_counter - 1)"
                                     transition="fade-transition">
                            <v-list-item-content>
                                <div class="text-overline mb-4">
                                    {{ messages[item_counter - 1]?.nm_assunto }}
                                </div>
                                <v-list-item-subtitle
                                    v-if="messages[item_counter - 1]?.nm_mensagem.split('\n').length"
                                    v-for="message in messages[item_counter - 1]?.nm_mensagem.split('\n')"
                                    v-bind:key="message"
                                >

                                    <span v-if="message.length > 1">
                                        {{ message }}
                                    </span>
                                    <span v-else>
                                        <br>
                                    </span>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle v-else>
                                    {{ messages[item_counter - 1]?.nm_mensagem }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-col class="text-right" cols="7">
                        <v-chip
                            color="blue"
                            outlined
                            label
                            cols="10"
                        >{{ item_counter }} de {{ messages.length }}
                        </v-chip>
                    </v-col>
                    <v-col class="text-right">
                        <v-btn v-if="messages.length && messages[item_counter - 1].id_aviso"
                               text
                               color="primary"
                               @click="cienteMensagem(messages[item_counter - 1].id_aviso)"
                        >
                            Ciente
                        </v-btn>
                        <v-btn v-if="messages.length && messages[item_counter - 1].id_alerta"
                               text
                               color="primary"
                               @click="cienteAlerta(messages[item_counter - 1].id_alerta)"
                        >
                            Ciente
                        </v-btn>
                    </v-col>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import {getAvisosPendentes, marcarLido} from "../../api/aviso";
import {getAlertaPendentes, marcarAlertaLido} from "../../api/alerta";

export default {
    data() {
        return {
            dialog: false,
            item_counter: 1,
            messages: []
        }
    },
    methods: {
        cienteMensagem(id) {
            marcarLido(id).then(() => {

            }).finally(() => {
                if (this.item_counter < this.messages.length) {
                    this.item_counter++;
                } else {
                    this.dialog = false;
                }
            });
        },
        cienteAlerta(id) {
            marcarAlertaLido(id).then(() => {

            }).finally(() => {
                if (this.item_counter < this.messages.length) {
                    this.item_counter++;
                } else {
                    this.dialog = false;
                }
            });
        }
    },
    created() {
        getAvisosPendentes().then((data) => {
            if (data.data.data.length) {
                this.messages = this.messages.concat(data.data.data);
                this.dialog = true;
            }
        });

        getAlertaPendentes().then((data) => {
            if (data.data.data.length) {
                this.messages = this.messages.concat(data.data.data);
                this.dialog = true;
            }
        });
    }
}
</script>