import api from './index';

const login = (code) => api.get('login/' + code);

const getUsuarioAutenticado = (token) => api.get('usuario-autenticado/' + token);

export {
    login,
    getUsuarioAutenticado
}
