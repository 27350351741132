import api from './index';

const getPermissoes = () => api.get('permissao/permissoes');
const getPermissao = (id_permissao) => api.get(`permissao/${id_permissao}`);
const createPermissao = (dados) => api.post('permissao', dados);
const updatePermissao = (id_permissao, dados) => api.put(`permissao/${id_permissao}`, dados);
const deletePermissao = (id_permissao) => api.delete(`permissao/${id_permissao}`);

const getPermissoesPaginate = async (page, itemsPerPage, filtrosAplicados = []) => await api.get('permissao/permissoes-paginate', {
    params: {
        page: page,
        limit: itemsPerPage,
        filtros: filtrosAplicados,
    },
    showLoader: false
});

export {    
    getPermissoes,
    getPermissoesPaginate,
    getPermissao,
    createPermissao,
    updatePermissao,
    deletePermissao
}
