import { login } from '../api/auth';
import filterService from "../filters/filterService";

class AuthService {
    getLocalUser() {
        const user = localStorage.getItem("user");

        if(!user) {
            return null;
        }

        return JSON.parse(user);
    }

    getTipoAnalista() {
        const tipoAnalista = localStorage.getItem("tipoAnalista");

        if(!tipoAnalista) {
            return null;
        }

        return JSON.parse(tipoAnalista);
    }

    getLocalServidor() {
        const servidor = localStorage.getItem("servidor");

        if(!servidor) {
            return null;
        }

        return JSON.parse(servidor);
    }

    getLocalToken() {
        const token = localStorage.getItem("access_token");

        if(!token) {
            filterService.clearAllFilters();
            return null;
        }

        return JSON.parse(token);
    }

    getLocalRoles() {
        const roles = localStorage.getItem("roles");

        return JSON.parse(roles);
    }

    getLocalPermissoes() {
        const permissoes = localStorage.getItem("permissoes");

        return JSON.parse(permissoes);
    }

    getSetOrgao() {
        const setOrgao = localStorage.getItem("setOrgao");

        return JSON.parse(setOrgao);
    }

    login(code) {
        return login(code)
            .then(response => {
                if(response.status == 200) {
                    localStorage.setItem('tipoAnalista', JSON.stringify(response.data.data.tipoAnalista));
                    localStorage.setItem('user', JSON.stringify(response.data.data.id_usuario));
                    localStorage.setItem('access_token', JSON.stringify(response.data.data.token));
                    localStorage.setItem('roles', JSON.stringify(response.data.data.roles));
                    localStorage.setItem('permissoes', JSON.stringify(response.data.data.permissoes.filter(this.uniqueValuesArray)));
                    localStorage.setItem('setOrgao', false);
                }
            }).catch(error => {
                return Promise.reject(error);
            });
    }

    logout() {
        localStorage.removeItem('tipoAnalista');
        localStorage.removeItem('user');
        localStorage.removeItem('servidor');
        localStorage.removeItem('access_token');
        localStorage.removeItem('roles');
        localStorage.removeItem('permissoes');
        localStorage.removeItem('setOrgao');
        filterService.clearAllFilters();
    }

    uniqueValuesArray(value, index, array) {
        return array.indexOf(value) === index;
      }

}

export default new AuthService();

